import React from 'react';
import { InputText } from 'primereact/inputtext';

import { useMutation } from '@apollo/react-hooks'
import { Button } from 'primereact/button';
import { useHistory } from 'react-router-dom';
import { InputMask } from 'primereact/inputmask';
import GrowlContext from '../../service/GrowlContext';
import UserContext from '../../service/UserContext';

import {insertUserInviteQuery, getCurrentUserId} from '../../graphql/users';
import ApolloClient from 'apollo-client';
import withApolloClient from '../../hooks/withApolloClient';
import { GetUserId, GetUserIdVariables } from '../../graphql/types/GetUserId';
import { InsertUserInvite, InsertUserInviteVariables } from '../../graphql/types/InsertUserInvite';

const CreateUser = ({ client }: { client: ApolloClient<object>}) => {
    const user = React.useContext(UserContext);
    const history = useHistory();
    const growl = React.useContext(GrowlContext);

    let emailRef: any;
    let phoneRef: any;

    const [email, setEmail] = React.useState('');
    const [phone, setPhone] = React.useState('');
    const [createdAt] = React.useState(new Date());

    const userIdQuery = client.query<GetUserId, GetUserIdVariables>({
        query: getCurrentUserId,
        variables: {
            cognitoId: user?.username.toLowerCase()
    }});

    const [insertUserInvite, {loading, error}] = useMutation<InsertUserInvite, InsertUserInviteVariables>(insertUserInviteQuery);

    const dispatchToSettter = (setter: React.Dispatch<React.SetStateAction<string>>) => {
        return (e: any) => setter((e.target as HTMLInputElement).value);
    }

    const goBack = () => history.goBack();
    const submit = async () => {
        const emailValid = emailRef.element?.validity.valid;
        const phoneValid = phoneRef.input?.validity.valid;
        if (!emailValid || !phoneValid) {
            const message = !emailValid && !phoneValid ? 'Please fill in the email and phone input fields' : 
                !emailValid ? 'Please fill in the email input field' : 'Please fill in the phone input field';
            growl?.show({severity: 'error', summary: 'Error Creating User', detail: message});
            return;
        }

        const userId = (await userIdQuery).data?.users[0]?.id;
        insertUserInvite({variables: {
            user: {
                invited_by_user_id:userId,
                email: email.toLowerCase(),
                phone 
            }
        }})
            .then(r => r.data?.insert_user_invites?.returning?.map(m => m.email)[0])
            .then(i => {
                if (i) {
                    history.push(`/user/edit/${i}`);
                }
            })
            .catch(e => {
                growl?.show({severity: 'error', summary: 'Error Creating User', detail: `error saving new user: ${JSON.stringify(e)}`});
            });
    }
    
    return (
        <div className="p-grid">
            <div className="p-col-12">
                <div className="card card-w-title">
                    <h1>Account Page</h1>
                    <div className="p-fluid">
                        <div className="p-field p-grid">
                            <label htmlFor="email" className="p-col-12 p-md-2">Email</label>
                            <div className="p-col-12 p-md-10">
                                <InputText
                                    ref={(r:any) => emailRef = r}
                                    placeholder="Email" 
                                    id="email" 
                                    type="email"
                                    value={email} 
                                    required={true}
                                    onChange={dispatchToSettter(setEmail)} />
                            </div>
                        </div>
                        <div className="p-field p-grid">
                            <label htmlFor="phone" className="p-col-12 p-md-2">Phone</label>
                            <div className="p-col-12 p-md-10">
                                <InputMask 
                                    ref={(r:any) => phoneRef = r}
                                    mask="(999) 999-9999"
                                    placeholder="Phone" 
                                    id="phone" 
                                    value={phone}
                                    required={true}
                                    onChange={dispatchToSettter(setPhone)} />
                            </div>
                        </div>
                        <div className="p-field p-grid">
                            <label htmlFor="createdOn" className="p-col-12 p-md-2">Created On</label>
                            <div className="p-col-12 p-md-10">
                                <InputText placeholder="Created On" id="createdOn" type="text" disabled={true} value={createdAt.toDateString()} />
                            </div>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
                            <Button type="button" className="p-button-success" label="Save" style={{width: '4em'}} onClick={submit}/>
                            <Button type="button" className="p-button-danger" label="Back" style={{width: '4em'}} onClick={goBack}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default withApolloClient(CreateUser);