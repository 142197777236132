import React, { Component } from 'react';
import {Panel} from 'primereact/components/panel/Panel';
import {Button} from 'primereact/components/button/Button';
import { InputText } from 'primereact/inputtext';
import { useMutation, useQuery } from '@apollo/react-hooks';
import {Users, Users_users} from '../graphql/types/Users';
import { getUsers } from '../graphql/users';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import { getAllQuestionSubmissions } from '../graphql/questionSets';
import {QuestionSetSubmissionHistory} from '../graphql/types/QuestionSetSubmissionHistory';
import { useHistory } from 'react-router-dom';
import UserContext, { isUserAnAdmin } from '../service/UserContext';
 
import { getOrganization, updateOrganization } from '../graphql/users';
import { Organization, Organization_organizations } from '../graphql/types/Organization';
import { UpdateOrganization, UpdateOrganizationVariables } from '../graphql/types/UpdateOrganization';
import { createPublicKey } from 'crypto';
import GrowlContext from '../service/GrowlContext';


export default function Dashboard({}) {
    const history = useHistory();
    const { data, error, loading } = useQuery<Users>(getUsers);
    const { data: dataS, error: errorS, loading: loadingS } = useQuery<QuestionSetSubmissionHistory>(getAllQuestionSubmissions);
    const [userList, setUserList] = React.useState<Users_users[]>()
    const [userCount, setUserCount] = React.useState(0); 
    const [testCount, setTestCount] = React.useState(0);
    const [todayCount, setTodayCount] = React.useState(0);
    const [submissionCount, setSubmissionCount] = React.useState(0);
    const ref = React.useRef<DataTable|null>();
    const user = React.useContext(UserContext);
    const isAdmin = isUserAnAdmin(user);

    const { data: dataOwner, error: errorOwner, loading: loadingOwner } = useQuery<Organization>(getOrganization);
    const [orgName, setOrgName] =  React.useState('');
    const [orgUrl, setOrgUrl] =  React.useState('');
    const [orgId, setOrgId] = React.useState(0);
    const growl = React.useContext(GrowlContext);

    const [updateOrganizationInfoMutation, {loading: loadingUpdateOrg, error: errorUpdateOrg}] 
    = useMutation<UpdateOrganization, UpdateOrganizationVariables>(updateOrganization)



    React.useEffect( () => {
        if (data?.users) {
            console.log("Found Users");
            setUserList(data?.users);
            setUserCount(data?.users.length);
        }
        else{
            console.log("data", data);
            console.log("No users");
        }
    }, [data]);
   
    React.useEffect( () => {
        if (dataS?.question_set_submission) {
            console.log("Found Submissions");
            setSubmissionCount(dataS?.question_set_submission.length);
            
        }
        else{
            console.log("data", data);
            console.log("No submisssions");
        }
    }, [dataS]);

    React.useEffect( () => {
        if (dataOwner?.organizations) {
            console.log("Found Org");
            var logourl = dataOwner.organizations[0].logo_url
            if (logourl == null)
                logourl = '';
            setOrgName(dataOwner?.organizations[0].display_name);
            setOrgUrl(logourl);
            setOrgId(dataOwner.organizations[0].id);
        }
        else{
            console.log("data", dataOwner);
            console.log("No Org");
        }
    }, [dataOwner]);


    if (error) {
        console.error("Error Occurred", error);
    }

    const actionsTemplate = (rowData: Users_users) => (
        <div>
            <Button
                type="button"
                icon="pi pi-search"
                className="p-button-primary"
                onClick={(e) => {
                    e.preventDefault();
                    history.push(`/user/edit/${rowData.email}`);
                }} />
        </div>
    )

    const goToCreateUser = () => history.push('/user/create');

    const updateOrgInfo = () => {       
        updateOrganizationInfoMutation({variables: {
            display_name: orgName,
            logo_url: orgUrl,
            id: orgId
        }}).then(r => {
            console.log("Saved.");
            growl?.show({severity: 'success', summary: 'Saved Org Info', detail: `Saved Org `});
        })
        .catch(e => {
            console.error('error updating Org Info', e);
            growl?.show({severity: 'error', summary: 'Error updating Org Info', detail: e});
        });
    }

    const userTableHeader = (
        <div className="p-clearfix" style={{'lineHeight':'1.87em'}}>
            <span style={{float: 'left'}}>User Management</span> 
            <Button icon="pi pi-user-plus" style={{float:'right'}} onClick={goToCreateUser}/>
        </div>);

    return (
        <div className="p-grid dashboard">
            <div className="p-col-12">
                <Panel header="At a glance" className="circle-panel">
                    <div className="p-grid">
                        <div className="p-col-12 p-lg-6 p-md-6">
                            <div className="status-title" style={{color:'#6ebc3b'}}>Users</div>
                            <div className="circle1">
                                <i className="pi pi-user"></i>
                                <span>{userCount.toString()}</span>
                            </div>
                        </div>
                        <div className="p-col-12 p-lg-6 p-md-6">
                            <div className="status-title" style={{color:'#f6a821'}}>Mobile</div>
                            <div className="circle2">
                                <i className="pi pi-mobile"></i>
                                <span>{submissionCount}</span>
                            </div>
                        </div>
                       
                    </div>
                </Panel>
            </div>
            
           {isAdmin && (
            <div className="p-col-12 p-lg-4 contacts">
                <Panel style={{minHeight: '360px'}}>
                <DataTable 
                        ref={(e) => {ref.current = e}} 
                        value={userList} 
                        selectionMode="single"
                        header= {userTableHeader}//"Users" 
                        paginator={false} 
                        rows={10}
                        responsive={true} >
                        <Column field="email" sortable={true} filter={false}/>
                        <Column body={actionsTemplate} style={{ textAlign: 'center', width: '10em' }} />
                    </DataTable>
                </Panel>
            </div>
            )}

            {isAdmin && (
            <div className="p-col-12 p-lg-4">
                <Panel>
                    <h3>Customization</h3>
                    <p>Use this section to customization how the forms on the mobile app look.</p>
                    <div className="p-field p-grid p-fluid">
                        <label htmlFor="name" className="p-col-12 p-md-3">Display Name</label>
                        <div className="p-col-12 p-md-9">
                            <InputText placeholder="Name" id="name" type="neworgtext" defaultValue={orgName} onChange={(e) => setOrgName((e.target as any).value)}  />
                        </div>
                    </div>
                    <div className="p-field p-grid p-fluid">
                        <label htmlFor="name" className="p-col-12 p-md-3">Logo URL</label>
                        <div className="p-col-12 p-md-9">
                            <InputText placeholder="URL of Logo Image" id="neworgtext" type="text" defaultValue={orgUrl} onChange={(e) => setOrgUrl((e.target as any).value)}/>
                        </div>
                    </div>
                    <div className="p-field" style={{textAlign: "right"}}>
                        <div>
                            <Button
                                type="button"
                                icon="pi pi-save"
                                label="Save"
                                className="p-button-primary"
                                onClick={(e) => {
                                    e.preventDefault();
                                    updateOrgInfo();
                                }} />
                        </div>
                    </div>
                </Panel>
            </div>
            )}
        </div>
    )
};

//export default Dashboard;
