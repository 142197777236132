import React from 'react';
import { InputText } from 'primereact/inputtext';
import { InputMask } from 'primereact/inputmask';
import { InputNumber } from 'primereact/inputnumber';
import Creatable from 'react-select/creatable';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { getFullyLoadedQuestionSetTemplate, insertQuestionSet, addQuestionSetSubscription, deleteQuestionSetSubscription, updateQuestionSet } from '../../graphql/questionSets';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ToggleButton } from 'primereact/togglebutton';
import { Dialog } from 'primereact/dialog';
import {
    FullyLoadedQuestionSet,
    FullyLoadedQuestionSetVariables,
    FullyLoadedQuestionSet_question_sets_by_pk_question_pages,
    FullyLoadedQuestionSet_question_sets_by_pk_question_pages_question_groups,
    FullyLoadedQuestionSet_question_sets_by_pk_question_pages_question_groups_question_freeforms,
    FullyLoadedQuestionSet_question_sets_by_pk_question_pages_question_groups_question_multiselects,
    FullyLoadedQuestionSet_question_sets_by_pk_question_pages_question_groups_group_type,
    FullyLoadedQuestionSet_question_sets_by_pk_question_pages_question_groups_question_multiselects_question_answer_set_question_answer_options,
} from '../../graphql/types/FullyLoadedQuestionSet';

import { value_types_enum, types_frequency_enum, question_pages_insert_input, question_groups_insert_input, question_groups_arr_rel_insert_input, group_types_enum, question_freeforms_arr_rel_insert_input, question_freeforms_insert_input, question_multiselects_arr_rel_insert_input, question_multiselects_insert_input, question_answer_options_insert_input, question_answer_set_insert_input, question_set_status_enum } from '../../types/graphql-global-types';

import GrowlContext from '../../service/GrowlContext';
import { InsertQuestionSet, InsertQuestionSetVariables } from '../../graphql/types/InsertQuestionSet';
import { ProgressSpinner } from 'primereact/progressspinner';
import UserContext from '../../service/UserContext';
import { SubscribeToTestNotificationVariables, SubscribeToTestNotification } from '../../graphql/types/SubscribeToTestNotification';
import { UnsubscribeToTestNotification, UnsubscribeToTestNotificationVariables } from '../../graphql/types/UnsubscribeToTestNotification';
import { UpdateQuestionSet, UpdateQuestionSetVariables } from '../../graphql/types/UpdateQuestionSet';
import { InsertQuestionSetPageVariables } from '../../graphql/types/InsertQuestionSetPage';
import { UpdateQuestionSetPageNameVariables } from '../../graphql/types/UpdateQuestionSetPageName';
import { SwapQuestionSetPagesVariables } from '../../graphql/types/SwapQuestionSetPages';
import { DeleteQuestionSetPageVariables } from '../../graphql/types/DeleteQuestionSetPage';
import { GetDefaultErrorHandler } from '../../service/GetDefaultErrorHandler';

// Actions
import { UseDispatchQuestionSetPageAction, QuestionSetPageActionEnum } from './QuestionSetPageActions';
import { UseDispatchQuestionGroupActions, QuestionGroupActionEnum, QuestionGroupActions } from './QuestionGroupActions';
import { UseDispatchFreeformQuestionActions, FreeformQuestionActionEnum, FreeformQuestionActions } from './FreeformQuestionActions';
import { UseDispatchMultiselectQuestionActions, MultiselectQuestionActionEnum } from './MultiselectQuestionActions';
import { UseDispatchAnswerOptionActions, AnswerOptionActionEnum } from './AnswerOptionActions';
import { SwapFreeformQuestionsVariables } from '../../graphql/types/SwapFreeformQuestions';
import { DeleteFreeformQuestionVariables } from '../../graphql/types/DeleteFreeformQuestion';
import { UpdateFreeformQuestionVariables } from '../../graphql/types/UpdateFreeformQuestion';
import { DeleteQuestionGroupVariables } from '../../graphql/types/DeleteQuestionGroup';
import { SwapQuestionGroupsVariables } from '../../graphql/types/SwapQuestionGroups';
import { SwapMultiselectQuestionsVariables } from '../../graphql/types/SwapMultiselectQuestions';
import { DeleteMultiselectQuestionVariables } from '../../graphql/types/DeleteMultiselectQuestion';
import { SwapAnswerOptionsVariables } from '../../graphql/types/SwapAnswerOptions';
import { DeleteAnswerOptionVariables } from '../../graphql/types/DeleteAnswerOption';
import DefaultValueOptions, { DefaultValueOptionsEnum, DefaultValueLabelsEnum, getFilteredDefaultValueOptions, shouldResetDefaultValueBasedOnQuestionType } from '../../service/QuestionSetDefaultValueEquations';

// UI
import './CreateEditQuestionSet.css';
import { validateEmail } from '../../service/functions';
import { debug } from 'console';

interface LocalQuestionSetPage extends FullyLoadedQuestionSet_question_sets_by_pk_question_pages { };
interface LocalQuestionGroup extends FullyLoadedQuestionSet_question_sets_by_pk_question_pages_question_groups { };
interface LocalFreeFormQuestion extends FullyLoadedQuestionSet_question_sets_by_pk_question_pages_question_groups_question_freeforms { };
interface LocalMultiSelectQuestion extends FullyLoadedQuestionSet_question_sets_by_pk_question_pages_question_groups_question_multiselects { };
interface LocalMultiselectAnswerOption extends FullyLoadedQuestionSet_question_sets_by_pk_question_pages_question_groups_question_multiselects_question_answer_set_question_answer_options { };
type setPagesType = React.Dispatch<React.SetStateAction<Array<LocalQuestionSetPage>>>;

export enum QuestionSetState {
    New,
    ExistingNoSubmissions,
    ExistingWithSubmissions
}

function reorder<T extends { order: number }>(items: T[]) {
    return items.map((r, i) => ({
        ...r,
        order: i
    }));
}

function swap<T>(items: T[], indexOne: number, indexTwo: number) {
    // bound check
    if (indexOne < 0 || indexOne >= items.length || indexTwo < 0 || indexTwo >= items.length) { return items; }

    const localItems = [...items];

    // swap
    localItems[indexOne] = items[indexTwo];
    localItems[indexTwo] = items[indexOne];

    return localItems;
}

function genericActionsTemplate<T>(
    questionSetState: QuestionSetState,
    items: T[],
    swap: (indexOne: number, indexTwo: number) => void,
    del: (rowIndex: number) => void
) {
    return (row: T) => {

        const rowIndex = items.findIndex(r => r === row);
        const isFirstRow = rowIndex === 0;
        const isLastRow = rowIndex === items.length - 1;

        if (questionSetState === QuestionSetState.ExistingWithSubmissions) {
            return <></>
        }

        return (
            <div className="p-fluid p-grid">
                <div className="p-col-4">
                    {!isFirstRow &&
                        <Button
                            type="button"
                            icon="pi pi-arrow-up"
                            className="p-button-warning"
                            onClick={(e: any) => {
                                // move this row up and re-sort pages
                                swap(rowIndex, rowIndex - 1);
                                e.preventDefault();
                            }} />
                    }
                </div>
                <div className="p-col-4">
                    {!isLastRow &&
                        <Button
                            type="button"
                            icon="pi pi-arrow-down"
                            className="p-button-primary"
                            onClick={(e: any) => {
                                // move this row up and re-sort pages
                                swap(rowIndex, rowIndex + 1);
                                e.preventDefault();
                            }} />
                    }
                </div>
                <div className="p-col-4">
                    <Button
                        type="button"
                        icon="pi pi-trash"
                        className="p-button-danger"
                        onClick={(e: any) => {
                            del(rowIndex);
                            e.preventDefault();
                        }} />
                </div>
            </div>
        );
    }
}

function genericAddFooter<T>(questionSetState: QuestionSetState, addNewItem: () => void) {
    return (
        <div className="p-grid" style={{ textAlign: "right" }}>
            <div className="p-col">
                {questionSetState !== QuestionSetState.ExistingWithSubmissions &&
                    <Button
                        type="button"
                        icon="pi pi-plus-circle"
                        className="p-button-primary"
                        onClick={(e: any) => {
                            addNewItem();
                        }} />
                }
            </div>
        </div>
    )
}

type InputTextTypes = "text" | "numeric" | "date" | "equation";
function EditHeader<T>(p: {
    item: { rowData: T; },
    getValue: (item: T) => any,
    updateHeader: (header: string, item: T) => void,
    updateHeaderBackend?: (header: string, item: T) => Promise<void>,
    optional?: {
        type: InputTextTypes | ((item: T) => InputTextTypes)
    }
}) {
    const { item, getValue, updateHeader, updateHeaderBackend, optional } = p;
    const [localValue, setLocalValue] = React.useState(getValue(item.rowData));
    const [defaultValue, setDefaultValue] = React.useState(localValue);

    const saveToServer = async () => {
        if (updateHeaderBackend) {
            setDefaultValue(localValue);
            updateHeader(localValue, item.rowData);
            await updateHeaderBackend(localValue, item.rowData);
        }
    }

    const params = {
        value: localValue,
        onChange: (e: any) => {
            const newValue = (e.target as any).value;
            setLocalValue(newValue);
        }
    };

    let type: InputTextTypes = optional ? (optional?.type instanceof Function ? optional.type(item.rowData) : optional.type) : "text"; // default to text

    if (type === "date") {
        return <InputMask {...params} mask="99/99/9999" slotChar="mm/dd/yyyy" />;
    } else if (type === "numeric") {
        return <InputNumber {...params} />;
    } else {
        return (
            <div className="p-grid p-align-center">
                <div className="p-col greedy-mfer"><InputText {...params} /></div>
                {defaultValue !== localValue && (
                    <div className="p-col giving-mfer">
                        <Button style={{ width: 30 }}
                            type="button"
                            className="p-button-primary"
                            icon="pi pi-save"
                            onClick={saveToServer} />
                    </div>
                )}
            </div>
        );
    }
}

function GenericEditHeader<T>(
    getValue: (item: T) => any,
    updateHeader: (header: string, item: T) => void,
    updateHeaderBackend?: (header: string, item: T) => Promise<void>,
    optional?: {
        type: InputTextTypes | ((item: T) => InputTextTypes)
    }) {

    return (i: { rowData: T }) => <EditHeader getValue={getValue} updateHeader={updateHeader} updateHeaderBackend={updateHeaderBackend} optional={optional} item={i} />;
}

/* 
    TODO CRT
    - set category to some sort of smart data entry
    - set frequency to dropdown with populated values
*/

const QuestionSetViewerCard = ({ page }: { page: LocalQuestionSetPage | undefined }) => {
    return (
        <div className="card card-w-title" style={{ height: "800px", overflowY: "scroll" }}>
            {
                page && (
                    <>
                        <h1 style={{ margin: 10, textTransform: "uppercase" }}>{page.header} <span style={{ float: "right" }}>{page.page_number}</span></h1>
                        {
                            page.question_groups.map((group, index) => (
                                <div key={index}>
                                    <h2 style={{ marginLeft: 20, marginBottom: 10, color: "#1a5b8b" }}>{group.header}</h2>
                                    {
                                        group.group_type.id === "FreeForm" &&
                                        group.question_freeforms.map((question, index) => (
                                            <h3 key={index} style={{ marginTop: 10, marginLeft: 30, marginRight: 30 }}>
                                                {question.text} <span style={{ float: "right", fontWeight: "lighter" }}>{question.value_type}</span>
                                            </h3>
                                        ))
                                    }
                                    {
                                        group.group_type.id === "Multiselect" &&
                                        group.question_multiselects.map((question, index) => (
                                            <div key={index} style={{ marginTop: 10, marginLeft: 30, marginRight: 30 }}>
                                                <h3>{question.text}</h3>
                                                {question.question_answer_set.question_answer_options.sort((o1, o2) => o1.value > o2.value ? 1 : -1).map((option, index) => (
                                                    <div key={index} style={{ textAlign: "right" }}>
                                                        {option.value}: {option.header}
                                                    </div>
                                                ))}
                                            </div>
                                        ))
                                    }
                                </div>
                            ))
                        }
                    </>
                )
            }
        </div>
    );
}

interface QuestionSetInfoCardParams {
    id?: number,
    questionSetState: QuestionSetState,
    pages: Array<LocalQuestionSetPage>,
    setSubmissions: React.Dispatch<number>,
    saveFunction: saveFunctionType,
    isSaving: boolean,
    setIsSaving: React.Dispatch<boolean>,
    questionSet: FullyLoadedQuestionSet | null | undefined
};

const QuestionSetInfoCard = ({ id, questionSetState, pages, setSubmissions, saveFunction, isSaving, setIsSaving, questionSet }: QuestionSetInfoCardParams) => {
    const user = React.useContext(UserContext);
    const history = useHistory();
    const freqOptions = [
        { label: types_frequency_enum.None, value: types_frequency_enum.None },
        { label: types_frequency_enum.Daily, value: types_frequency_enum.Daily },
        { label: types_frequency_enum.Weekly, value: types_frequency_enum.Weekly },
        { label: types_frequency_enum.Monthly, value: types_frequency_enum.Monthly },
    ]

    const [name, setName] = React.useState('');
    const [version, setVersion] = React.useState('1.0'); // default to 1
    const [category, setCategory] = React.useState('');
    const [currentSubscriber, setCurrentSubscriber] = React.useState('');
    const [subscriberlist, setSubscriberList] = React.useState(Array<string>());
    const [frequency, setFrequency] = React.useState(types_frequency_enum.None);
    const [localSubmissions, localSetSubmissions] = React.useState(0);
    const [isPublished, setIsPublished] = React.useState(false); // for now will only have two statuses, even though GQL has room for more
    const canUpdateVersion = questionSetState === QuestionSetState.ExistingWithSubmissions;

    // Dialog
    const [dialog, setDialog] = React.useState<{
        header: string,
        message: string,
        footer: JSX.Element,
        visible: boolean,
    }>({ header: '', message: '', footer: <></>, visible: false });

    React.useEffect(() => {
        const set = questionSet?.question_sets_by_pk;
        if (set) {
            setName(set.name);
            setVersion(set.version);
            setCategory(set.category);
            setSubscriberList(set.question_set_notification_subscriptions.map(r => r.email_address) || Array<string>());
            setFrequency(set.frequency);
            localSetSubmissions(set.question_set_submissions_aggregate?.aggregate?.count || 0);
            setIsPublished(set.question_set_status === question_set_status_enum.published);
        }
    }, [questionSet])

    const growl = React.useContext(GrowlContext);

    const [update] = useMutation<UpdateQuestionSet, UpdateQuestionSetVariables>(updateQuestionSet);

    // When submissions change update parent
    React.useEffect(() => { setSubmissions(localSubmissions); }, [localSubmissions]);

    const showSave = questionSetState === QuestionSetState.New; // Only allow save on create
    const showCopy = questionSetState !== QuestionSetState.New; // Only allow copy on non-create

    const dynamicSave = async () => {
        // If doing full saves, don't actually update the record right now
        if (showSave || !id) {
            return;
        }

        if (!name) {
            growl?.show({ severity: 'error', summary: 'Form name is required' });
            return;
        }

        const set = questionSet?.question_sets_by_pk;
        if (!set) {
            return;
        }

        const publishStatusToUse = isPublished ? question_set_status_enum.published : question_set_status_enum.draft;

        // If nothing changed, don't save
        const hasUpdate =
            set.name !== name ||
            set.version !== version ||
            set.category !== category ||
            set.frequency !== frequency ||
            set.question_set_status !== publishStatusToUse;

        if (!hasUpdate) {
            return;
        }

        // Doing partial saves, update record properties now
        setIsSaving(true);

        const updateVariables: UpdateQuestionSetVariables = {
            id: id,
            category: category,
            name: name,
            frequency: frequency,
            status: publishStatusToUse
        };

        try {
            await update({ variables: updateVariables });
            growl?.show({ severity: 'success', summary: 'Test updated', detail: 'Test updated successfully!' });

            // Update local set so change won't keep being sent to server
            if (set) {
                set.name = name;
                set.version = version;
                set.category = category;
                set.frequency = frequency;
                set.question_set_status = publishStatusToUse;
            }
        } catch (e) {
            console.error(e);
            growl?.show({ severity: 'error', summary: 'Error updating test', detail: `${e}` });
        }

        setIsSaving(false);
    };

    const [subscribeMutation] = useMutation<SubscribeToTestNotification, SubscribeToTestNotificationVariables>(addQuestionSetSubscription);
    const [unsubscribeMutation] = useMutation<UnsubscribeToTestNotification, UnsubscribeToTestNotificationVariables>(deleteQuestionSetSubscription);

    const addCurrentSubscriber = async () => {
        // don't add empty or duplicate record or not valid email or not a real question set (hasn't been saved)
        if (!currentSubscriber || subscriberlist.find(r => r === currentSubscriber) || !validateEmail(currentSubscriber) || id === undefined) {
            return;
        }

        // If showing save (e.g. new form), just add to list and exit
        // otherwise, save to backend then add to list and exit

        // save changes to backend
        if (!showSave) {
            const subscribeVars: SubscribeToTestNotificationVariables = { question_set_id: id, email_address: currentSubscriber.toLowerCase() }
            try {
                await subscribeMutation({ variables: subscribeVars }).then(() => growl?.show({ severity: 'success', summary: `Added subscriber ${currentSubscriber} to form` }));
            } catch (e) {
                console.error(e);
                growl?.show({ severity: 'error', summary: 'Error adding subscriber', detail: `${JSON.stringify(e)}` });
                return; // don't change UI if save doesn't work
            }
        }

        // add subscriber to collection
        const newSubscribers = [
            ...subscriberlist,
            currentSubscriber
        ];

        setSubscriberList(newSubscribers);

        // clear input text for use later
        setCurrentSubscriber('');
    };

    const removeCurrentSubscriber = async (k: string) => {
        // Do nothing if not a real question set
        if (id === undefined) {
            return;
        }

        // save changes to backend
        const unsubscribeVars: UnsubscribeToTestNotificationVariables = { question_set_id: id, email_address: k };
        try {
            await unsubscribeMutation({ variables: unsubscribeVars })
                .then(() => growl?.show({ severity: 'success', summary: `Removed subscriber ${k} from form` }));
        } catch (e) {
            console.error(e);
            growl?.show({ severity: 'error', summary: 'Error removing subscriber', detail: `${JSON.stringify(e)}` });
            return; // don't change UI if save doesn't work
        }

        // remove subscriber from collection
        const newSubscribers = subscriberlist.filter(r => r !== k);
        setSubscriberList(newSubscribers);
    }

    React.useEffect(() => {
        dynamicSave();
    }, [isPublished]);

    const togglePublish = () => {
        setIsPublished(!isPublished);
    };

    const updateVersion = () => {
        const set = questionSet?.question_sets_by_pk;
        if (!set) return;
        const yes = () => {
            // do the magic
            const newVersion = `${parseInt(version) + 1}.0`;
            saveFunction({
                category,
                frequency,
                name,
                version: newVersion,
                pages,
                set_id: set.set_id,
                subscribers: subscriberlist
            })

            // take down dialog
            setDialog({ ...dialog, visible: false });
        };

        // take down dialog
        const no = () => setDialog({ ...dialog, visible: false });

        setDialog({
            header: "Confirm Version Change",
            footer: (
                <div>
                    <Button label="No" icon="pi pi-times" onClick={no} className="p-button-text" />
                    <Button label="Yes" icon="pi pi-check" onClick={yes} autoFocus />
                </div>
            ),
            visible: true,
            message: 'Are you sure you want to update the form version? This operation cannot be undone.'
        })
    }

    return (
        <div className="card card-w-title">
            <h1>
                Question Set
                {showCopy && <Button style={{ float: "right" }} label="Copy Form" onClick={() => history.push(`/sets/copy/${questionSet?.question_sets_by_pk?.id}`)} />}
            </h1>
            <div className="p-fluid">
                <div className="p-field p-grid">
                    <label htmlFor="name" className="p-col-12 p-md-2">Name</label>
                    <div className="p-col-12 p-md-10">
                        <InputText placeholder="Name" id="name" type="text" value={name} onChange={(e: any) => setName((e.target as any).value)} onBlur={dynamicSave} />
                    </div>
                </div>
                <div className="p-field p-grid">
                    <label htmlFor="version" className="p-col-12 p-md-2">Version</label>
                    <div className="p-col-12 p-md-10">
                        <div style={{ display: "flex" }}>
                            <div style={{ flexGrow: 1, marginRight: 5 }}>
                                <InputText readOnly disabled={true} placeholder="Version" id="version" type="text" value={version} />
                            </div>
                            <div style={{ flexGrow: 0 }}>
                                <Button className="p-button-primary" label="New Version" icon="pi pi-plus" disabled={!canUpdateVersion} onClick={updateVersion} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="p-field p-grid">
                    <label htmlFor="category" className="p-col-12 p-md-2">Category</label>
                    <div className="p-col-12 p-md-10">
                        <InputText placeholder="Category" id="category" type="text" value={category} onChange={(e: any) => setCategory((e.target as any).value)} onBlur={dynamicSave} />
                    </div>
                </div>
                <div className="p-field p-grid">
                    <label htmlFor="submissionCount" className="p-col-12 p-md-2"># Submissions</label>
                    <div className="p-col-12 p-md-10">
                        <InputText placeholder="0" id="submissionCount" type="numeric" disabled={true} value={localSubmissions} />
                    </div>
                </div>
                <div className="p-field p-grid">
                    <label htmlFor="frequency" className="p-col-12 p-md-2">Frequency</label>
                    <div className="p-col-12 p-md-10">
                        <Dropdown options={freqOptions} id="frequency" value={frequency} onChange={(e) => setFrequency((e.target as any).value)} onBlur={dynamicSave} />
                    </div>
                </div>
                <div className="p-field p-grid">
                    <label htmlFor="addtemails" className="p-col-12 p-md-2">Notify when completed</label>
                    <div className="p-col-12 p-md-10">
                        <InputText id="addtemails"
                            // disabled={questionSetState === QuestionSetState.New}
                            value={currentSubscriber} placeholder="New Subscriber" type="text"
                            onChange={(e: React.FormEvent<HTMLInputElement>) => setCurrentSubscriber((e.target as any).value)}
                            onBlur={addCurrentSubscriber}
                            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => { if (e.key === "Enter") { addCurrentSubscriber(); } }}
                        />
                    </div>
                </div>
                <div className="p-field p-grid">
                    <div className="p-col-12 p-md-2"></div>
                    <div className="p-col-12 p-md-10">
                        <div className="p-grid p-flex-wrap subscriber-list">
                            {subscriberlist.map((l, k) => (
                                <div className="p-grid subscriber-list-record p-align-center" key={k}>
                                    <Button icon="pi pi-times" onClick={() => removeCurrentSubscriber(l)} />
                                    <div className="p-col">{l}</div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {
                    // Only show status when the form is a real form - don't show for copy or new
                    !showSave && (<div className="p-field p-grid">
                        <label htmlFor="setstatus" className="p-col-4 p-md-2">Form Status</label>
                        <div className="p-col-3 p-md-3">
                            <ToggleButton
                                offLabel="Draft Mode"
                                onLabel="Published"
                                checked={isPublished}
                                onChange={togglePublish} />
                        </div>
                    </div>
                    )}
                {showSave && (
                    <div className="p-field p-grid">
                        <div className="p-col-10 p-md-11 p-sm-10"></div>
                        <div className="p-col-2 p-md-1 p-sm-2">
                            {isSaving && <ProgressSpinner />}
                            <Button type="button" label="Save" disabled={isSaving} onClick={() => saveFunction({
                                category,
                                frequency,
                                name,
                                version,
                                pages,
                                subscribers: subscriberlist
                            })} />
                        </div>
                    </div>
                )}
            </div>
            <Dialog header={dialog.header} visible={dialog.visible} modal style={{ width: 450 }}
                footer={dialog.footer} onHide={() => setDialog({ ...dialog, visible: false })}>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <i className="pi pi-exclamation-triangle pr-mr-3" style={{ fontSize: '2rem', marginRight: 15 }} />
                    <span>{dialog.message}</span>
                </div>
            </Dialog>
        </div>
    );
}

const getDoLiveChanges = (questionSetState: QuestionSetState) => questionSetState !== QuestionSetState.New && questionSetState !== QuestionSetState.ExistingWithSubmissions;

interface QuestionSetEditLayoutParams {
    questionSetId: number | undefined,
    setPage: React.Dispatch<React.SetStateAction<LocalQuestionSetPage | undefined>>,
    pages: Array<LocalQuestionSetPage>,
    setPages: setPagesType,
    questionSetState: QuestionSetState
};

const QuestionSetEditLayout = ({ questionSetId, setPage, pages, setPages, questionSetState }: QuestionSetEditLayoutParams) => {

    const [expandedRows, setExpandedRows] = React.useState<any[]>([]);
    const dispatchError = GetDefaultErrorHandler(`Error editing page`);
    const dispatchAction = UseDispatchQuestionSetPageAction(questionSetState, dispatchError);

    // Change backend if this test already exists (requires question set be in server already) but doesn't already have submissions (will also be blocked in UI)
    const doLiveChanges = getDoLiveChanges(questionSetState);

    const localPages = [...pages];

    const saveChanges = () => {
        setPages([...pages]);
    }

    const setSmartExpandedRows = (data: any) => {
        // default to show rows passed in
        let newRows = data;

        const currExpanded = expandedRows;
        const newExpanded = data;

        const oldKeyIds = Object.keys(currExpanded);
        const newKeyIds = Object.keys(newExpanded);
        const addedRow = newKeyIds.length > oldKeyIds.length;

        if (addedRow) {
            // the shared key between the two is the row we don't want to expand anymore
            newRows = newKeyIds.filter(k => !oldKeyIds.find(r => r === k))
                .reduce((prev, curr) => ({ ...prev, [curr]: newExpanded[curr] }), {});
        }

        // if there are expanded rows, set page number to that row index
        const page = localPages.find(r => r.id.toString() === Object.keys(newRows)[0]);
        setPage(page);

        setExpandedRows(newRows);
    }

    const addItem = async () => {
        // Generic page
        const pn = localPages && localPages.length ? localPages[localPages.length - 1].page_number + 1 : 1;
        const page: LocalQuestionSetPage = {
            id: (pn) * -1,
            __typename: "question_pages",
            page_number: pn,
            question_groups: [],
            header: `Page ${pn}`
        };

        if (doLiveChanges) {
            const insertPageVars: InsertQuestionSetPageVariables = {
                questionSetId: questionSetId || -1,
                name: page.header,
                pageNumber: page.page_number
            };
            var newId = (await dispatchAction(QuestionSetPageActionEnum.insert, insertPageVars)) as number;
            page.id = newId;
        }

        // Change ui
        localPages.push(page);
        setPages(localPages);
    }

    const footer = genericAddFooter(questionSetState, addItem);

    const localSwap = async (indexOne: number, indexTwo: number) => {
        let insertPageVars: SwapQuestionSetPagesVariables = {
            pageOneId: pages[indexOne].id,
            pageTwoId: pages[indexTwo].id,
            pageOneOrder: pages[indexTwo].page_number,
            pageTwoOrder: pages[indexOne].page_number,
        };

        const local = swap(localPages, indexOne, indexTwo);
        // local reorder
        local.forEach((l, i) => {
            l.page_number = i + 1;
        });
        setPages(local);

        // Change backend
        await dispatchAction(QuestionSetPageActionEnum.swapPages, insertPageVars);
    }

    const localDelete = async (rowIndex: number) => {
        const deletePageVars: DeleteQuestionSetPageVariables = {
            pageId: localPages[rowIndex].id
        };

        // Change ui
        localPages.splice(rowIndex, 1);
        setPages(localPages);

        // Change backend
        await dispatchAction(QuestionSetPageActionEnum.deletePage, deletePageVars);
    }

    const localActionsTemplate = genericActionsTemplate(questionSetState, localPages, localSwap, localDelete);

    const localUpdateHeader = GenericEditHeader<LocalQuestionSetPage>(
        x => x.header,
        async (header, item) => {
            const local = localPages.find(i => i === item);
            if (local) {
                // change ui
                local.header = header;
                setPages(localPages);
            }
        },
        async (header, item) => {
            const local = localPages.find(i => i === item);
            if (local) {
                const changeHeaderVars: UpdateQuestionSetPageNameVariables = {
                    pageId: local.id,
                    name: header
                };
                await dispatchAction(QuestionSetPageActionEnum.updatePage, changeHeaderVars);
            }
        }
    )

    const setIsLocked = questionSetState === QuestionSetState.ExistingWithSubmissions;

    return (
        <div className="card card-w-title" style={{ height: "100%" }}>
            <h1>Layout</h1>
            <div className="p-fluid">
                <DataTable
                    header="Pages"
                    value={pages}
                    expandedRows={expandedRows}
                    onRowToggle={(e) => { setSmartExpandedRows(e.data) }}
                    rowExpansionTemplate={(d: LocalQuestionSetPage) => <EditPageLayout page={d} saveChanges={saveChanges} questionSetState={questionSetState} />}
                    dataKey="id"
                    scrollable={true}
                    scrollHeight="615px"
                    footer={footer}
                >

                    <Column expander style={{ width: '2em', paddingLeft: 4 }} />
                    {setIsLocked && <Column field="header" header="Page Header" />}
                    {!setIsLocked && <Column field="header" header="Page Header" editor={localUpdateHeader} />}
                    <Column body={localActionsTemplate} style={{ textAlign: 'center', width: '10em' }} />
                </DataTable>
            </div>
        </div>
    );
}

const GroupTypeEditor = (p: {
    rowData: LocalQuestionGroup,
    dispatchAction: (action: QuestionGroupActionEnum, params: QuestionGroupActions) => Promise<any>,
    saveChanges: () => void,
    setClicked: (x: boolean) => void
}) => {

    let types: FullyLoadedQuestionSet_question_sets_by_pk_question_pages_question_groups_group_type[] = [
        { id: "FreeForm", __typename: "group_types" }
        , { id: "Multiselect", __typename: "group_types" }
    ];

    const { rowData, dispatchAction, saveChanges, setClicked } = p;

    const [localValue, setLocalValue] = React.useState(rowData.group_type.id);
    const [defaultValue, setDefaultValue] = React.useState(localValue);

    // Have to play with validation to keep clicking a new option from closing the editor of the DT
    const updateGroupType = async (id: string) => {
        const matchingType = types.find(r => r.id === id);
        if (matchingType) {
            setLocalValue(matchingType.id);
            setClicked(true);
        }
    };

    const saveToServer = async () => {
        // Change ui
        rowData.group_type.id = localValue;
        saveChanges();

        // Change backend
        await dispatchAction(QuestionGroupActionEnum.update, {
            id: rowData.id,
            header: rowData.header,
            groupType: localValue as group_types_enum
        });

        // Update default
        setDefaultValue(localValue);
    }

    return (
        <div className="p-grid p-align-center">
            <div className="p-col greedy-mfer">
                <Dropdown
                    value={localValue}
                    onChange={(e) => updateGroupType(e.value)}
                    options={types} optionLabel="id" optionValue="id"
                />
            </div>
            {defaultValue !== localValue && (
                <div className="p-col giving-mfer">
                    <Button style={{ width: 30 }}
                        type="button"
                        className="p-button-primary"
                        icon="pi pi-save"
                        onClick={saveToServer} />
                </div>
            )}
        </div>
    );
};

const EditPageLayout = ({ page, saveChanges, questionSetState }: { page: LocalQuestionSetPage, saveChanges: () => void, questionSetState: QuestionSetState }) => {
    const [expandedRows, setExpandedRows] = React.useState<any[]>([]);
    const dispatchError = GetDefaultErrorHandler(`Error editing group`);
    const dispatchAction = UseDispatchQuestionGroupActions(questionSetState, dispatchError);
    const doLiveChanges = getDoLiveChanges(questionSetState);
    const localGroups: LocalQuestionGroup[] = [...page.question_groups];

    const localAddItem = async () => {
        const type = group_types_enum.FreeForm;
        const pn = localGroups && localGroups.length ? localGroups[localGroups.length - 1].order + 1 : 1;
        const group: LocalQuestionGroup = {
            id: (localGroups.length + 1) * -1,
            header: `Group ${pn}`,
            __typename: "question_groups",
            order: pn,
            group_type: { id: type, __typename: "group_types" },
            question_freeforms: [],
            question_multiselects: []
        };

        // Change backend
        if (doLiveChanges) {
            const newId = (await dispatchAction(QuestionGroupActionEnum.insert, {
                pageId: page.id,
                header: group.header,
                order: group.order,
                groupType: type
            })) as number;
            group.id = newId;
        }

        // Change ui
        const newGroups = [...localGroups, group];
        page.question_groups = newGroups;
        saveChanges();
    }

    const localFooter = genericAddFooter(questionSetState, localAddItem);

    const localSwap = async (indexOne: number, indexTwo: number) => {
        let swapVars: SwapQuestionGroupsVariables = {
            idOne: localGroups[indexOne].id,
            idTwo: localGroups[indexTwo].id,
            orderOne: localGroups[indexTwo].order,
            orderTwo: localGroups[indexOne].order
        };

        // Change ui
        const local = reorder(swap(localGroups, indexOne, indexTwo));
        page.question_groups = local;
        saveChanges();

        // Change backend
        await dispatchAction(QuestionGroupActionEnum.swap, swapVars);
    }

    const localDelete = async (rowIndex: number) => {
        const deleteVars: DeleteQuestionGroupVariables = {
            id: localGroups[rowIndex].id
        };

        // Change ui
        localGroups.splice(rowIndex, 1);
        page.question_groups = localGroups;
        saveChanges();

        // Change backend
        await dispatchAction(QuestionGroupActionEnum.delete, deleteVars);
    }

    const localActionsTemplate = genericActionsTemplate(questionSetState, localGroups, localSwap, localDelete);

    const localUpdateHeader = GenericEditHeader<LocalQuestionGroup>(
        x => x.header,
        async (header, item) => {
            const local = localGroups.find(i => i === item);
            if (local) {
                // Change ui
                local.header = header;
                page.question_groups = localGroups;
                saveChanges();
            }
        },
        async (header, item) => {
            const local = localGroups.find(i => i === item);
            if (local) {
                // Change backend
                await dispatchAction(QuestionGroupActionEnum.update, {
                    id: local.id,
                    header: local.header,
                    groupType: local.group_type.id as group_types_enum
                });
            }
        }
    );

    const setIsLocked = questionSetState === QuestionSetState.ExistingWithSubmissions;

    // Have to play with the validation to keep clicking option from closing the editor
    const [clicked, setClicked] = React.useState(false);
    function Validator() {
        // If we just clicked a new option, keep this open still
        if (clicked) {
            setClicked(false);
            return false;
        }
        return true;
    }
    return (
        <div className="p-fluid">
            <DataTable
                header="Groups"
                value={localGroups}
                expandedRows={expandedRows}
                onRowToggle={(e) => setExpandedRows(e.data)}
                rowExpansionTemplate={(d: LocalQuestionGroup) => <EditGroupLayout group={d} saveChanges={saveChanges} questionSetState={questionSetState} />}
                dataKey="id"
                footer={localFooter}
            >

                <Column expander style={{ width: '2em', paddingLeft: 4 }} />
                {setIsLocked ?
                    <Column field="header" header="Group Header" /> :
                    <Column field="header" header="Group Header" editor={localUpdateHeader} />}
                {setIsLocked ?
                    <Column field="group_type.id" header="Type" style={{ width: "10em" }} /> :
                    <Column field="group_type.id" header="Type"
                        editor={(item) => <GroupTypeEditor {...item} saveChanges={saveChanges} dispatchAction={dispatchAction} setClicked={setClicked} />}
                        style={{ width: "15em" }}
                        editorValidator={Validator} />}
                <Column body={localActionsTemplate} style={{ textAlign: 'center', width: '10em' }} />
            </DataTable>
        </div>
    );
}

const EditGroupLayout = ({ group, saveChanges, questionSetState }: { group: LocalQuestionGroup, saveChanges: () => void, questionSetState: QuestionSetState }) => {
    return group.group_type.id === "FreeForm" ?
        <FreeFormTableEditor group={group} saveChanges={saveChanges} questionSetState={questionSetState} /> :
        <MultiselectTableEditor group={group} saveChanges={saveChanges} questionSetState={questionSetState} />;
}

const DefaultValueEditor = (p: {
    rowData: LocalFreeFormQuestion,
    dispatchAction: (action: FreeformQuestionActionEnum, params: FreeformQuestionActions) => Promise<any>,
    saveChanges: () => void,
    setClicked: (x: boolean) => void
}) => {
    const { rowData, dispatchAction, saveChanges, setClicked } = p;
    const growl = React.useContext(GrowlContext);

    const defaultValueOptions = getFilteredDefaultValueOptions(rowData.value_type);
    const option = defaultValueOptions.find(x => x.value === rowData.default_value) || {
        value: rowData.default_value || DefaultValueOptionsEnum.None,
        label: rowData.default_value || DefaultValueLabelsEnum.None
    };
    const [optionLabel, setOptionLabel] = React.useState(option.label);
    const [optionValue, setOptionValue] = React.useState(option.value);
    const [defaultValue, setDefaultValue] = React.useState(optionValue);

    const handleChange = async (option: any) => {
        let value, label;
        if (!option) {
            // null option set
            value = "";
            label = "";
        } else {
            value = option.value;
            label = option.label;
        }
        setOptionLabel(label);
        setOptionValue(value);
        setClicked(true);
    };

    // NOTE: this save can happen when the question type is updated too
    const saveToServer = async () => {
        if (shouldResetDefaultValueBasedOnQuestionType(rowData.value_type, optionValue)) {
            growl?.show({ severity: 'error', summary: `Invalid default value for selected question type` });
            rowData.default_value = null;
            saveChanges();
            return;
        }

        rowData.default_value = optionValue;
        saveChanges();

        // Change backend
        const updateVars: UpdateFreeformQuestionVariables = { id: rowData.id, text: rowData.text, type: rowData.value_type, default_value: optionValue };
        await dispatchAction(FreeformQuestionActionEnum.update, updateVars);

        // Update default
        setDefaultValue(optionValue);
    }

    return (
        <div className="p-grid p-align-center">
            <div className="p-col greedy-mfer">
                <Creatable
                    isClearable
                    options={defaultValueOptions}
                    value={{ label: optionLabel, value: optionValue }}
                    onChange={(value, _) => handleChange(value)} />
            </div>
            {defaultValue !== optionValue && (
                <div className="p-col giving-mfer">
                    <Button style={{ width: 30 }}
                        type="button"
                        className="p-button-primary"
                        icon="pi pi-save"
                        onClick={saveToServer} />
                </div>
            )}
        </div>
    )
}

const FreeFormValueTypeEditor = (p: {
    updateDefaultValues: (type: value_types_enum, item: LocalFreeFormQuestion) => void,
    rowData: LocalFreeFormQuestion,
    dispatchAction: (action: FreeformQuestionActionEnum, params: FreeformQuestionActions) => Promise<any>,
    saveChanges: () => void,
    setClicked: (x: boolean) => void
}) => {
    const types: value_types_enum[] = [
        value_types_enum.Text,
        value_types_enum.Date,
        value_types_enum.Number
    ];

    const { updateDefaultValues, rowData, dispatchAction, saveChanges, setClicked } = p;

    const [localValue, setLocalValue] = React.useState(rowData.value_type);
    const [defaultValue, setDefaultValue] = React.useState(localValue);

    // Have to play with validation to keep clicking a new option from closing the editor of the DT
    const localUpdateFreeFormType = async (id: value_types_enum) => {
        const matchingType = types.find(r => r === id);
        if (matchingType) {
            setLocalValue(matchingType);
            setClicked(true);
        }
    };

    // NOTE:    when the type is updated the remote updateDefaultValues function is also
    //          called. that function can update the row's default_value based on the
    //          type that is selected. therefore, this save to server can also send the
    //          updated default value
    const saveToServer = async () => {
        // Update default value - this needs to happen before update sent off
        updateDefaultValues(localValue, rowData);

        // Change ui
        rowData.value_type = localValue;
        saveChanges();

        // Change backend
        const updateVars: UpdateFreeformQuestionVariables = {
            id: rowData.id,
            text: rowData.text,
            type: localValue,
            default_value: rowData.default_value
        };
        await dispatchAction(FreeformQuestionActionEnum.update, updateVars);

        // Update default
        setDefaultValue(localValue);
    }

    return (
        <div className="p-grid p-align-center">
            <div className="p-col greedy-mfer">
                <Dropdown
                    value={localValue}
                    onChange={(e) => localUpdateFreeFormType(e.value)}
                    options={types}
                />
            </div>
            {defaultValue !== localValue && (
                <div className="p-col giving-mfer">
                    <Button style={{ width: 30 }}
                        type="button"
                        className="p-button-primary"
                        icon="pi pi-save"
                        onClick={saveToServer} />
                </div>
            )}
        </div>
    )
}

const FreeFormTableEditor = (
    { group, saveChanges, questionSetState }:
        { group: LocalQuestionGroup, saveChanges: () => void, questionSetState: QuestionSetState }
) => {

    const localQuestions: LocalFreeFormQuestion[] = [...group.question_freeforms];
    const dispatchError = GetDefaultErrorHandler(`Error editing freeform question`);
    const dispatchAction = UseDispatchFreeformQuestionActions(questionSetState, dispatchError);
    const doLiveChanges = getDoLiveChanges(questionSetState);

    const updateDefaultValues = React.useCallback((type: value_types_enum, item: LocalFreeFormQuestion) => {
        // if the current default value is not a valid option, reset it
        if (shouldResetDefaultValueBasedOnQuestionType(type, item.default_value)) {
            item.default_value = null;
        }
    }, []);

    const localSaveChanges = (local: LocalFreeFormQuestion[]) => {
        group.question_freeforms = local;
        saveChanges()
    }

    const addItem = async () => {
        const pn = localQuestions && localQuestions.length ? localQuestions[localQuestions.length - 1].order + 1 : 1;
        const question: LocalFreeFormQuestion = {
            __typename: "question_freeforms",
            order: pn,
            text: `Question #${pn}`,
            value_type: value_types_enum.Text,
            id: -1,
            default_value: null
        };

        // Change backend
        if (doLiveChanges) {
            const newId = (await dispatchAction(FreeformQuestionActionEnum.insert, {
                groupId: group.id,
                text: question.text,
                order: question.order,
                type: question.value_type
            })) as number;
            question.id = newId;
        }

        // Change ui
        localQuestions.push(question);
        localSaveChanges(localQuestions);
    };

    const localSwap = async (indexOne: number, indexTwo: number) => {
        let swapVars: SwapFreeformQuestionsVariables = {
            idOne: localQuestions[indexOne].id,
            idTwo: localQuestions[indexTwo].id,
            orderOne: localQuestions[indexTwo].order,
            orderTwo: localQuestions[indexOne].order
        };

        const local = reorder(swap(localQuestions, indexOne, indexTwo));
        localSaveChanges(local);

        await dispatchAction(FreeformQuestionActionEnum.swap, swapVars);
    }

    const localDelete = async (rowIndex: number) => {
        const deleteVars: DeleteFreeformQuestionVariables = { id: localQuestions[rowIndex].id };

        // CHange ui
        localQuestions.splice(rowIndex, 1);
        localSaveChanges(localQuestions);

        // CHange backend
        await dispatchAction(FreeformQuestionActionEnum.delete, deleteVars);
    }

    const actions = genericActionsTemplate(questionSetState, localQuestions, localSwap, localDelete);
    const footer = genericAddFooter(questionSetState, addItem);
    const headerUpdate = GenericEditHeader<LocalFreeFormQuestion>(
        x => x.text,
        async (header, item) => {
            const local = localQuestions.find(i => i === item);
            if (local) {
                // Change ui
                local.text = header;
                localSaveChanges(localQuestions);
            }
        },
        async (header, item) => {
            const local = localQuestions.find(i => i === item);
            if (local) {
                // Change backend
                const updateVars: UpdateFreeformQuestionVariables = { id: local.id, text: header, type: item.value_type, default_value: item.default_value };
                await dispatchAction(FreeformQuestionActionEnum.update, updateVars);
            }
        }
    )

    const setIsLocked = questionSetState === QuestionSetState.ExistingWithSubmissions;

    // Have to play with the validation to keep clicking option from closing the editor
    const [defaultValueClicked, setDefaultValueClicked] = React.useState(false);
    function DefaultValueValidator() {
        // If we just clicked a new option, keep this open still
        if (defaultValueClicked) {
            setDefaultValueClicked(false);
            return false;
        }
        return true;
    }

    // Have to play with the validation to keep clicking option from closing the editor
    const [freeFormTypeClicked, setFreeFormTypeClicked] = React.useState(false);
    function FreeFormTypeValidator() {
        // If we just clicked a new option, keep this open still
        if (freeFormTypeClicked) {
            setFreeFormTypeClicked(false);
            return false;
        }
        return true;
    }

    return (
        <div className="p-fluid">
            <DataTable
                header="Free Form Questions"
                value={localQuestions}
                dataKey="order"
                footer={footer}
            >
                {setIsLocked ?
                    <Column field="text" header="Question" /> :
                    <Column field="text" header="Question" editor={headerUpdate} />}
                {setIsLocked ?
                    <Column field="value_type" header="Type" /> :
                    <Column field="value_type"
                        header="Type"
                        editorValidator={FreeFormTypeValidator}
                        editor={(item) => <FreeFormValueTypeEditor {...item} saveChanges={() => localSaveChanges(localQuestions)} dispatchAction={dispatchAction} setClicked={setFreeFormTypeClicked} updateDefaultValues={updateDefaultValues} />} />}
                {setIsLocked ?
                    <Column
                        header="Default Value"
                        body={(r: LocalFreeFormQuestion) => DefaultValueOptions.find(x => x.value === r.default_value)?.label || r.default_value} /> :
                    <Column header="Default Value"
                        body={(r: LocalFreeFormQuestion) => DefaultValueOptions.find(x => x.value === r.default_value)?.label || r.default_value}
                        editorValidator={DefaultValueValidator}
                        editor={(item) => <DefaultValueEditor {...item} saveChanges={() => localSaveChanges(localQuestions)} dispatchAction={dispatchAction} setClicked={setDefaultValueClicked} />} />}
                <Column body={actions} style={{ textAlign: 'center', width: '10em' }} />
            </DataTable>
        </div>
    );
}

const MultiselectTableEditor = (
    { group, saveChanges, questionSetState }:
        { group: LocalQuestionGroup, saveChanges: () => void, questionSetState: QuestionSetState }
) => {

    const [expandedRows, setExpandedRows] = React.useState<any[]>([]);
    const dispatchError = GetDefaultErrorHandler(`Error editing multiselect question`);
    const dispatchAction = UseDispatchMultiselectQuestionActions(questionSetState, dispatchError);
    const doLiveChanges = getDoLiveChanges(questionSetState);
    const localQuestions: LocalMultiSelectQuestion[] = [...group.question_multiselects];

    const localSaveChanges = (local: LocalMultiSelectQuestion[]) => {
        group.question_multiselects = local;
        saveChanges();
    }

    const addItem = async () => {
        const pn = localQuestions && localQuestions.length ? localQuestions[localQuestions.length - 1].order + 1 : 1;
        const question: LocalMultiSelectQuestion = {
            __typename: "question_multiselects",
            order: pn,
            text: `Question #${pn}`,
            question_answer_set: {
                __typename: "question_answer_set",
                name: 'generated',
                question_answer_options: [],
                id: -1
            },
            id: -1
        };

        // Change backend
        if (doLiveChanges) {
            const newIds = (await dispatchAction(MultiselectQuestionActionEnum.insert, {
                groupId: group.id,
                text: question.text,
                order: question.order,
                answerSetName: question.question_answer_set.name
            })) as { id: number, questionAnswerSetId: number };
            question.id = newIds.id;
            question.question_answer_set.id = newIds.questionAnswerSetId;
        }

        // Change ui
        localQuestions.push(question);
        localSaveChanges(localQuestions);
    };

    const localSwap = async (indexOne: number, indexTwo: number) => {
        let swapVars: SwapMultiselectQuestionsVariables = {
            idOne: localQuestions[indexOne].id,
            idTwo: localQuestions[indexTwo].id,
            orderOne: localQuestions[indexTwo].order,
            orderTwo: localQuestions[indexOne].order
        };

        const local = reorder(swap(localQuestions, indexOne, indexTwo));
        localSaveChanges(local);

        await dispatchAction(MultiselectQuestionActionEnum.swap, swapVars);
    }

    const localDelete = async (rowIndex: number) => {
        const deleteVars: DeleteMultiselectQuestionVariables = { id: localQuestions[rowIndex].id };

        localQuestions.splice(rowIndex, 1);
        localSaveChanges(localQuestions);

        // CHange backend
        await dispatchAction(MultiselectQuestionActionEnum.delete, deleteVars);
    }

    const actions = genericActionsTemplate(questionSetState, localQuestions, localSwap, localDelete);
    const footer = genericAddFooter(questionSetState, addItem);
    const headerUpdate = GenericEditHeader<LocalMultiSelectQuestion>(
        x => x.text,
        (header, item) => {
            const local = localQuestions.find(i => i === item);
            if (local) {
                local.text = header;
                localSaveChanges(localQuestions);
            }
        },
        async (header, item) => {
            const local = localQuestions.find(i => i === item);
            if (local) {
                // Change backend
                await dispatchAction(MultiselectQuestionActionEnum.update, { id: local.id, text: header });
            }
        }
    )

    const setIsLocked = questionSetState === QuestionSetState.ExistingWithSubmissions;

    return (
        <div className="p-fluid">
            <DataTable
                header="Multiselect Questions"
                value={localQuestions}
                expandedRows={expandedRows}
                onRowToggle={(e) => setExpandedRows(e.data)}
                rowExpansionTemplate={(d: LocalMultiSelectQuestion) => <EditMultiselectAnswerOptions question={d} saveChanges={saveChanges} questionSetState={questionSetState} />}
                dataKey="order"
                footer={footer}>
                <Column expander style={{ width: '2em', paddingLeft: 4 }} />
                {setIsLocked ?
                    <Column field="text" header="Question" /> :
                    <Column field="text" header="Question" editor={headerUpdate} />}
                <Column body={actions} style={{ textAlign: 'center', width: '10em' }} />
            </DataTable>
        </div>
    );
}

const EditMultiselectAnswerOptions = (
    { question, saveChanges, questionSetState }:
        { question: LocalMultiSelectQuestion, saveChanges: () => void, questionSetState: QuestionSetState }
) => {
    const localOptions: LocalMultiselectAnswerOption[] = [...question.question_answer_set.question_answer_options];
    const dispatchError = GetDefaultErrorHandler(`Error editing answer option`);
    const dispatchAction = UseDispatchAnswerOptionActions(questionSetState, dispatchError);
    const doLiveChanges = getDoLiveChanges(questionSetState);
    const growl = React.useContext(GrowlContext);

    const localSaveChanges = (local: LocalMultiselectAnswerOption[]) => {
        question.question_answer_set.question_answer_options = local;
        saveChanges();
    }

    const swapOptions = async (indexOne: number, indexTwo: number) => {
        let swapVars: SwapAnswerOptionsVariables = {
            idOne: localOptions[indexOne].id,
            idTwo: localOptions[indexTwo].id,
            orderOne: localOptions[indexTwo].order,
            orderTwo: localOptions[indexOne].order
        };

        const local = reorder(swap(localOptions, indexOne, indexTwo));
        localSaveChanges(local);

        await dispatchAction(AnswerOptionActionEnum.swap, swapVars);
    };

    const deleteOption = async (rowIndex: number) => {
        const deleteVars: DeleteAnswerOptionVariables = { id: localOptions[rowIndex].id };

        // Change ui
        localOptions.splice(rowIndex, 1);
        localSaveChanges(localOptions);

        // Change backend
        await dispatchAction(AnswerOptionActionEnum.delete, deleteVars);

    }

    const addNewItem = async () => {
        const pn = localOptions && localOptions.length ? localOptions[localOptions.length - 1].order + 1 : 1;
        const localOption: LocalMultiselectAnswerOption = {
            __typename: "question_answer_options",
            header: `Option #${pn}`,
            order: pn,
            value: `${pn}`,
            id: -1 * pn
        };

        // Change backend
        if (doLiveChanges) {
            const newId = (await dispatchAction(AnswerOptionActionEnum.insert, {
                setId: question.question_answer_set.id,
                order: question.order,
                header: localOption.header,
                value: localOption.value
            })) as number;
            localOption.id = newId;
        }

        // Change ui
        localOptions.push(localOption);
        localSaveChanges(localOptions);
    }

    const localActionTemplate = genericActionsTemplate(questionSetState, localOptions, swapOptions, deleteOption);
    const localFooter = genericAddFooter(questionSetState, addNewItem);
    const localUpdateHeader = GenericEditHeader<LocalMultiselectAnswerOption>(
        (x) => x.header,
        (header, item) => {
            const localItem = localOptions.find(i => i === item);
            if (localItem) {
                localItem.header = header;
                localSaveChanges(localOptions);
            }
        },
        async (header, item) => {
            const local = localOptions.find(i => i === item);
            if (local) {
                // Change backend
                await dispatchAction(AnswerOptionActionEnum.update, { id: local.id, header: header, value: local.value });
            }
        }
    )

    const localUpdateValue = GenericEditHeader<LocalMultiselectAnswerOption>(
        x => x.value,
        (value, item) => {
            const local = localOptions.find(i => i === item);
            if (local) {
                // make sure nothing else has this value
                const valueExists = localOptions.filter(f => f !== item).find(f => f.value === value);
                if (!valueExists) {
                    local.value = value;
                    localSaveChanges(localOptions);
                } else {
                    growl?.show({ severity: 'error', summary: 'Error setting value', detail: `Cannot re-use value ${value} twice in the same question` });
                    return;
                }
            }
        },
        async (value, item) => {
            const local = localOptions.find(i => i === item);
            if (local) {
                // Change backend
                await dispatchAction(AnswerOptionActionEnum.update, { id: local.id, header: local.header, value: value });
            }
        }
    )

    const setIsLocked = questionSetState === QuestionSetState.ExistingWithSubmissions;

    return (
        <div className="p-fluid">
            <DataTable
                header="Answer Options"
                value={localOptions}
                dataKey="order"
                sortField="order"
                sortOrder={1}
                footer={localFooter}>
                {setIsLocked ?
                    <Column field="header" header="Answer" /> :
                    <Column field="header" header="Answer" editor={localUpdateHeader} />}
                {setIsLocked ?
                    <Column field="value" header="Value" /> :
                    <Column field="value" header="Value" editor={localUpdateValue} />}
                <Column body={localActionTemplate} style={{ textAlign: 'center', width: '10em' }} />
            </DataTable>
        </div>
    )
}

const useQuestionSetState = ({ isIdDefined, submissions }: { isIdDefined: boolean, submissions: number }) => {
    const [questionSetState, setQuestionSetState] = React.useState(QuestionSetState.New);

    React.useEffect(() => {
        // When validation or # of submissions changes, update readonly
        if (isIdDefined && submissions) {
            setQuestionSetState(QuestionSetState.ExistingWithSubmissions);
        } else if (isIdDefined) {
            setQuestionSetState(QuestionSetState.ExistingNoSubmissions);
        } else {
            setQuestionSetState(QuestionSetState.New);
        }
    }, [isIdDefined, submissions]);

    return questionSetState;
}

interface saveNewQuestionSetParams { category: string, frequency: types_frequency_enum, name: string, version: string, pages: Array<LocalQuestionSetPage>, set_id?: string, subscribers?: string[] };
type saveFunctionType = (x: saveNewQuestionSetParams) => Promise<void>;
const useSaveNewQuestionSet = (): [boolean, React.Dispatch<boolean>, saveFunctionType] => {
    const history = useHistory();
    const [insert] = useMutation<InsertQuestionSet, InsertQuestionSetVariables>(insertQuestionSet);
    const growl = React.useContext(GrowlContext);

    const [isSaving, setIsSaving] = React.useState(false);

    // Used to save a new question set - will not do any checks on submissions, etc.
    const saveNewQuestionSet = async (params: saveNewQuestionSetParams) => {
        if (!params.name) {
            growl?.show({ severity: 'error', summary: 'Form name is required' });
            return;
        }

        setIsSaving(true);

        const allAnswerOptionSets: question_answer_set_insert_input[] = Array<question_answer_set_insert_input>();

        // Breaking out so it's easier to see what's happening
        const convertQuestionSetAnswerOptionToDto = (option: FullyLoadedQuestionSet_question_sets_by_pk_question_pages_question_groups_question_multiselects_question_answer_set_question_answer_options)
            : question_answer_options_insert_input => {
            return {
                header: option.header,
                order: option.order,
                value: option.value
            };
        };

        const convertQuestionsToFreeForms = (questions: FullyLoadedQuestionSet_question_sets_by_pk_question_pages_question_groups_question_freeforms[]): question_freeforms_arr_rel_insert_input => {
            const data: question_freeforms_insert_input[] = questions.map(question => ({
                text: question.text,
                value_type: question.value_type,
                order: question.order
            }));

            return { data };
        }

        const convertQuestionsToMultiselects = (questions: FullyLoadedQuestionSet_question_sets_by_pk_question_pages_question_groups_question_multiselects[]): question_multiselects_arr_rel_insert_input => {
            const data: question_multiselects_insert_input[] = questions.map(question => {
                const answerSetOptions: question_answer_set_insert_input = {
                    name: question.question_answer_set.name,
                    question_answer_options: {
                        data: question.question_answer_set.question_answer_options.map(convertQuestionSetAnswerOptionToDto)
                    }
                };

                allAnswerOptionSets.push(answerSetOptions);

                return {
                    order: question.order,
                    text: question.text,
                    question_answer_set: { data: answerSetOptions }
                };
            });

            return { data };
        }

        const convertGroupsToDto = (groups: LocalQuestionGroup[]): question_groups_arr_rel_insert_input => {
            const data: question_groups_insert_input[] = groups.map(group => ({
                group_type_id: group.group_type.id as group_types_enum,
                header: group.header,
                order: group.order,
                question_freeforms: group.group_type.id === group_types_enum.FreeForm ? convertQuestionsToFreeForms(group.question_freeforms) : null,
                question_multiselects: group.group_type.id === group_types_enum.Multiselect ? convertQuestionsToMultiselects(group.question_multiselects) : null
            }));

            return { data };
        }

        const convertPageToDto = (page: LocalQuestionSetPage): question_pages_insert_input => ({
            header: page.header,
            page_number: page.page_number,
            question_groups: convertGroupsToDto(page.question_groups)
        });

        debugger;
        const insertVariables: InsertQuestionSetVariables = {
            qs: [{
                category: params.category,
                frequency: params.frequency,
                name: params.name,
                version: params.version,
                set_id: params.set_id,
                question_pages: {
                    data: params.pages.map(convertPageToDto)
                },
                question_set_notification_subscriptions: {
                    data: params.subscribers?.map(m => ({
                        email_address: m
                    })) || []
                }
            }]
        };

        try {
            const { data } = await insert({ variables: insertVariables });
            growl?.show({ severity: 'success', summary: 'Test saved', detail: 'Test saved successfully!' });
            history.push(`/sets/edit/${data?.insert_question_sets?.returning[0].id}`);
            console.log('data', data);
        } catch (e) {
            console.error(e);
            growl?.show({ severity: 'error', summary: 'Error saving test', detail: `${e}` });
        }

        setIsSaving(false);
    };

    return [isSaving, setIsSaving, saveNewQuestionSet];
}

interface CreateQuestionSetParams { };
interface EditQuestionSetParams { id: number };
interface CopyQuestionSetParams { idToCopy: number };
type CreateEditQuestionSetParams = CreateQuestionSetParams | EditQuestionSetParams | CopyQuestionSetParams;
const isEditQuestionSet = (p: CreateEditQuestionSetParams): p is EditQuestionSetParams => (p as EditQuestionSetParams).id !== undefined;
const isCopyQuestionSet = (p: CreateEditQuestionSetParams): p is CopyQuestionSetParams => (p as CopyQuestionSetParams).idToCopy !== undefined;

function CreateEditQuestionSet(props: CreateEditQuestionSetParams) {
    let id: number = -1;
    let idToCopy: number = -1;
    if (isEditQuestionSet(props)) {
        id = props.id;
    } else if (isCopyQuestionSet(props)) {
        idToCopy = props.idToCopy
    }

    const [pages, setPages] = React.useState(Array<LocalQuestionSetPage>());
    const [expandedPage, setExpandedPage] = React.useState<LocalQuestionSetPage | undefined>();

    const [submissions, setSubmissions] = React.useState(0);
    const [isSaving, setIsSaving, saveFunction] = useSaveNewQuestionSet();
    const questionSetState = useQuestionSetState({ isIdDefined: id !== -1, submissions })

    const passedInId = id !== -1 ? id : idToCopy !== -1 ? idToCopy : -1;
    const idToLoad = questionSetState === QuestionSetState.New ? -1 : id;
    /* 
        If we are editing a question set the ID will be the existing id - need to get that record from the db
        and do the edits on it directly
    */
    const { data } = useQuery<FullyLoadedQuestionSet, FullyLoadedQuestionSetVariables>(getFullyLoadedQuestionSetTemplate, {
        variables: { id: passedInId },
        skip: passedInId === -1
    });

    React.useEffect(() => {
        if (!data?.question_sets_by_pk) { return; }
        const set = data.question_sets_by_pk;
        setPages([...set.question_pages]);
    }, [data]);

    return (
        <>
            <div className="p-grid">
                <div className="p-col-12">
                    <QuestionSetInfoCard id={idToLoad} questionSetState={questionSetState} pages={pages} setSubmissions={setSubmissions} saveFunction={saveFunction} isSaving={isSaving} setIsSaving={setIsSaving} questionSet={data} />
                </div>
            </div>
            <div className="p-grid" style={{ height: "800px" }}>
                <div className="p-col-8">
                    <QuestionSetEditLayout questionSetId={idToLoad} setPage={setExpandedPage} pages={pages} setPages={setPages} questionSetState={questionSetState} />
                </div>
                <div className="p-col-4">
                    <QuestionSetViewerCard page={expandedPage} />
                </div>
            </div>
        </>
    )
}

export function CreateQuestionSet() {
    const params: CreateQuestionSetParams = {};
    return <CreateEditQuestionSet {...params} />;
}

export function EditQuestionSet(props: { match: { params: { id: number } } }) {
    const params: EditQuestionSetParams = { id: props.match.params.id };
    return <CreateEditQuestionSet {...params} />;
}

export function CopyQuestionSet(props: { match: { params: { id: number } } }) {
    const params: CopyQuestionSetParams = { idToCopy: props.match.params.id };
    return <CreateEditQuestionSet {...params} />
}