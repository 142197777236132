import React from 'react';
import { insertQuestionGroup, updateQuestionGroup, swapQuestionGroups, deleteQuestionGroup } from '../../graphql/questionSets';
import { useMutation } from '@apollo/react-hooks';

import { QuestionSetState } from './CreateEditQuestionSet';
import { InsertQuestionGroupVariables, InsertQuestionGroup } from '../../graphql/types/InsertQuestionGroup';
import { UpdateQuestionGroupVariables, UpdateQuestionGroup } from '../../graphql/types/UpdateQuestionGroup';
import { DeleteQuestionGroupVariables, DeleteQuestionGroup } from '../../graphql/types/DeleteQuestionGroup';
import { SwapQuestionGroupsVariables, SwapQuestionGroups } from '../../graphql/types/SwapQuestionGroups';

export enum QuestionGroupActionEnum {
    insert, update, swap, delete
};

export type QuestionGroupActions = InsertQuestionGroupVariables | UpdateQuestionGroupVariables | SwapQuestionGroupsVariables | DeleteQuestionGroupVariables;
export function UseDispatchQuestionGroupActions(questionSetState: QuestionSetState, errorHandler: (e: any) => void) {
    const [insert] = useMutation<InsertQuestionGroup, InsertQuestionGroupVariables>(insertQuestionGroup);
    const [updateText] = useMutation<UpdateQuestionGroup, UpdateQuestionGroupVariables>(updateQuestionGroup);
    const [swap] = useMutation<SwapQuestionGroups, SwapQuestionGroupsVariables>(swapQuestionGroups);
    const [deleteItem] = useMutation<DeleteQuestionGroup, DeleteQuestionGroupVariables>(deleteQuestionGroup);

    const action = React.useMemo(() => {
        if (questionSetState === QuestionSetState.New) {
            // new test - don't do intermediate saves
            return () => Promise.resolve();
        }
        else if (questionSetState === QuestionSetState.ExistingWithSubmissions) {
            // test with submissions - don't do intermediate saves
            return () => Promise.resolve();
        } else {
            // existing test but has no submissions - do intermediate saves
            return (action: QuestionGroupActionEnum, params: QuestionGroupActions) => {
                if (action === QuestionGroupActionEnum.insert) {
                    const insertVars = params as InsertQuestionGroupVariables;
                    return insert({ variables: insertVars })
                        .then(r => r?.data?.insert_question_groups?.returning[0]?.id || -1)
                        .catch(errorHandler);
                }
                else if (action === QuestionGroupActionEnum.update) {
                    const editVars = params as UpdateQuestionGroupVariables;
                    return updateText({ variables: editVars }).catch(errorHandler);
                }
                else if (action === QuestionGroupActionEnum.swap) {
                    const swapVars = params as SwapQuestionGroupsVariables;
                    return swap({ variables: swapVars }).catch(errorHandler);
                }
                else if (action === QuestionGroupActionEnum.delete) {
                    const deleteVars = params as DeleteQuestionGroupVariables;
                    return deleteItem({ variables: deleteVars }).catch(errorHandler);
                }
                else {
                    return Promise.resolve();
                }
            };
        }
    }, [questionSetState]);

    return action;
}