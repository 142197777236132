import React from 'react';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { useQuery, useMutation } from '@apollo/react-hooks'

import { Button } from 'primereact/button';
import { ToggleButton } from 'primereact/togglebutton';
import { MultiSelect } from 'primereact/multiselect';
import { useHistory } from 'react-router-dom';

import { getAllCategoryTypes } from '../../graphql/questionSets';
import { getUserById, setUserIsEnabled, updateUser } from '../../graphql/users';
import { GetUserById, GetUserByIdVariables } from '../../graphql/types/GetUserById';
import { SetUserIsEnabled, SetUserIsEnabledVariables } from '../../graphql/types/SetUserIsEnabled';

import GrowlContext from '../../service/GrowlContext';
import { GetCategories } from '../../graphql/types/GetCategories';
import { UpdateUserCategories, UpdateUserCategoriesVariables } from '../../graphql/types/UpdateUserCategories';
import { user_category_insert_input } from '../../types/graphql-global-types';

const EditUser = ({match}: any) => {
    const { email } = match.params;
    const history = useHistory();

    const [formEmail, setFormEmail] = React.useState('');
    const [phone, setPhone] = React.useState('');
    const [org, setOrg] = React.useState('');
    const [createdAt, setCreatedAt] = React.useState('');
    const [isEnabled, setIsEnabled] = React.useState(false);
    const [userCategories, setUserCategories] = React.useState(Array<string>());

    const growl = React.useContext(GrowlContext);

    const {loading, data, error} = useQuery<GetUserById, GetUserByIdVariables>(getUserById, {variables: {email: email}});
    const {data: rawCategories} = useQuery<GetCategories>(getAllCategoryTypes);
    const [updateIsEnabledMutation] = useMutation<SetUserIsEnabled, SetUserIsEnabledVariables>(setUserIsEnabled);
    const [updateUserMutation] = useMutation<UpdateUserCategories, UpdateUserCategoriesVariables>(updateUser);

    // Show all categories that they have and all possible categories on the tests coming in
    const testCategories = rawCategories?.question_sets?.map(q => q.category) || [];
    const userOwnedCategories = data?.users[0]?.user_categories?.map(q => q.category) || [];
    const allIncomingCategories = Array.from(new Set([...testCategories, ...userOwnedCategories]))
        .filter(q => q) // remove empty categories
        .map(q => ({label: q, value: q })) 
        .sort((l1, l2) => l1 > l2 ? 1 : -1); // alphabetical order;

    const categories = [ 
        {label: 'ALL', value: 'ALL'},
        ...allIncomingCategories
    ];
    debugger;

    React.useEffect(() => {
        if (data?.users?.length) {
            setFormEmail(data.users[0].email);
            setPhone(data.users[0].phone);
            setCreatedAt(data.users[0].created_at);
            setOrg(data.users[0].organization?.name || '');
            setIsEnabled(!data.users[0].is_disabled);
            setUserCategories(data.users[0].user_categories.map(m => m.category));
        } else if (!loading && !data?.users?.length) {
            // if user not found, go back to dashboard
            history.push('/');
        }
    }, [data]);

    const goBack = () => history.goBack();

    const setEnabled = ({value}: {value: boolean}) => {
        updateIsEnabledMutation({
            variables: { email: email.toLowerCase(), isDisabled: !value }
        }).then(r => {
            setIsEnabled(value);
            growl?.show({severity: 'success', summary: 'Status Changed', detail: `User ${email} status is now ${value ? 'enabled' : 'disabled'}`});
        }).catch((e) => {
            console.error('Unable to change user is enabled', e);
            growl?.show({severity: 'error', summary: 'Error', detail: `Unable to set user ${email} status to ${value ? 'enabled' : 'disabled'}`});
        });
    }
    
    const setCategory = (e: string[]) => {
        setUserCategories(e);
        console.log(e);
    }

    const saveUser = () => {
        // for now only saves the new categories
        const currentCategories = data?.users[0]?.user_categories || [];
        const newCategories = userCategories;

        const categoriesToAdd: user_category_insert_input[] = newCategories.filter(r => !currentCategories.find(x => x.category === r)).map(r => ({
            category: r,
            user_id: data?.users[0].id
        }));
        const categoriesToDelete = currentCategories.filter(r => !newCategories.includes(r.category)).map(r => r.id);

        updateUserMutation({
            variables: {
                insert: categoriesToAdd,
                delete: categoriesToDelete
            }
        }).then(r => {
            growl?.show({severity: 'success', summary: 'Saved user', detail: `Saved user ${email}`});
        })
        .catch(e => {
            console.error('error updating user', e);
            growl?.show({severity: 'error', summary: 'Error updating user', detail: e});
        });
    }

    return (
        <div className="p-grid">
            <div className="p-col-12">
                <div className="card card-w-title">
                    <h1>Account Page</h1>
                    <div className="p-fluid">
                        <div className="p-field p-grid">
                            <label htmlFor="email" className="p-col-12 p-md-2">Email</label>
                            <div className="p-col-12 p-md-10">
                                <InputText
                                    id="email" 
                                    type="email"
                                    value={formEmail} 
                                    readOnly={true}/>
                            </div>
                        </div>
                        <div className="p-field p-grid">
                            <label htmlFor="phone" className="p-col-12 p-md-2">Phone</label>
                            <div className="p-col-12 p-md-10">
                                <InputText 
                                    id="phone" 
                                    value={phone}
                                    readOnly={true} />
                            </div>
                        </div>
                        <div className="p-field p-grid">
                            <label htmlFor="org" className="p-col-12 p-md-2">Organization</label>
                            <div className="p-col-12 p-md-10">
                                <InputText 
                                    id="org" 
                                    value={org}
                                    readOnly={true} />
                            </div>
                        </div>
                        <div className="p-field p-grid">
                            <label htmlFor="createdOn" className="p-col-12 p-md-2">Categories</label>
                            <div className="p-col-12 p-md-10">
                                <MultiSelect value={userCategories} options={categories} filter={false} onChange={(e) => setCategory(e.value)} />
                            </div>
                        </div>
                        <div className="p-field p-grid">
                            <label htmlFor="createdOn" className="p-col-12 p-md-2">Created On</label>
                            <div className="p-col-12 p-md-10">
                                <Calendar placeholder="Created On" id="createdOn" disabled={true} showTime value={new Date(createdAt)} />
                            </div>
                        </div>
                        <div className="p-field p-grid">
                            <label htmlFor="createdOn" className="p-col-12 p-md-2">Enabled</label>
                            <div className="p-col-12 p-md-10">
                                <ToggleButton checked={isEnabled} onChange={setEnabled} />
                            </div>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
                            <Button type="button" className="p-button-success" label="Save" style={{width: '4em'}} onClick={saveUser} /*disabled={true}*/ />
                            <Button type="button" className="p-button-danger" label="Back" style={{width: '4em'}} onClick={goBack}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditUser;