import * as React from 'react';

interface User {
    username: string,
    attributes: {
        'custom:Organization_ID': string,
        email: string,
        email_verified: boolean,
        phone_number: string,
        phone_number_verified: boolean,
        sub: string
    },
    signInUserSession: {
        idToken: {
            jwtToken: string,
            payload: {
                orgId: number,
                isAdmin: string
            }
        }
    }
};

export const isUserAnAdmin = (user: User|null) => user?.signInUserSession?.idToken?.payload?.isAdmin === "true";

//let user: User | null = null;
const UserContext = React.createContext<User|null>(null);
export default UserContext;