import React from 'react';
import { insertAnswerOption, updateAnswerOption, swapAnswerOptions, deleteAnswerOption } from '../../graphql/questionSets';
import { useMutation } from '@apollo/react-hooks';

import { QuestionSetState } from './CreateEditQuestionSet';
import { InsertAnswerOptionVariables, InsertAnswerOption } from '../../graphql/types/InsertAnswerOption';
import { UpdateAnswerOptionVariables, UpdateAnswerOption } from '../../graphql/types/UpdateAnswerOption';
import { SwapAnswerOptionsVariables, SwapAnswerOptions } from '../../graphql/types/SwapAnswerOptions';
import { DeleteAnswerOptionVariables, DeleteAnswerOption } from '../../graphql/types/DeleteAnswerOption';
import { InsertMultiselectQuestionVariables } from '../../graphql/types/InsertMultiselectQuestion';
import { UpdateMultiselectQuestionVariables } from '../../graphql/types/UpdateMultiselectQuestion';
import { SwapMultiselectQuestionsVariables } from '../../graphql/types/SwapMultiselectQuestions';
import { DeleteMultiselectQuestionVariables } from '../../graphql/types/DeleteMultiselectQuestion';

export enum AnswerOptionActionEnum {
    insert, update, swap, delete
};

type AnswerOptionActionsActions = InsertAnswerOptionVariables | UpdateAnswerOptionVariables | SwapAnswerOptionsVariables | DeleteAnswerOptionVariables;
export function UseDispatchAnswerOptionActions(questionSetState: QuestionSetState, errorHandler: (e: any) => void) {
    const [insert] = useMutation<InsertAnswerOption, InsertAnswerOptionVariables>(insertAnswerOption);
    const [updateText] = useMutation<UpdateAnswerOption, UpdateAnswerOptionVariables>(updateAnswerOption);
    const [swap] = useMutation<SwapAnswerOptions, SwapAnswerOptionsVariables>(swapAnswerOptions);
    const [deleteItem] = useMutation<DeleteAnswerOption, DeleteAnswerOptionVariables>(deleteAnswerOption);

    const action = React.useMemo(() => {
        if (questionSetState === QuestionSetState.New) {
            // new test - don't do intermediate saves
            return () => Promise.resolve();
        }
        else if (questionSetState === QuestionSetState.ExistingWithSubmissions) {
            // test with submissions - don't do intermediate saves
            return () => Promise.resolve();
        } else {
            // existing test but has no submissions - do intermediate saves
            return (action: AnswerOptionActionEnum, params: AnswerOptionActionsActions) => {
                if (action === AnswerOptionActionEnum.insert) {
                    const insertVars = params as InsertAnswerOptionVariables;
                    return insert({ variables: insertVars })
                        .then(r => r.data?.insert_question_answer_options?.returning[0]?.id || -1)
                        .catch(errorHandler);
                }
                else if (action === AnswerOptionActionEnum.update) {
                    const editVars = params as UpdateAnswerOptionVariables;
                    return updateText({ variables: editVars }).catch(errorHandler);
                }
                else if (action === AnswerOptionActionEnum.swap) {
                    const swapVars = params as SwapAnswerOptionsVariables;
                    return swap({ variables: swapVars }).catch(errorHandler);
                }
                else if (action === AnswerOptionActionEnum.delete) {
                    const deleteVars = params as DeleteAnswerOptionVariables;
                    return deleteItem({ variables: deleteVars }).catch(errorHandler);
                }
                else {
                    return Promise.resolve();
                }
            };
        }
    }, [questionSetState]);

    return action;
}