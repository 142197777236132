import React from 'react';
import { SignIn } from 'aws-amplify-react';
import { Button } from "primereact/button";

export class CustomSignIn extends SignIn {
  constructor(props) {
    super(props);
    this._validAuthStates = ["signIn", "signedOut", "signedUp"];
    // this.signIn = this.signIn.bind(this);
    // this.handleInputChange = this.handleInputChange.bind(this);

    // this.handleFormSubmission = this.handleFormSubmission.bind(this);
    // this.state = {};
  }

  getUsernameFromInput() {
    let userName = super.getUsernameFromInput();
    return userName.toLowerCase();
  }

  showComponent(theme) {
    return (<div className="login-body">
      <div className="body-container">
        <div className="p-grid p-nogutter">
          <div className="p-col-12">
            <div className="login-wrapper">
              <div className="login-container">
                <img src="assets/layout/images/Mach1-Forms.png" alt="mach1-forms" />
                <span className="title">Login to Mach1 Forms</span>
                <div className="p-grid p-fluid">
                  <div className="p-col-12">
                    <input
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker leading-tight focus:outline-none focus:shadow-outline"
                      id="username"
                      key="username"
                      name="username"
                      onChange={this.handleInputChange}
                      type="text"
                      placeholder="Username"
                    />
                  </div>
                  <div className="p-col-12">
                    <input
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker mb-3 leading-tight focus:outline-none focus:shadow-outline"
                      id="password"
                      key="password"
                      name="password"
                      onChange={this.handleInputChange}
                      type="password"
                      placeholder="******************"
                    />
                  </div>
                  <p className="text-grey-dark text-xs">
                    Forgot your password?{" "}
                    <a
                      className="text-indigo cursor-pointer hover:text-indigo-darker"
                      onClick={() => super.changeState("forgotPassword")}
                    >
                      Reset Password
                    </a>
                  </p>
                  <div className="p-col-6">
                    <Button label="Login" icon="pi pi-check" onClick={() => super.signIn()} />
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>);


    // return (
    //     <div className="mx-auto w-full max-w-xs">
    //       <form className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
    //         <div className="mb-4">
    //           <label
    //             className="block text-grey-darker text-sm font-bold mb-2"
    //             htmlFor="username"
    //           >
    //             Username
    //           </label>
    //           <input
    //             className="shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker leading-tight focus:outline-none focus:shadow-outline"
    //             id="username"
    //             key="username"
    //             name="username"
    //             onChange={this.handleInputChange}
    //             type="text"
    //             placeholder="Username"
    //           />
    //         </div>
    //         <div className="mb-6">
    //           <label
    //             className="block text-grey-darker text-sm font-bold mb-2"
    //             htmlFor="password"
    //           >
    //             Password
    //           </label>
    //           <input
    //             className="shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker mb-3 leading-tight focus:outline-none focus:shadow-outline"
    //             id="password"
    //             key="password"
    //             name="password"
    //             onChange={this.handleInputChange}
    //             type="password"
    //             placeholder="******************"
    //           />
    //           <p className="text-grey-dark text-xs">
    //             Forgot your password?{" "}
    //             <a
    //               className="text-indigo cursor-pointer hover:text-indigo-darker"
    //               onClick={() => super.changeState("forgotPassword")}
    //             >
    //               Reset Password
    //             </a>
    //           </p>
    //         </div>
    //         <div className="flex items-center justify-between">
    //           <button
    //             className="bg-blue hover:bg-blue-dark text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
    //             type="button"
    //             onClick={() => super.signIn()}
    //           >
    //             Login
    //           </button>
    //           <p className="text-grey-dark text-xs">
    //             No Account?{" "}
    //             <a
    //               className="text-indigo cursor-pointer hover:text-indigo-darker"
    //               onClick={() => super.changeState("signUp")}
    //             >
    //               Create account
    //             </a>
    //           </p>
    //         </div>
    //       </form>
    //     </div>
    //   );
  }
}