import React from 'react';
import { insertQuestionSetPage, updateQuestionSetPageHeader, swapQuestionSetPageOrders, deleteQuestionSetPage } from '../../graphql/questionSets';
import { useMutation } from '@apollo/react-hooks';
import { InsertQuestionSetPageVariables, InsertQuestionSetPage } from '../../graphql/types/InsertQuestionSetPage';
import { UpdateQuestionSetPageNameVariables, UpdateQuestionSetPageName } from '../../graphql/types/UpdateQuestionSetPageName';
import { SwapQuestionSetPagesVariables, SwapQuestionSetPages } from '../../graphql/types/SwapQuestionSetPages';
import { DeleteQuestionSetPageVariables, DeleteQuestionSetPage } from '../../graphql/types/DeleteQuestionSetPage';
import { QuestionSetState } from './CreateEditQuestionSet';

export enum QuestionSetPageActionEnum {
    insert, updatePage, swapPages, deletePage
};

type QuestionSetPageActions = InsertQuestionSetPageVariables | UpdateQuestionSetPageNameVariables | SwapQuestionSetPagesVariables | DeleteQuestionSetPageVariables;
export function UseDispatchQuestionSetPageAction(questionSetState: QuestionSetState, errorHandler: (e: any) => void) {
    const [insertPage] = useMutation<InsertQuestionSetPage, InsertQuestionSetPageVariables>(insertQuestionSetPage);
    const [editPageName] = useMutation<UpdateQuestionSetPageName, UpdateQuestionSetPageNameVariables>(updateQuestionSetPageHeader);
    const [swapPages] = useMutation<SwapQuestionSetPages, SwapQuestionSetPagesVariables>(swapQuestionSetPageOrders);
    const [deletePage] = useMutation<DeleteQuestionSetPage, DeleteQuestionSetPageVariables>(deleteQuestionSetPage);

    const action = React.useMemo(() => {
        if (questionSetState === QuestionSetState.New) {
            // new test - don't do intermediate saves
            return () => Promise.resolve();
        }
        else if (questionSetState === QuestionSetState.ExistingWithSubmissions) {
            // test with submissions - don't do intermediate saves
            return () => Promise.resolve();
        }
        else {
            // existing test but has no submissions - do intermediate saves
            return (action: QuestionSetPageActionEnum, params: QuestionSetPageActions) => {
                if (action === QuestionSetPageActionEnum.insert) {
                    const insertVars = params as InsertQuestionSetPageVariables;
                    return insertPage({ variables: insertVars })
                        .then(r => r?.data?.insert_question_pages?.returning[0]?.id || -1)
                        .catch(errorHandler);
                }
                else if (action === QuestionSetPageActionEnum.updatePage) {
                    const editVars = params as UpdateQuestionSetPageNameVariables;
                    return editPageName({ variables: editVars }).catch(errorHandler);
                }
                else if (action === QuestionSetPageActionEnum.swapPages) {
                    const swapVars = params as SwapQuestionSetPagesVariables;
                    return swapPages({ variables: swapVars }).catch(errorHandler);
                }
                else if (action === QuestionSetPageActionEnum.deletePage) {
                    const deleteVars = params as DeleteQuestionSetPageVariables;
                    return deletePage({ variables: deleteVars }).catch(errorHandler);
                }
                else {
                    return Promise.resolve();
                }
            };
        }
    }, [questionSetState]);

    return action;
}
