import gql from "graphql-tag";

export const getQuestionSets = gql`
    query QuestionSets {
        question_sets(where:{is_deleted:{_eq:false}}, order_by: {created_at: asc}){
            id
            name
            version
            category
            frequency
            set_id
            question_set_notification_subscriptions{
                email_address
            }
            question_set_submissions_aggregate{
              aggregate{
                count
              }
            }
        }
    }
`;

export const addQuestionSetSubscription = gql`
    mutation SubscribeToTestNotification($question_set_id:Int!, $email_address:String!) {
        insert_question_set_notification_subscriptions(objects: {question_set_id:$question_set_id, email_address:$email_address}) {
            affected_rows
        }
    }
`;

export const deleteQuestionSetSubscription = gql`
    mutation UnsubscribeToTestNotification($question_set_id: Int!, $email_address: String!) {
        delete_question_set_notification_subscriptions(where: {question_set_id: {_eq: $question_set_id}, email_address: {_eq: $email_address}}) {
            affected_rows
        }
    }
`;

export const getQuestionSetSubmissionHistory = gql`
    query QuestionSetSubmissionHistory($questionSetId: Int!) {
        question_sets_by_pk(id: $questionSetId) {
            id
            name
        }
        question_set_submission(where: {is_deleted: {_eq: false}, question_set_id: {_eq: $questionSetId}}) {
            id
            created_at
            note
            user {
                email
            }
        }
    }
`;

export const getFullyLoadedQuestionSetTemplate = gql`
    query FullyLoadedQuestionSet($id: Int!) {
    question_sets_by_pk(id: $id) {
        set_id
        id
        name
        version
        category
        frequency
        question_set_status
        question_pages(where: {is_deleted: {_eq: false}}, order_by:{page_number:asc}) {
            id
            page_number
            header
            question_groups(where: {is_deleted: {_eq: false}}, order_by:{order:asc}) {
                    id
                    header
                    order
                    group_type {
                        id
                    }
                    question_freeforms(where: {is_deleted: {_eq: false}}, order_by:{order:asc}) {
                        order
                        text
                        value_type
                        id
                        default_value
                    }
                    question_multiselects(where: {is_deleted: {_eq: false}}, order_by:{order:asc}) {
                    order
                    text
                    question_answer_set {
                        name
                        id
                        question_answer_options {
                            header
                            order
                            value
                            id
                        }
                    }
                    id
                }
            }
        }
        question_set_notification_subscriptions{
            email_address
        }
        question_set_submissions_aggregate{
            aggregate{
            count
            }
        }
    }
}
`;

export const insertQuestionSet = gql`
    mutation InsertQuestionSet($qs: [question_sets_insert_input!]!) {
        insert_question_sets(objects: $qs) {
            returning {
                id
            }
        }
    }
`;

export const getAllCategoryTypes = gql`
    query GetCategories {
        question_sets(distinct_on: category) {
            category
        }
    }
`;

export const updateQuestionSet = gql`
    mutation UpdateQuestionSet($id: Int!, $category: String, $name: String, $frequency: types_frequency_enum!, $status: question_set_status_enum! ) {
        update_question_sets(where: {id: {_eq: $id}}, _set: {category: $category, name: $name, frequency: $frequency, question_set_status: $status }) {
            affected_rows
        }
    }
`;


// Page manipulations
export const insertQuestionSetPage = gql`
mutation InsertQuestionSetPage($questionSetId: Int!, $name: String!, $pageNumber: Int! ) {
  insert_question_pages(objects: {question_set_id: $questionSetId, page_number: $pageNumber, header: $name}) {
    returning {
        id
    }
  }
}
`;

export const updateQuestionSetPageHeader = gql`
mutation UpdateQuestionSetPageName($pageId: Int!, $name: String!) {
  update_question_pages(where: {id: {_eq: $pageId}}, _set: {header: $name}) {
    affected_rows
  }
}
`;

export const swapQuestionSetPageOrders = gql`
mutation SwapQuestionSetPages($pageOneId: Int!, $pageOneOrder: Int!, $pageTwoId: Int!, $pageTwoOrder: Int!) {
  pageOne: update_question_pages(where: {id: {_eq: $pageOneId}}, _set: {page_number: $pageOneOrder}) {
    affected_rows
  }
  pageTwo: update_question_pages(where: {id: {_eq: $pageTwoId}}, _set: {page_number: $pageTwoOrder}) {
    affected_rows
  }
}
`;

export const deleteQuestionSetPage = gql`
mutation DeleteQuestionSetPage($pageId: Int!) {
    delete_question_multiselects(where: {question_group: {question_page_id: {_eq: $pageId}}}) {affected_rows}
    delete_question_freeforms(where: {question_group: {question_page_id: {_eq: $pageId}}}) { affected_rows }
    delete_question_groups(where: {question_page_id: {_eq: $pageId}}) { affected_rows }
    delete_question_pages(where: {id: {_eq: $pageId}}) { affected_rows }  
}
`;
//

// Group manipulations
export const insertQuestionGroup = gql`
    mutation InsertQuestionGroup($pageId: Int!, $header: String!, $groupType: group_types_enum!, $order: Int!) {
        insert_question_groups(objects: {question_page_id: $pageId, header: $header, group_type_id: $groupType, order: $order}){returning {id}}
    }
`;

export const updateQuestionGroup = gql`
    mutation UpdateQuestionGroup($id: Int!, $header: String!, $groupType: group_types_enum!) {
        update_question_groups(where: {id: {_eq: $id}}, _set: {header: $header, group_type_id: $groupType}) {affected_rows}
    }
`;

export const swapQuestionGroups = gql`
    mutation SwapQuestionGroups($idOne: Int!, $idTwo: Int!, $orderOne: Int!, $orderTwo: Int!) {
        pageOne: update_question_groups(where: {id: {_eq: $idOne}}, _set: {order: $orderOne}) {affected_rows}
        pageTwo: update_question_groups(where: {id: {_eq: $idTwo}}, _set: {order: $orderTwo}) {affected_rows}
    }
`;

export const deleteQuestionGroup = gql`
mutation DeleteQuestionGroup($id: Int!) {
    delete_question_multiselects(where: {question_group_id: {_eq: $id}}) {affected_rows}
    delete_question_freeforms(where: {question_group_id: {_eq: $id}}) { affected_rows }
    delete_question_groups(where: {id: {_eq: $id}}) { affected_rows }
}
`;
//

// Freeform question manipulations
export const insertFreeformQuestion = gql`
mutation InsertFreeformQuestion($groupId: Int!, $text: String!, $order: Int!, $type: value_types_enum) {
  insert_question_freeforms(objects: {question_group_id: $groupId, text: $text, order: $order, value_type: $type}) {
    returning {id}
  }
}
`;

export const updateFreeformQuestion = gql`
mutation UpdateFreeformQuestion($id: Int!, $text: String!, $type: value_types_enum!, $default_value: String) {
    update_question_freeforms(where: {id: {_eq: $id}}, _set: {text: $text, value_type: $type, default_value: $default_value}) {affected_rows}
}
`;

export const swapFreeformQuestions = gql`
mutation SwapFreeformQuestions($idOne: Int!, $orderOne: Int!, $idTwo: Int!, $orderTwo: Int!) {
    questionOne: update_question_freeforms(where: {id: {_eq: $idOne}}, _set: {order: $orderOne}) {affected_rows}
    questionTwo: update_question_freeforms(where: {id: {_eq: $idTwo}}, _set: {order: $orderTwo}) {affected_rows}
}
`;

export const deleteFreeformQuestion = gql`
mutation DeleteFreeformQuestion($id: Int!) {
    delete_question_freeforms(where: {id: {_eq: $id}}) { affected_rows }
}
`;
//

// Multiselect question manipulations
export const insertMultiselectQuestion = gql`
mutation InsertMultiselectQuestion($groupId: Int!, $text: String!, $order: Int!, $answerSetName: String! ) {
    insert_question_multiselects(objects: {question_group_id: $groupId, order: $order, text: $text, question_answer_set: {data: {name: $answerSetName}}}){
        returning {
            id
            question_answer_set_id
        }
    }
}
`;

export const updateMultiselectQuestion = gql`
mutation UpdateMultiselectQuestion($id: Int!, $text: String!) {
    update_question_multiselects(where: {id: {_eq: $id}}, _set: {text: $text}){affected_rows}
}
`;


export const swapMultiselectQuestions = gql`
    mutation SwapMultiselectQuestions($idOne: Int!, $idTwo: Int!, $orderOne: Int!, $orderTwo: Int!) {
        pageOne: update_question_multiselects(where: {id: {_eq: $idOne}}, _set: {order: $orderOne}) {affected_rows}
        pageTwo: update_question_multiselects(where: {id: {_eq: $idTwo}}, _set: {order: $orderTwo}) {affected_rows}
    }
`;

export const deleteMultiselectQuestion = gql`
mutation DeleteMultiselectQuestion($id: Int!) {
    delete_question_multiselects(where: {id: {_eq: $id}}) {affected_rows}
}
`;
//

// Multiselect answer options
export const insertAnswerOption = gql`
mutation InsertAnswerOption($setId: Int!, $order: Int!, $header: String!, $value: String!) {
    insert_question_answer_options(objects: {question_answer_set_id: $setId, order: $order, header: $header, value: $value}){returning{id}}
}
`;

export const updateAnswerOption = gql`
mutation UpdateAnswerOption($id: Int!, $header: String!, $value: String!) {
    update_question_answer_options(where: {id: {_eq: $id}}, _set: {header: $header, value: $value}){affected_rows}
}
`;


export const swapAnswerOptions = gql`
    mutation SwapAnswerOptions($idOne: Int!, $idTwo: Int!, $orderOne: Int!, $orderTwo: Int!) {
        pageOne: update_question_answer_options(where: {id: {_eq: $idOne}}, _set: {order: $orderOne}) {affected_rows}
        pageTwo: update_question_answer_options(where: {id: {_eq: $idTwo}}, _set: {order: $orderTwo}) {affected_rows}
    }
`;

export const deleteAnswerOption = gql`
mutation DeleteAnswerOption($id: Int!) {
    delete_question_answer_options(where: {id: {_eq: $id}}) {affected_rows}
}
`;
//

// Question set submissions
export const getAllQuestionSubmissions = gql`
query QuestionSetSubmissions {
    question_set_submission(where: {is_deleted:{_eq:false}}) {
      id
      question_set_id
    }
  }
`;

export const getQuestionSetSubmission = gql`
query QuestionSetSubmission($submissionId: Int!) {
    question_set_submission(where: {id: {_eq: $submissionId}}, limit: 1) {
        created_at
        note
        user {
            email
        }
        question_set {
            name
            version
            organization {
                display_name
                logo_url
            }
            question_pages(order_by: {page_number: asc}, where: {is_deleted: {_eq: false}}) {
                header
                page_number
                question_groups(where: {is_deleted: {_eq: false}}, order_by: {order: asc}) {
                    header
                    group_type {
                        id
                    }
                    question_freeforms(order_by: {order: asc}, where: {is_deleted: {_eq: false}}) {
                        text
                        value_type
                        order
                        question_freeform_answers(where: {question_set_submission: {id: {_eq: $submissionId}}}) {
                            value
                        }
                    }
                    question_multiselects(order_by: {order: asc}, where: {is_deleted: {_eq: false}}) {
                        text
                        order
                        question_answer_set {
                            name
                            question_answer_options {
                                header
                                order
                                value
                            }
                        }
                        question_multiselect_answers(where: {question_set_submission: {id: {_eq: $submissionId}}}) {
                            value: question_answer_option_value
                        }
                    }
                }
            }
        }
    }
  }
  
`;

//