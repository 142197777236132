import React, { Component } from 'react';
import PropTypes from 'prop-types';

const AppBreadcrumb = ({ location }) => {
    const propTypes = {
        match: PropTypes.object
    }
    const paths = location.pathname.split('/');

    return (
        <div className="layout-breadcrumb">
            <ul>
                <li><button className="p-link"><i className="pi pi-home"></i></button></li>
                {
                    location.pathname === '/' ?  <li>/</li> : paths.map((path, index) => <li key={index}>{path === '' ? '/' : path}</li>)
                }
            </ul>
        </div>
    );
}

export default AppBreadcrumb;
