import React, {useRef} from 'react';
import { useQuery } from '@apollo/react-hooks';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import { Button } from 'primereact/button';

import {ProgressSpinner} from 'primereact/progressspinner';
import { getQuestionSets } from '../../graphql/questionSets';
import {QuestionSets, QuestionSets_question_sets} from '../../graphql/types/QuestionSets';

import { useHistory } from 'react-router-dom';
import { defaultPollingInterval } from '../../graphql/config';

interface TableRow extends QuestionSets_question_sets {
    isEditable: boolean
}

export default function QuestionSetsDisplay() {
    const { data, error, loading } = useQuery<QuestionSets>(getQuestionSets, {pollInterval: defaultPollingInterval, fetchPolicy: "no-cache"});
    const [selectedRow, setSelectedRow] = React.useState<TableRow>();
    const [selectedData, setSelectedData] = React.useState<TableRow[]>(Array<TableRow>())
    const history = useHistory();
    let dt = useRef<any>(null);

    React.useEffect(() => {
        if (data?.question_sets) {
            const allData = data?.question_sets;
            const rows = allData.map(r =>{
                // the form is editable if there are no other tests with the same set id with a larger version number
                const versionInt = parseInt(r.version) || 0;
                const otherVersions = allData.filter(x => x.set_id === r.set_id).map(x => parseInt(x.version) || -1);
                return {
                    ...r,
                    isEditable: otherVersions.filter(x => x > versionInt).length === 0
                }
            });
            setSelectedData(rows);
        }
    }, [data]);

    const onExportCSV = () => {
        
        dt.current.exportCSV();
    };


    const actionsTemplate = (rowData: TableRow) => (
        <div>
            {rowData.isEditable && (<Button
                type="button"
                icon="pi pi-pencil"
                className="p-button-warning"
                onClick={(e) => {
                    e.preventDefault();
                    history.push(`/sets/edit/${rowData.id}`);
                }} />
            )}
            <Button
                type="button"
                icon="pi pi-search"
                className="p-button-primary"
                onClick={(e) => {
                    e.preventDefault();
                    history.push(`/submissionhistory/${rowData.id}`);
                }} />
        </div>
    )

    if (error) {
        console.error(error);
    }
    

    const header = <div style={{textAlign:'left'}}><Button type="button" icon="pi pi-external-link" iconPos="left" label="CSV" onClick={onExportCSV}></Button></div>;

    if (loading) {
        return <ProgressSpinner />
    } else {
        return (        
        <div className="p-grid">
            <div className="p-col-12">
                <div className="card card-w-title">
                    <h1>Data Sets</h1>
                    <DataTable 
                        ref={dt} 
                        value={selectedData} 
                        selectionMode="single"
                        header={header}
                        paginator={true} 
                        rows={10}
                        responsive={true} 
                        selection={selectedRow} 
                        onRowClick={(e) => {
                            // if (!e.originalEvent.defaultPrevented) {
                            //     history.push(`/submissionhistory/${e.data.id}`);
                            // }
                        }}>
                        <Column body={actionsTemplate} style={{ textAlign: 'center', width: '10em' }} />
                        <Column field="name" header="Name" sortable={true} filter={false}/>
                        <Column field="version" header="Version" sortable={true} filter={false} />
                        <Column field="category" header="Category" sortable={true} filter={false} />
                        <Column field="frequency" header="Frequency" sortable={true} filter={false} />
                        <Column field="set_id" header="Form Id" sortable={true} filter={true} />
                    </DataTable>
                </div>
            </div>
        </div>);
    }
}