import React, { Component } from 'react';
import classNames from 'classnames';
//import AppTopbar from './AppTopbar';
import { AppTopbar } from './AppTopbar';
import AppBreadcrumb from './AppBreadcrumb';
import AppFooter from './AppFooter';
import { AppMenu } from './AppMenu';
import AppConfig from './AppConfig';
import { withRouter } from 'react-router';
import { Route } from 'react-router-dom';
import Dashboard from './components/Dashboard';
import { Growl } from 'primereact/growl';
import 'primereact/resources/primereact.min.css';
import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';
import '@fullcalendar/timegrid/main.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import './App.css';

import QuestionSetsDisplay from './components/Data-Sets/QuestionSets';
import QuestionSetSubmissionHistory from './components/Data-Sets/QuestionSetSubmissionHistory';
import { CreateQuestionSet, EditQuestionSet, CopyQuestionSet } from './components/Data-Sets/CreateEditQuestionSet';
import CreateUser from './components/User-Management/CreateUser'
import EditUser from './components/User-Management/EditUser'

//Amplify garbage
import Amplify, { Auth } from 'aws-amplify';
//import { withAuthenticator, ConfirmSignIn, VerifyContact, SignUp } from 'aws-amplify-react'
import awsconfig from './aws-exports';
import '@aws-amplify/ui/dist/style.css';

//apollo stuff
import { ApolloProvider } from "@apollo/react-hooks";
import ApolloClient from "apollo-client";
import { HttpLink } from 'apollo-link-http';
import { WebSocketLink } from 'apollo-link-ws';
import { split } from 'apollo-link';
import { getMainDefinition } from 'apollo-utilities';
import { SubscriptionClient } from 'subscriptions-transport-ws';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { onError } from "apollo-link-error";

// Contexts
import GrowlContext from './service/GrowlContext';
import UserContext, { isUserAnAdmin } from './service/UserContext';

// Custom auth
import { ConfirmSignIn, ConfirmSignUp, ForgotPassword, RequireNewPassword, SignUp, VerifyContact, withAuthenticator } from 'aws-amplify-react';
import { CustomSignIn } from './components/Account/CustomSignIn';

Amplify.configure(awsconfig);
//
class App extends Component {
    constructor() {
        super();
        this.state = {
            layoutMode: 'horizontal',
            overlayMenuActive: false,
            staticMenuDesktopInactive: false,
            staticMenuMobileActive: false,
            topbarMenuActive: false,
            activeTopbarItem: null,
            darkTheme: false,
            menuActive: false,
            themeColor: 'blue',
            configDialogActive: false,
            userInfo: null,
            client: null
        };

        this.growl = null;

        this.onDocumentClick = this.onDocumentClick.bind(this);
        this.onMenuClick = this.onMenuClick.bind(this);
        this.onMenuButtonClick = this.onMenuButtonClick.bind(this);
        this.onTopbarMenuButtonClick = this.onTopbarMenuButtonClick.bind(this);
        this.onThemeChange = this.onThemeChange.bind(this);
        this.onTopbarItemClick = this.onTopbarItemClick.bind(this);
        this.onMenuItemClick = this.onMenuItemClick.bind(this);
        this.onRootMenuItemClick = this.onRootMenuItemClick.bind(this);
        this.changeMenuMode = this.changeMenuMode.bind(this);
        this.changeMenuColor = this.changeMenuColor.bind(this);
        this.changeTheme = this.changeTheme.bind(this);
        this.onConfigButtonClick = this.onConfigButtonClick.bind(this);
        this.onConfigCloseClick = this.onConfigCloseClick.bind(this);
        this.onConfigClick = this.onConfigClick.bind(this);
        this.onLogoutButtonClick = this.onLogoutButtonClick.bind(this);
    }

    componentDidUpdate() {
        console.log('component did update')
    }

    async getUserData() {
        const authUser = await Auth.currentAuthenticatedUser();

        this.setState(({
            userInfo: authUser
        }));

        if (authUser) {
            this.createGqlClient(authUser);
        }

    }

    componentDidMount() {
        this.getUserData();
    }

    componentDidCatch() {
        console.log('component caught');
    }

    createGqlClient(cognitoUser) {
        const token = cognitoUser?.signInUserSession?.idToken?.jwtToken;
        console.log('token', token);
        const subscriptionClient = new SubscriptionClient(process.env.REACT_APP_GRAPHQL_WS_ENDPOINT,
            {
                reconnect: true,
                connectionParams: {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                },
                onError: ({ graphQLErrors, networkError }) => {
                    if (graphQLErrors) {
                        //This is where we would log to a logging service.
                        graphQLErrors.forEach(({ message, locations, path }) =>
                            console.log(
                                `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
                            ),
                        );
                    }
                    if (networkError) {
                        //This is where we should let the user know that they have no network activity
                        console.log(`[Network error]: ${networkError}`);
                    }
                }
            }
        );

        const errorLink = onError(({ graphQLErrors, networkError }) => {
            if (graphQLErrors) {
                for (let err of graphQLErrors) {
                    switch (err.extensions.code) {
                        case "UNAUTHENTICATED":
                            Auth.signOut();
                    }
                }
            }
            if (networkError) console.log(`[Network error]: ${networkError}`);
        });

        const wslink = new WebSocketLink(subscriptionClient, {});
        const httpLink = new HttpLink({
            uri: process.env.REACT_APP_GRAPHQL_ENDPOINT,
            headers: {
                Authorization: `Bearer ${token}`,
            }
        });
        
        const httpFullyLink = errorLink.concat(httpLink);

        const link = split(({ query }) => {
            const { kind, operation } = getMainDefinition(query);
            return kind === 'OperationDefinition' && operation === 'subscription';
        }, wslink, httpFullyLink);

        const apClient = new ApolloClient({
            link: link,
            cache: new InMemoryCache(),
            onError: () => {
                console.log('an error occurred');
            }
        })

        this.setState({
            client: apClient
        })
    }

    onMenuClick(event) {
        this.menuClick = true;
    }

    onMenuButtonClick(event) {
        this.menuClick = true;
        this.setState(({
            topbarMenuActive: false
        }));

        if (this.state.layoutMode === 'overlay' && !this.isMobile()) {
            this.setState({
                overlayMenuActive: !this.state.overlayMenuActive
            });
        } else {
            if (this.isDesktop())
                this.setState({ staticMenuDesktopInactive: !this.state.staticMenuDesktopInactive });
            else
                this.setState({ staticMenuMobileActive: !this.state.staticMenuMobileActive });
        }

        event.preventDefault();
    }

    onTopbarMenuButtonClick(event) {
        this.topbarItemClick = true;
        this.setState({ topbarMenuActive: !this.state.topbarMenuActive });
        this.hideOverlayMenu();
        event.preventDefault();
    }

    onTopbarItemClick(event) {
        this.topbarItemClick = true;

        if (this.state.activeTopbarItem === event.item)
            this.setState({ activeTopbarItem: null });
        else
            this.setState({ activeTopbarItem: event.item });

        event.originalEvent.preventDefault();
    }

    onMenuItemClick(event) {
        if (!event.item.items) {
            this.hideOverlayMenu();
        }
        if (!event.item.items && (this.isHorizontal() || this.isSlim())) {
            this.setState({
                menuActive: false
            })
        }
    }

    onRootMenuItemClick(event) {
        this.setState({
            menuActive: !this.state.menuActive
        });
    }

    onConfigButtonClick(event) {
        this.configClick = true;
        this.setState({ configDialogActive: !this.state.configDialogActive })
    }

    onConfigCloseClick() {
        this.setState({ configDialogActive: false })
    }

    onConfigClick() {
        this.configClick = true;
    }

    onDocumentClick(event) {
        if (!this.topbarItemClick) {
            this.setState({
                activeTopbarItem: null,
                topbarMenuActive: false
            });
        }

        if (!this.configClick) {
            this.setState({ configDialogActive: false });
        }

        if (!this.menuClick) {
            if (this.isHorizontal() || this.isSlim()) {
                this.setState({
                    menuActive: false
                })
            }

            this.hideOverlayMenu();
        }

        this.topbarItemClick = false;
        this.menuClick = false;
        this.configClick = false;
    }

    hideOverlayMenu() {
        this.setState({
            overlayMenuActive: false,
            staticMenuMobileActive: false
        })
    }

    isTablet() {
        let width = window.innerWidth;
        return width <= 1024 && width > 640;
    }

    isDesktop() {
        return window.innerWidth > 1024;
    }

    isMobile() {
        return window.innerWidth <= 640;
    }

    isOverlay() {
        return this.state.layoutMode === 'overlay';
    }

    isHorizontal() {
        return this.state.layoutMode === 'horizontal';
    }

    isSlim() {
        return this.state.layoutMode === 'slim';
    }

    changeMenuMode(event) {
        this.setState({
            layoutMode: event.menuMode,
            staticMenuDesktopInactive: false,
            overlayMenuActive: false
        });
    }

    changeMenuColor(event) {
        this.setState({ darkTheme: event.darkTheme })
        this.onThemeChange();
    }

    onThemeChange() {
        const themeLink = document.getElementById('theme-css');
        const href = themeLink.href;
        const themeFile = href.substring(href.lastIndexOf('/') + 1, href.lastIndexOf('.'));
        const themeTokens = themeFile.split('-');
        const themeName = themeTokens[1];
        const themeMode = themeTokens[2];
        const newThemeMode = (themeMode === 'dark') ? 'light' : 'dark';

        this.changeTheme({ originalEvent: null, theme: themeName + '-' + newThemeMode });
        console.log(newThemeMode);
    }

    changeTheme(event) {
        this.setState({ themeColor: event.theme.split('-')[0] })
        this.changeStyleSheetUrl('layout-css', event.theme, 'layout');
        this.changeStyleSheetUrl('theme-css', event.theme, 'theme');
    }

    onLogoutButtonClick() {
        console.log('logging out')
        Auth.signOut();
        window.location.reload(false);
    }

    changeStyleSheetUrl(id, value, prefix) {
        let element = document.getElementById(id);
        let urlTokens = element.getAttribute('href').split('/');
        urlTokens[urlTokens.length - 1] = prefix + '-' + value + '.css';
        let newURL = urlTokens.join('/');

        this.replaceLink(element, newURL);

        if (value.indexOf('dark') !== -1) {
            this.setState({ darkTheme: true });
        } else {
            this.setState({ darkTheme: false });
        }
    }

    isIE() {
        return /(MSIE|Trident\/|Edge\/)/i.test(window.navigator.userAgent)
    }

    replaceLink(linkElement, href) {
        const id = linkElement.getAttribute('id');
        const cloneLinkElement = linkElement.cloneNode(true);

        if (this.isIE()) {
            linkElement.setAttribute('href', href);
        }
        else {
            cloneLinkElement.setAttribute('href', href);
            cloneLinkElement.setAttribute('id', id + '-clone');

            linkElement.parentNode.insertBefore(cloneLinkElement, linkElement.nextSibling);

            cloneLinkElement.addEventListener('load', () => {
                linkElement.remove();
                cloneLinkElement.setAttribute('id', id);
            });
        }
    }

    getMenu() {
        let menu = [
            { label: 'Dashboard', icon: 'pi pi-fw pi-home', to: '/' },
        ];

        // Only show question set pages if an admin
        if (isUserAnAdmin(this.state.userInfo)) {
            menu = [
                ...menu,
                {
                    label: 'Data sets',
                    items: [
                        { label: 'View Sets', icon: 'pi pi-fw pi-table', to: '/sets' },
                        { label: 'Create Set', icon: 'pi pi-fw pi-plus', to: '/sets/create' }
                    ]
                },
            ];
        }

        return menu;
    }

    render() {
        const layoutClassName = classNames('layout-wrapper', {
            'layout-horizontal': this.state.layoutMode === 'horizontal',
            'layout-overlay': this.state.layoutMode === 'overlay',
            'layout-static': this.state.layoutMode === 'static',
            'layout-slim': this.state.layoutMode === 'slim',
            'layout-static-inactive': this.state.staticMenuDesktopInactive,
            'layout-mobile-active': this.state.staticMenuMobileActive,
            'layout-overlay-active': this.state.overlayMenuActive
        });

        let menu = this.getMenu();

        const AppBreadCrumbWithRouter = withRouter(AppBreadcrumb);
        if (this.state.userInfo && this.state.client) {
            return (
                <div className={layoutClassName} >
                    <UserContext.Provider value={this.state.userInfo}>
                        <ApolloProvider client={this.state.client}>
                            <AppTopbar
                                darkTheme={this.state.darkTheme}
                                onThemeChange={this.onThemeChange}
                                topbarMenuActive={this.state.topbarMenuActive}
                                activeTopbarItem={this.state.activeTopbarItem}
                                onMenuButtonClick={this.onMenuButtonClick}
                                onTopbarMenuButtonClick={this.onTopbarMenuButtonClick}
                                onTopbarItemClick={this.onTopbarItemClick}
                                onLogoutButtonClick={this.onLogoutButtonClick}
                                userInfo={this.state.userInfo}
                            />

                            <div className='layout-menu-container' onClick={this.onMenuClick}>
                                <div className="layout-menu-content">
                                    <div className="layout-menu-title">MENU</div>
                                    <AppMenu model={menu} onMenuItemClick={this.onMenuItemClick}
                                        onRootMenuItemClick={this.onRootMenuItemClick}
                                        layoutMode={this.state.layoutMode} active={this.state.menuActive} />
                                </div>
                            </div>

                            <div className="layout-content">
                                <AppBreadCrumbWithRouter />

                                <Growl ref={(el) => (this.growl = el)} />

                                <GrowlContext.Provider value={this.growl}>

                                    <div className="layout-content-container">
                                        <Route path="/" exact component={(a) => <Dashboard {...a} growl={this.growl} />} />

                                        {/* question sets - only allow if admin */}
                                        {isUserAnAdmin(this.state.userInfo) && (
                                            <>
                                                <Route path="/sets" component={QuestionSetsDisplay} exact={true} />
                                                <Route path="/submissionhistory/:id" component={QuestionSetSubmissionHistory} />
                                                <Route path="/sets/edit/:id" key="edit-set" component={EditQuestionSet} />
                                                <Route path="/sets/create" key="create-set" component={CreateQuestionSet} />
                                                <Route path="/sets/copy/:id" key="copy-set" component={CopyQuestionSet} />
                                            </>
                                        )}

                                        { /* User Management - only allow if admin */}
                                        {isUserAnAdmin(this.state.userInfo) && (
                                            <>
                                                <Route path="/user/create" component={CreateUser} />
                                                <Route path="/user/edit/:email" component={EditUser} />
                                            </>
                                        )}
                                    </div>

                                    <AppFooter />
                                </GrowlContext.Provider>

                                {this.state.staticMenuMobileActive && <div className="layout-mask"></div>}
                            </div>

                            <AppConfig layoutMode={this.state.layoutMode} darkTheme={this.state.darkTheme} themeColor={this.state.themeColor}
                                changeMenuMode={this.changeMenuMode} changeMenuColor={this.changeMenuColor} changeTheme={this.changeTheme}
                                onConfigButtonClick={this.onConfigButtonClick} onConfigCloseClick={this.onConfigCloseClick}
                                onConfigClick={this.onConfigClick} configDialogActive={this.state.configDialogActive} />
                        </ApolloProvider>
                    </UserContext.Provider>
                </div>
            );
        } else {
            console.log("Not authenticated");
            return (<p></p>)
        }

    }
}

export default withAuthenticator(App, false, [
    <CustomSignIn />,
    <ConfirmSignIn />,
    <VerifyContact />,
    <SignUp />,
    <ConfirmSignUp />,
    <ForgotPassword />,
    <RequireNewPassword />
]);