import React from 'react';
import { insertFreeformQuestion, updateFreeformQuestion, swapFreeformQuestions, deleteFreeformQuestion } from '../../graphql/questionSets';
import { useMutation } from '@apollo/react-hooks';

import { QuestionSetState } from './CreateEditQuestionSet';
import { InsertFreeformQuestionVariables, InsertFreeformQuestion } from '../../graphql/types/InsertFreeformQuestion';
import { UpdateFreeformQuestionVariables, UpdateFreeformQuestion } from '../../graphql/types/UpdateFreeformQuestion';
import { SwapFreeformQuestionsVariables, SwapFreeformQuestions } from '../../graphql/types/SwapFreeformQuestions';
import { DeleteFreeformQuestionVariables, DeleteFreeformQuestion } from '../../graphql/types/DeleteFreeformQuestion';

export enum FreeformQuestionActionEnum {
    insert, update, swap, delete
};

export type FreeformQuestionActions = InsertFreeformQuestionVariables | UpdateFreeformQuestionVariables | SwapFreeformQuestionsVariables | DeleteFreeformQuestionVariables;
export function UseDispatchFreeformQuestionActions(questionSetState: QuestionSetState, errorHandler: (e: any) => void) {
    const [insert] = useMutation<InsertFreeformQuestion, InsertFreeformQuestionVariables>(insertFreeformQuestion);
    const [updateQuestion] = useMutation<UpdateFreeformQuestion, UpdateFreeformQuestionVariables>(updateFreeformQuestion);
    const [swap] = useMutation<SwapFreeformQuestions, SwapFreeformQuestionsVariables>(swapFreeformQuestions);
    const [deleteItem] = useMutation<DeleteFreeformQuestion, DeleteFreeformQuestionVariables>(deleteFreeformQuestion);

    const action = React.useMemo(() => {
        if (questionSetState === QuestionSetState.New) {
            // new test - don't do intermediate saves
            return () => Promise.resolve();
        }
        else if (questionSetState === QuestionSetState.ExistingWithSubmissions) {
            // test with submissions - don't do intermediate saves
            return () => Promise.resolve();
        } else {
            // existing test but has no submissions - do intermediate saves
            return (action: FreeformQuestionActionEnum, params: FreeformQuestionActions) => {
                if (action === FreeformQuestionActionEnum.insert) {
                    const insertVars = params as InsertFreeformQuestionVariables;
                    return insert({ variables: insertVars })
                        .then(r => r?.data?.insert_question_freeforms?.returning[0]?.id || -1)
                        .catch(errorHandler);
                }
                else if (action === FreeformQuestionActionEnum.update) {
                    const editVars = params as UpdateFreeformQuestionVariables;
                    return updateQuestion({ variables: editVars }).catch(errorHandler);
                }
                else if (action === FreeformQuestionActionEnum.swap) {
                    const swapVars = params as SwapFreeformQuestionsVariables;
                    return swap({ variables: swapVars }).catch(errorHandler);
                }
                else if (action === FreeformQuestionActionEnum.delete) {
                    const deleteVars = params as DeleteFreeformQuestionVariables;
                    return deleteItem({ variables: deleteVars }).catch(errorHandler);
                }
                else {
                    return Promise.resolve();
                }
            };
        }
    }, [questionSetState]);

    return action;
}