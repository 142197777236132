export function validateEmail(email: string) {
    if (!email || email.length > 256) return false;
  
    const reg = /^[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;
    if (!reg.test(email)) return false;
  
    // Further checking of some things regex can't handle
    var emailParts = email.split('@');
    var account = emailParts[0];
    var address = emailParts[1];
    if (account.length > 64) return false;
  
    var domainParts = address.split('.');
    if (domainParts.some(function (part) {
      return part.length > 63;
    })) return false;
  
    return true;
}