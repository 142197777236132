import React, { Component } from 'react';

export default function AppFooter() {
    return (
        <div className="layout-footer">
            <div className="clearfix">
                <span className="footer-text-left">Mach1 Forms, a <a target="_blank" href='https://gcsworks.com'>Gulf Coast Solutions company</a></span>
                <span className="footer-text-right">All Rights Reserved</span>
            </div>
        </div>
    )
}