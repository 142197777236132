import React from 'react';
import { insertMultiselectQuestion, updateMultiselectQuestion, swapMultiselectQuestions, deleteMultiselectQuestion } from '../../graphql/questionSets';
import { useMutation } from '@apollo/react-hooks';

import { QuestionSetState } from './CreateEditQuestionSet';
import { InsertMultiselectQuestionVariables, InsertMultiselectQuestion } from '../../graphql/types/InsertMultiselectQuestion';
import { UpdateMultiselectQuestionVariables, UpdateMultiselectQuestion } from '../../graphql/types/UpdateMultiselectQuestion';
import { SwapMultiselectQuestionsVariables, SwapMultiselectQuestions } from '../../graphql/types/SwapMultiselectQuestions';
import { DeleteMultiselectQuestionVariables, DeleteMultiselectQuestion } from '../../graphql/types/DeleteMultiselectQuestion';

export enum MultiselectQuestionActionEnum {
    insert, update, swap, delete
};

type QuestionGroupActions = InsertMultiselectQuestionVariables | UpdateMultiselectQuestionVariables | SwapMultiselectQuestionsVariables | DeleteMultiselectQuestionVariables;
export function UseDispatchMultiselectQuestionActions(questionSetState: QuestionSetState, errorHandler: (e: any) => void) {
    const [insert] = useMutation<InsertMultiselectQuestion, InsertMultiselectQuestionVariables>(insertMultiselectQuestion);
    const [updateText] = useMutation<UpdateMultiselectQuestion, UpdateMultiselectQuestionVariables>(updateMultiselectQuestion);
    const [swap] = useMutation<SwapMultiselectQuestions, SwapMultiselectQuestionsVariables>(swapMultiselectQuestions);
    const [deleteItem] = useMutation<DeleteMultiselectQuestion, DeleteMultiselectQuestionVariables>(deleteMultiselectQuestion);

    const action = React.useMemo(() => {
        if (questionSetState === QuestionSetState.New) {
            // new test - don't do intermediate saves
            return () => Promise.resolve();
        }
        else if (questionSetState === QuestionSetState.ExistingWithSubmissions) {
            // test with submissions - don't do intermediate saves
            return () => Promise.resolve();
        } else {
            // existing test but has no submissions - do intermediate saves
            return (action: MultiselectQuestionActionEnum, params: QuestionGroupActions) => {
                if (action === MultiselectQuestionActionEnum.insert) {
                    const insertVars = params as InsertMultiselectQuestionVariables;
                    return insert({ variables: insertVars })
                        .then(r => ({
                            id: r.data?.insert_question_multiselects?.returning[0]?.id || -1,
                            questionAnswerSetId: r.data?.insert_question_multiselects?.returning[0]?.question_answer_set_id || -1
                        }))
                        .catch(errorHandler);
                }
                else if (action === MultiselectQuestionActionEnum.update) {
                    const editVars = params as UpdateMultiselectQuestionVariables;
                    return updateText({ variables: editVars }).catch(errorHandler);
                }
                else if (action === MultiselectQuestionActionEnum.swap) {
                    const swapVars = params as SwapMultiselectQuestionsVariables;
                    return swap({ variables: swapVars }).catch(errorHandler);
                }
                else if (action === MultiselectQuestionActionEnum.delete) {
                    const deleteVars = params as DeleteMultiselectQuestionVariables;
                    return deleteItem({ variables: deleteVars }).catch(errorHandler);
                }
                else {
                    return Promise.resolve();
                }
            };
        }
    }, [questionSetState]);

    return action;
}