import React from 'react';
import GrowlContext from './GrowlContext';
export function GetDefaultErrorHandler(attemptedAction: string) {
    const growl = React.useContext(GrowlContext);
    const action = React.useMemo(() => {
        return (e: any) => {
            console.error(e);
            growl?.show({ severity: 'error', summary: attemptedAction, detail: `${e}` });
        };
    }, [growl]);
    return action;
}
