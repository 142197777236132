import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {InputText} from 'primereact/inputtext';
import {InputSwitch} from 'primereact/inputswitch';
import {Dialog} from 'primereact/dialog'
import {Button} from 'primereact/button'

/*
const AppTopbar = ( {topbarMenuActive, onMenuButtonClick, onTopbarMenuButtonClick, darkTheme, onThemeChange} ) => {
    let topbarItemsClassName = classNames('topbar-menu fadeInDown', {'topbar-menu-visible': topbarMenuActive});

    function onTopbarItemClick(event, item) {
        if(this.props.onTopbarItemClick) {
            this.props.onTopbarItemClick({
                originalEvent: event,
                item: item
            });
        }
    }

    return (
        <div className="topbar clearfix">

            <button className="p-link" id="menu-button" onClick={onMenuButtonClick}>
                <i className="pi pi-bars"></i>
            </button>

            <img className="logo" alt="apollo-layout" src="assets/layout/images/apollo_logo.png" />

            <button className="p-link profile" onClick={onTopbarMenuButtonClick}>
                <span className="username">Hudson is King</span>
                <img src="assets/layout/images/avatar/avatar.png" alt="apollo-layout" />
                <i className="pi pi-angle-down"></i>
            </button>

            <span className="topbar-themeswitcher">
                <InputSwitch checked={darkTheme} onChange={onThemeChange}></InputSwitch>
            </span>

            <ul className={topbarItemsClassName}>
                <li role="menuitem">
                    <button className="p-link">
                        <i className="pi pi-fw pi-user-edit"></i>
                        <span>Account</span>
                    </button>
                </li>
                <li role="menuitem">
                    <button className="p-link">
                        <i className="pi pi-fw pi-cog"></i>
                        <span>Settings</span>
                    </button>
                </li>
                <li role="menuitem">
                    <button className="p-link">
                        <i className="pi pi-fw pi-power-off"></i>
                        <span>Logout</span>
                    </button>
                </li>
            </ul>
        </div>
    )
}

export default AppTopbar;*/

export class AppTopbar extends Component {

    static defaultProps = {
        onMenuButtonClick: null,
        onTopbarMenuButtonClick: null,
        onTopbarItemClick: null,
        topbarMenuActive: false,
        activeTopbarItem: null,
        darkTheme: null,
        onThemeChange: null
    }

    static propTypes = {
        onMenuButtonClick: PropTypes.func.isRequired,
        onTopbarMenuButtonClick: PropTypes.func.isRequired,
        onTopbarItemClick: PropTypes.func.isRequired,
        topbarMenuActive: PropTypes.bool.isRequired,
        activeTopbarItem: PropTypes.string,
        darkTheme: PropTypes.bool,
        onThemeChange: PropTypes.func,
        onLogoutPress: PropTypes.func,
        userInfo: PropTypes.object
    }

    constructor() {
        super();
        this.state = {
            displayLogout: false
        };

        this.onClick = this.onClick.bind(this);
        this.onHide = this.onHide.bind(this);
    }

    onClick(name, position) {
        let state = {
            [`${name}`]: true
        };

        if (position) {
            state = {
                ...state,
                position
            }
        }

        this.setState(state);
    }

    onHide(name, result) {
        this.setState({
            [`${name}`]: false
        });

        if (name === 'displayLogout' && result === true) {
            this.props.onLogoutButtonClick();
        }
    }

    renderFooter(name) {
        return (
            <div>
                <Button label="Yes" icon="pi pi-check" onClick={() => this.onHide(name, true)} />
                <Button label="No" icon="pi pi-times" onClick={() => this.onHide(name, false)} className="p-button-secondary"/>
            </div>
        );
    }

    onTopbarItemClick(event, item) {
        if(this.props.onTopbarItemClick) {
            this.props.onTopbarItemClick({
                originalEvent: event,
                item: item
            });
        }
    }

    render() {
        let topbarItemsClassName = classNames('topbar-menu fadeInDown', {'topbar-menu-visible': this.props.topbarMenuActive});

        return (
            <>
            <Dialog header="Confirm Logout?" visible={this.state.displayLogout} style={{width: '20vw'}} onHide={() => this.onHide('displayLogout')} footer={this.renderFooter('displayLogout')}>
                <p>Are you sure you want to log out?</p>
            </Dialog>
            <div className="topbar clearfix">
                <button className="p-link" id="menu-button" onClick={this.props.onMenuButtonClick}>
                    <i className="pi pi-bars"></i>
                </button>

                <img className="logo" alt="apollo-layout" src="assets/layout/images/Mach1-Forms.png" />

                <button className="p-link profile" onClick={this.props.onTopbarMenuButtonClick} style={{height: '100%'}}>
                    <span className="username">{this.props.userInfo?.attributes?.email}</span>
                    {/* <img src="assets/layout/images/avatar/avatar.png" alt="apollo-layout" /> */}
                    <i className="pi pi-angle-down"></i>
                </button>

               

                <ul className={topbarItemsClassName}>
                    <li role="menuitem">
                        <button className="p-link" onClick={() => {window.location = "/#/account"}}>
                            <i className="pi pi-fw pi-user-edit"></i>
                            <span>Account</span>
                        </button>
                    </li>
                    <li role="menuitem">
                       
                        <button className="p-link" onClick={() => this.onClick('displayLogout')}>
                            <i className="pi pi-fw pi-power-off"></i>
                            <span>Logout</span>
                        </button>
                    </li>
                </ul>
            </div>
            </>
        );
    }
}
//<button className="p-link" onClick={this.props.onLogoutButtonClick}>