/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

/**
 * unique or primary key constraints on table "app_errors"
 */
export enum app_errors_constraint {
  app_errors_pkey = "app_errors_pkey",
}

/**
 * update columns of table "app_errors"
 */
export enum app_errors_update_column {
  application_type = "application_type",
  created_at = "created_at",
  error_json = "error_json",
  id = "id",
  user_cognito_id = "user_cognito_id",
}

/**
 * unique or primary key constraints on table "assigned_question_sets"
 */
export enum assigned_question_sets_constraint {
  assigned_question_sets_pkey = "assigned_question_sets_pkey",
  assigned_question_sets_user_id_questions_set_id_organization_id = "assigned_question_sets_user_id_questions_set_id_organization_id",
}

/**
 * update columns of table "assigned_question_sets"
 */
export enum assigned_question_sets_update_column {
  assigned_at = "assigned_at",
  assigned_by_user_id = "assigned_by_user_id",
  id = "id",
  organization_id = "organization_id",
  questions_set_id = "questions_set_id",
  user_id = "user_id",
}

/**
 * unique or primary key constraints on table "group_types"
 */
export enum group_types_constraint {
  group_types_id_key1 = "group_types_id_key1",
  group_types_pkey = "group_types_pkey",
}

export enum group_types_enum {
  FreeForm = "FreeForm",
  Multiselect = "Multiselect",
}

/**
 * update columns of table "group_types"
 */
export enum group_types_update_column {
  id = "id",
}

/**
 * unique or primary key constraints on table "menu_types"
 */
export enum menu_types_constraint {
  menu_type_pkey = "menu_type_pkey",
}

/**
 * update columns of table "menu_types"
 */
export enum menu_types_update_column {
  id = "id",
  name = "name",
}

/**
 * unique or primary key constraints on table "organizations"
 */
export enum organizations_constraint {
  organization_id_key = "organization_id_key",
  organization_pkey = "organization_pkey",
}

/**
 * update columns of table "organizations"
 */
export enum organizations_update_column {
  created_at = "created_at",
  display_name = "display_name",
  id = "id",
  logo_url = "logo_url",
  name = "name",
}

/**
 * unique or primary key constraints on table "push_messages"
 */
export enum push_messages_constraint {
  push_message_pkey = "push_message_pkey",
}

/**
 * update columns of table "push_messages"
 */
export enum push_messages_update_column {
  created_at = "created_at",
  created_by_user_id = "created_by_user_id",
  fulfilled = "fulfilled",
  fulfilled_at = "fulfilled_at",
  id = "id",
  message_body = "message_body",
  message_sound = "message_sound",
  message_text = "message_text",
  message_title = "message_title",
  message_to_user_id = "message_to_user_id",
  message_to_user_list = "message_to_user_list",
}

/**
 * unique or primary key constraints on table "question_answer_options"
 */
export enum question_answer_options_constraint {
  question_answer_option_pkey = "question_answer_option_pkey",
}

/**
 * update columns of table "question_answer_options"
 */
export enum question_answer_options_update_column {
  created_at = "created_at",
  header = "header",
  id = "id",
  is_deleted = "is_deleted",
  order = "order",
  question_answer_set_id = "question_answer_set_id",
  updated_at = "updated_at",
  value = "value",
}

/**
 * unique or primary key constraints on table "question_answer_set"
 */
export enum question_answer_set_constraint {
  question_answer_set_pkey = "question_answer_set_pkey",
}

/**
 * update columns of table "question_answer_set"
 */
export enum question_answer_set_update_column {
  created_at = "created_at",
  id = "id",
  is_deleted = "is_deleted",
  name = "name",
  organization_id = "organization_id",
  updated_at = "updated_at",
}

/**
 * unique or primary key constraints on table "question_freeform_answers"
 */
export enum question_freeform_answers_constraint {
  question_freeform_answers_pkey = "question_freeform_answers_pkey",
}

/**
 * update columns of table "question_freeform_answers"
 */
export enum question_freeform_answers_update_column {
  created_at = "created_at",
  id = "id",
  question_freeform_id = "question_freeform_id",
  question_set_submission_id = "question_set_submission_id",
  updated_at = "updated_at",
  value = "value",
}

/**
 * unique or primary key constraints on table "question_freeforms"
 */
export enum question_freeforms_constraint {
  question_freeform_pkey = "question_freeform_pkey",
}

/**
 * update columns of table "question_freeforms"
 */
export enum question_freeforms_update_column {
  created_at = "created_at",
  default_value = "default_value",
  formatter = "formatter",
  id = "id",
  is_deleted = "is_deleted",
  order = "order",
  question_group_id = "question_group_id",
  text = "text",
  updated_at = "updated_at",
  value_type = "value_type",
}

/**
 * unique or primary key constraints on table "question_groups"
 */
export enum question_groups_constraint {
  question_groups_pkey = "question_groups_pkey",
}

/**
 * update columns of table "question_groups"
 */
export enum question_groups_update_column {
  created_at = "created_at",
  group_type_id = "group_type_id",
  header = "header",
  id = "id",
  is_deleted = "is_deleted",
  order = "order",
  question_page_id = "question_page_id",
  updated_at = "updated_at",
}

/**
 * unique or primary key constraints on table "question_multiselect_answers"
 */
export enum question_multiselect_answers_constraint {
  question_multiselect_answers_pkey = "question_multiselect_answers_pkey",
}

/**
 * update columns of table "question_multiselect_answers"
 */
export enum question_multiselect_answers_update_column {
  created_at = "created_at",
  id = "id",
  question_answer_option_id = "question_answer_option_id",
  question_answer_option_value = "question_answer_option_value",
  question_multiselect_id = "question_multiselect_id",
  question_set_submission_id = "question_set_submission_id",
  updated_at = "updated_at",
}

/**
 * unique or primary key constraints on table "question_multiselects"
 */
export enum question_multiselects_constraint {
  question_multiselect_pkey = "question_multiselect_pkey",
}

/**
 * update columns of table "question_multiselects"
 */
export enum question_multiselects_update_column {
  created_at = "created_at",
  default_value = "default_value",
  id = "id",
  is_deleted = "is_deleted",
  order = "order",
  question_answer_set_id = "question_answer_set_id",
  question_group_id = "question_group_id",
  text = "text",
  updated_at = "updated_at",
}

/**
 * unique or primary key constraints on table "question_pages"
 */
export enum question_pages_constraint {
  pages_pkey = "pages_pkey",
}

/**
 * update columns of table "question_pages"
 */
export enum question_pages_update_column {
  created_at = "created_at",
  header = "header",
  id = "id",
  is_deleted = "is_deleted",
  page_number = "page_number",
  question_set_id = "question_set_id",
  updated_at = "updated_at",
}

/**
 * unique or primary key constraints on table "question_set_notification_subscriptions"
 */
export enum question_set_notification_subscriptions_constraint {
  question_set_notification_subscriptions_pkey = "question_set_notification_subscriptions_pkey",
}

/**
 * update columns of table "question_set_notification_subscriptions"
 */
export enum question_set_notification_subscriptions_update_column {
  created_at = "created_at",
  email_address = "email_address",
  id = "id",
  question_set_id = "question_set_id",
  updated_at = "updated_at",
}

/**
 * unique or primary key constraints on table "question_set_status"
 */
export enum question_set_status_constraint {
  question_set_status_pkey = "question_set_status_pkey",
}

export enum question_set_status_enum {
  draft = "draft",
  published = "published",
}

/**
 * update columns of table "question_set_status"
 */
export enum question_set_status_update_column {
  status = "status",
}

/**
 * unique or primary key constraints on table "question_set_submission"
 */
export enum question_set_submission_constraint {
  question_set_submission_pkey = "question_set_submission_pkey",
}

/**
 * update columns of table "question_set_submission"
 */
export enum question_set_submission_update_column {
  created_at = "created_at",
  id = "id",
  include_notification = "include_notification",
  is_deleted = "is_deleted",
  note = "note",
  question_set_id = "question_set_id",
  synced_to_airtable = "synced_to_airtable",
  updated_at = "updated_at",
  user_cognito_id = "user_cognito_id",
}

/**
 * unique or primary key constraints on table "question_sets"
 */
export enum question_sets_constraint {
  question_sets_pkey = "question_sets_pkey",
}

/**
 * update columns of table "question_sets"
 */
export enum question_sets_update_column {
  category = "category",
  created_at = "created_at",
  frequency = "frequency",
  id = "id",
  is_deleted = "is_deleted",
  name = "name",
  organization_id = "organization_id",
  question_set_status = "question_set_status",
  set_id = "set_id",
  updated_at = "updated_at",
  version = "version",
}

/**
 * unique or primary key constraints on table "question_submits"
 */
export enum question_submits_constraint {
  submits_pkey = "submits_pkey",
}

/**
 * update columns of table "question_submits"
 */
export enum question_submits_update_column {
  created_at = "created_at",
  header = "header",
  id = "id",
  is_deleted = "is_deleted",
  question_set_id = "question_set_id",
  text = "text",
  updated_at = "updated_at",
  validator = "validator",
}

/**
 * unique or primary key constraints on table "theme_types"
 */
export enum theme_types_constraint {
  theme_types_pkey = "theme_types_pkey",
}

/**
 * update columns of table "theme_types"
 */
export enum theme_types_update_column {
  id = "id",
  name = "name",
}

/**
 * unique or primary key constraints on table "types_frequency"
 */
export enum types_frequency_constraint {
  types_frequency_pkey = "types_frequency_pkey",
}

export enum types_frequency_enum {
  Daily = "Daily",
  Monthly = "Monthly",
  None = "None",
  Weekly = "Weekly",
}

/**
 * update columns of table "types_frequency"
 */
export enum types_frequency_update_column {
  id = "id",
}

/**
 * unique or primary key constraints on table "user_category"
 */
export enum user_category_constraint {
  user_category_pkey = "user_category_pkey",
}

/**
 * update columns of table "user_category"
 */
export enum user_category_update_column {
  category = "category",
  created_at = "created_at",
  id = "id",
  user_id = "user_id",
}

/**
 * unique or primary key constraints on table "user_invites"
 */
export enum user_invites_constraint {
  user_invites_email_key = "user_invites_email_key",
  user_invites_phone_key = "user_invites_phone_key",
  user_invites_pkey = "user_invites_pkey",
}

/**
 * update columns of table "user_invites"
 */
export enum user_invites_update_column {
  email = "email",
  fulfilled = "fulfilled",
  fulfilled_at = "fulfilled_at",
  id = "id",
  invited_at = "invited_at",
  invited_by_user_id = "invited_by_user_id",
  phone = "phone",
}

/**
 * unique or primary key constraints on table "users"
 */
export enum users_constraint {
  users__id_key = "users__id_key",
  users_cognito_id_key = "users_cognito_id_key",
  users_name_key = "users_name_key",
  users_pkey = "users_pkey",
  users_push_token_key = "users_push_token_key",
}

/**
 * update columns of table "users"
 */
export enum users_update_column {
  cognito_id = "cognito_id",
  created_at = "created_at",
  dark_mode = "dark_mode",
  email = "email",
  id = "id",
  is_disabled = "is_disabled",
  is_org_admin = "is_org_admin",
  last_seen = "last_seen",
  menu_type_id = "menu_type_id",
  organization_id = "organization_id",
  phone = "phone",
  push_token = "push_token",
  theme_type_id = "theme_type_id",
}

/**
 * unique or primary key constraints on table "value_types"
 */
export enum value_types_constraint {
  value_types_pkey = "value_types_pkey",
}

export enum value_types_enum {
  Date = "Date",
  Number = "Number",
  Text = "Text",
}

/**
 * update columns of table "value_types"
 */
export enum value_types_update_column {
  id = "id",
}

/**
 * unique or primary key constraints on table "webhook_headers"
 */
export enum webhook_headers_constraint {
  webhook_headers_pkey = "webhook_headers_pkey",
}

/**
 * update columns of table "webhook_headers"
 */
export enum webhook_headers_update_column {
  created_at = "created_at",
  id = "id",
  name = "name",
  updated_at = "updated_at",
  value = "value",
  webhook_id = "webhook_id",
}

/**
 * unique or primary key constraints on table "webhook_source"
 */
export enum webhook_source_constraint {
  webhook_source_pkey = "webhook_source_pkey",
}

/**
 * update columns of table "webhook_source"
 */
export enum webhook_source_update_column {
  created_at = "created_at",
  id = "id",
  source = "source",
  updated_at = "updated_at",
  webhook_id = "webhook_id",
}

/**
 * unique or primary key constraints on table "webhooks"
 */
export enum webhooks_constraint {
  webhooks_pkey = "webhooks_pkey",
}

/**
 * update columns of table "webhooks"
 */
export enum webhooks_update_column {
  created_at = "created_at",
  id = "id",
  is_enabled = "is_enabled",
  method = "method",
  name = "name",
  organization_id = "organization_id",
  updated_at = "updated_at",
  url = "url",
}

/**
 * expression to compare columns of type Boolean. All fields are combined with logical 'AND'.
 */
export interface Boolean_comparison_exp {
  _eq?: boolean | null;
  _gt?: boolean | null;
  _gte?: boolean | null;
  _in?: boolean[] | null;
  _is_null?: boolean | null;
  _lt?: boolean | null;
  _lte?: boolean | null;
  _neq?: boolean | null;
  _nin?: boolean[] | null;
}

/**
 * expression to compare columns of type Int. All fields are combined with logical 'AND'.
 */
export interface Int_comparison_exp {
  _eq?: number | null;
  _gt?: number | null;
  _gte?: number | null;
  _in?: number[] | null;
  _is_null?: boolean | null;
  _lt?: number | null;
  _lte?: number | null;
  _neq?: number | null;
  _nin?: number[] | null;
}

/**
 * expression to compare columns of type String. All fields are combined with logical 'AND'.
 */
export interface String_comparison_exp {
  _eq?: string | null;
  _gt?: string | null;
  _gte?: string | null;
  _ilike?: string | null;
  _in?: string[] | null;
  _is_null?: boolean | null;
  _like?: string | null;
  _lt?: string | null;
  _lte?: string | null;
  _neq?: string | null;
  _nilike?: string | null;
  _nin?: string[] | null;
  _nlike?: string | null;
  _nsimilar?: string | null;
  _similar?: string | null;
}

/**
 * input type for inserting array relation for remote table "app_errors"
 */
export interface app_errors_arr_rel_insert_input {
  data: app_errors_insert_input[];
  on_conflict?: app_errors_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "app_errors". All fields are combined with a logical 'AND'.
 */
export interface app_errors_bool_exp {
  _and?: (app_errors_bool_exp | null)[] | null;
  _not?: app_errors_bool_exp | null;
  _or?: (app_errors_bool_exp | null)[] | null;
  application_type?: String_comparison_exp | null;
  created_at?: timestamptz_comparison_exp | null;
  error_json?: String_comparison_exp | null;
  id?: Int_comparison_exp | null;
  user?: users_bool_exp | null;
  user_cognito_id?: uuid_comparison_exp | null;
}

/**
 * input type for inserting data into table "app_errors"
 */
export interface app_errors_insert_input {
  application_type?: string | null;
  created_at?: any | null;
  error_json?: string | null;
  id?: number | null;
  user?: users_obj_rel_insert_input | null;
  user_cognito_id?: any | null;
}

/**
 * on conflict condition type for table "app_errors"
 */
export interface app_errors_on_conflict {
  constraint: app_errors_constraint;
  update_columns: app_errors_update_column[];
  where?: app_errors_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "assigned_question_sets"
 */
export interface assigned_question_sets_arr_rel_insert_input {
  data: assigned_question_sets_insert_input[];
  on_conflict?: assigned_question_sets_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "assigned_question_sets". All fields are combined with a logical 'AND'.
 */
export interface assigned_question_sets_bool_exp {
  _and?: (assigned_question_sets_bool_exp | null)[] | null;
  _not?: assigned_question_sets_bool_exp | null;
  _or?: (assigned_question_sets_bool_exp | null)[] | null;
  assigned_at?: timestamptz_comparison_exp | null;
  assigned_by_user_id?: Int_comparison_exp | null;
  id?: bigint_comparison_exp | null;
  organization_id?: Int_comparison_exp | null;
  questions_set_id?: Int_comparison_exp | null;
  user?: users_bool_exp | null;
  user_id?: Int_comparison_exp | null;
}

/**
 * input type for inserting data into table "assigned_question_sets"
 */
export interface assigned_question_sets_insert_input {
  assigned_at?: any | null;
  assigned_by_user_id?: number | null;
  id?: any | null;
  organization_id?: number | null;
  questions_set_id?: number | null;
  user?: users_obj_rel_insert_input | null;
  user_id?: number | null;
}

/**
 * on conflict condition type for table "assigned_question_sets"
 */
export interface assigned_question_sets_on_conflict {
  constraint: assigned_question_sets_constraint;
  update_columns: assigned_question_sets_update_column[];
  where?: assigned_question_sets_bool_exp | null;
}

/**
 * expression to compare columns of type bigint. All fields are combined with logical 'AND'.
 */
export interface bigint_comparison_exp {
  _eq?: any | null;
  _gt?: any | null;
  _gte?: any | null;
  _in?: any[] | null;
  _is_null?: boolean | null;
  _lt?: any | null;
  _lte?: any | null;
  _neq?: any | null;
  _nin?: any[] | null;
}

/**
 * Boolean expression to filter rows from the table "group_types". All fields are combined with a logical 'AND'.
 */
export interface group_types_bool_exp {
  _and?: (group_types_bool_exp | null)[] | null;
  _not?: group_types_bool_exp | null;
  _or?: (group_types_bool_exp | null)[] | null;
  id?: String_comparison_exp | null;
  question_groups?: question_groups_bool_exp | null;
}

/**
 * expression to compare columns of type group_types_enum. All fields are combined with logical 'AND'.
 */
export interface group_types_enum_comparison_exp {
  _eq?: group_types_enum | null;
  _in?: group_types_enum[] | null;
  _is_null?: boolean | null;
  _neq?: group_types_enum | null;
  _nin?: group_types_enum[] | null;
}

/**
 * input type for inserting data into table "group_types"
 */
export interface group_types_insert_input {
  id?: string | null;
  question_groups?: question_groups_arr_rel_insert_input | null;
}

/**
 * input type for inserting object relation for remote table "group_types"
 */
export interface group_types_obj_rel_insert_input {
  data: group_types_insert_input;
  on_conflict?: group_types_on_conflict | null;
}

/**
 * on conflict condition type for table "group_types"
 */
export interface group_types_on_conflict {
  constraint: group_types_constraint;
  update_columns: group_types_update_column[];
  where?: group_types_bool_exp | null;
}

/**
 * expression to compare columns of type jsonb. All fields are combined with logical 'AND'.
 */
export interface jsonb_comparison_exp {
  _contained_in?: any | null;
  _contains?: any | null;
  _eq?: any | null;
  _gt?: any | null;
  _gte?: any | null;
  _has_key?: string | null;
  _has_keys_all?: string[] | null;
  _has_keys_any?: string[] | null;
  _in?: any[] | null;
  _is_null?: boolean | null;
  _lt?: any | null;
  _lte?: any | null;
  _neq?: any | null;
  _nin?: any[] | null;
}

/**
 * Boolean expression to filter rows from the table "menu_types". All fields are combined with a logical 'AND'.
 */
export interface menu_types_bool_exp {
  _and?: (menu_types_bool_exp | null)[] | null;
  _not?: menu_types_bool_exp | null;
  _or?: (menu_types_bool_exp | null)[] | null;
  id?: Int_comparison_exp | null;
  name?: String_comparison_exp | null;
  users?: users_bool_exp | null;
}

/**
 * input type for inserting data into table "menu_types"
 */
export interface menu_types_insert_input {
  id?: number | null;
  name?: string | null;
  users?: users_arr_rel_insert_input | null;
}

/**
 * input type for inserting object relation for remote table "menu_types"
 */
export interface menu_types_obj_rel_insert_input {
  data: menu_types_insert_input;
  on_conflict?: menu_types_on_conflict | null;
}

/**
 * on conflict condition type for table "menu_types"
 */
export interface menu_types_on_conflict {
  constraint: menu_types_constraint;
  update_columns: menu_types_update_column[];
  where?: menu_types_bool_exp | null;
}

/**
 * Boolean expression to filter rows from the table "organizations". All fields are combined with a logical 'AND'.
 */
export interface organizations_bool_exp {
  _and?: (organizations_bool_exp | null)[] | null;
  _not?: organizations_bool_exp | null;
  _or?: (organizations_bool_exp | null)[] | null;
  assigned_question_sets?: assigned_question_sets_bool_exp | null;
  created_at?: timestamp_comparison_exp | null;
  display_name?: String_comparison_exp | null;
  id?: Int_comparison_exp | null;
  logo_url?: String_comparison_exp | null;
  name?: String_comparison_exp | null;
  question_sets?: question_sets_bool_exp | null;
  users_organization?: users_bool_exp | null;
  webhooks?: webhooks_bool_exp | null;
}

/**
 * input type for inserting data into table "organizations"
 */
export interface organizations_insert_input {
  assigned_question_sets?: assigned_question_sets_arr_rel_insert_input | null;
  created_at?: any | null;
  display_name?: string | null;
  id?: number | null;
  logo_url?: string | null;
  name?: string | null;
  question_sets?: question_sets_arr_rel_insert_input | null;
  users_organization?: users_arr_rel_insert_input | null;
  webhooks?: webhooks_arr_rel_insert_input | null;
}

/**
 * input type for inserting object relation for remote table "organizations"
 */
export interface organizations_obj_rel_insert_input {
  data: organizations_insert_input;
  on_conflict?: organizations_on_conflict | null;
}

/**
 * on conflict condition type for table "organizations"
 */
export interface organizations_on_conflict {
  constraint: organizations_constraint;
  update_columns: organizations_update_column[];
  where?: organizations_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "push_messages"
 */
export interface push_messages_arr_rel_insert_input {
  data: push_messages_insert_input[];
  on_conflict?: push_messages_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "push_messages". All fields are combined with a logical 'AND'.
 */
export interface push_messages_bool_exp {
  _and?: (push_messages_bool_exp | null)[] | null;
  _not?: push_messages_bool_exp | null;
  _or?: (push_messages_bool_exp | null)[] | null;
  created_at?: timestamptz_comparison_exp | null;
  created_by_user_id?: Int_comparison_exp | null;
  fulfilled?: Boolean_comparison_exp | null;
  fulfilled_at?: timestamptz_comparison_exp | null;
  id?: bigint_comparison_exp | null;
  message_body?: jsonb_comparison_exp | null;
  message_sound?: String_comparison_exp | null;
  message_text?: String_comparison_exp | null;
  message_title?: String_comparison_exp | null;
  message_to_user_id?: Int_comparison_exp | null;
  message_to_user_list?: jsonb_comparison_exp | null;
  user?: users_bool_exp | null;
  userByCreatedByUserId?: users_bool_exp | null;
}

/**
 * input type for inserting data into table "push_messages"
 */
export interface push_messages_insert_input {
  created_at?: any | null;
  created_by_user_id?: number | null;
  fulfilled?: boolean | null;
  fulfilled_at?: any | null;
  id?: any | null;
  message_body?: any | null;
  message_sound?: string | null;
  message_text?: string | null;
  message_title?: string | null;
  message_to_user_id?: number | null;
  message_to_user_list?: any | null;
  user?: users_obj_rel_insert_input | null;
  userByCreatedByUserId?: users_obj_rel_insert_input | null;
}

/**
 * on conflict condition type for table "push_messages"
 */
export interface push_messages_on_conflict {
  constraint: push_messages_constraint;
  update_columns: push_messages_update_column[];
  where?: push_messages_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "question_answer_options"
 */
export interface question_answer_options_arr_rel_insert_input {
  data: question_answer_options_insert_input[];
  on_conflict?: question_answer_options_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "question_answer_options". All fields are combined with a logical 'AND'.
 */
export interface question_answer_options_bool_exp {
  _and?: (question_answer_options_bool_exp | null)[] | null;
  _not?: question_answer_options_bool_exp | null;
  _or?: (question_answer_options_bool_exp | null)[] | null;
  created_at?: timestamptz_comparison_exp | null;
  header?: String_comparison_exp | null;
  id?: Int_comparison_exp | null;
  is_deleted?: Boolean_comparison_exp | null;
  order?: Int_comparison_exp | null;
  question_answer_set?: question_answer_set_bool_exp | null;
  question_answer_set_id?: Int_comparison_exp | null;
  updated_at?: timestamptz_comparison_exp | null;
  value?: String_comparison_exp | null;
}

/**
 * input type for inserting data into table "question_answer_options"
 */
export interface question_answer_options_insert_input {
  created_at?: any | null;
  header?: string | null;
  id?: number | null;
  is_deleted?: boolean | null;
  order?: number | null;
  question_answer_set?: question_answer_set_obj_rel_insert_input | null;
  question_answer_set_id?: number | null;
  updated_at?: any | null;
  value?: string | null;
}

/**
 * on conflict condition type for table "question_answer_options"
 */
export interface question_answer_options_on_conflict {
  constraint: question_answer_options_constraint;
  update_columns: question_answer_options_update_column[];
  where?: question_answer_options_bool_exp | null;
}

/**
 * Boolean expression to filter rows from the table "question_answer_set". All fields are combined with a logical 'AND'.
 */
export interface question_answer_set_bool_exp {
  _and?: (question_answer_set_bool_exp | null)[] | null;
  _not?: question_answer_set_bool_exp | null;
  _or?: (question_answer_set_bool_exp | null)[] | null;
  created_at?: timestamptz_comparison_exp | null;
  id?: Int_comparison_exp | null;
  is_deleted?: Boolean_comparison_exp | null;
  name?: String_comparison_exp | null;
  organization?: organizations_bool_exp | null;
  organization_id?: Int_comparison_exp | null;
  question_answer_options?: question_answer_options_bool_exp | null;
  question_multiselects?: question_multiselects_bool_exp | null;
  updated_at?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "question_answer_set"
 */
export interface question_answer_set_insert_input {
  created_at?: any | null;
  id?: number | null;
  is_deleted?: boolean | null;
  name?: string | null;
  organization?: organizations_obj_rel_insert_input | null;
  organization_id?: number | null;
  question_answer_options?: question_answer_options_arr_rel_insert_input | null;
  question_multiselects?: question_multiselects_arr_rel_insert_input | null;
  updated_at?: any | null;
}

/**
 * input type for inserting object relation for remote table "question_answer_set"
 */
export interface question_answer_set_obj_rel_insert_input {
  data: question_answer_set_insert_input;
  on_conflict?: question_answer_set_on_conflict | null;
}

/**
 * on conflict condition type for table "question_answer_set"
 */
export interface question_answer_set_on_conflict {
  constraint: question_answer_set_constraint;
  update_columns: question_answer_set_update_column[];
  where?: question_answer_set_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "question_freeform_answers"
 */
export interface question_freeform_answers_arr_rel_insert_input {
  data: question_freeform_answers_insert_input[];
  on_conflict?: question_freeform_answers_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "question_freeform_answers". All fields are combined with a logical 'AND'.
 */
export interface question_freeform_answers_bool_exp {
  _and?: (question_freeform_answers_bool_exp | null)[] | null;
  _not?: question_freeform_answers_bool_exp | null;
  _or?: (question_freeform_answers_bool_exp | null)[] | null;
  created_at?: timestamptz_comparison_exp | null;
  id?: Int_comparison_exp | null;
  question_freeform?: question_freeforms_bool_exp | null;
  question_freeform_id?: Int_comparison_exp | null;
  question_set_submission?: question_set_submission_bool_exp | null;
  question_set_submission_id?: Int_comparison_exp | null;
  updated_at?: timestamptz_comparison_exp | null;
  value?: String_comparison_exp | null;
}

/**
 * input type for inserting data into table "question_freeform_answers"
 */
export interface question_freeform_answers_insert_input {
  created_at?: any | null;
  id?: number | null;
  question_freeform?: question_freeforms_obj_rel_insert_input | null;
  question_freeform_id?: number | null;
  question_set_submission?: question_set_submission_obj_rel_insert_input | null;
  question_set_submission_id?: number | null;
  updated_at?: any | null;
  value?: string | null;
}

/**
 * on conflict condition type for table "question_freeform_answers"
 */
export interface question_freeform_answers_on_conflict {
  constraint: question_freeform_answers_constraint;
  update_columns: question_freeform_answers_update_column[];
  where?: question_freeform_answers_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "question_freeforms"
 */
export interface question_freeforms_arr_rel_insert_input {
  data: question_freeforms_insert_input[];
  on_conflict?: question_freeforms_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "question_freeforms". All fields are combined with a logical 'AND'.
 */
export interface question_freeforms_bool_exp {
  _and?: (question_freeforms_bool_exp | null)[] | null;
  _not?: question_freeforms_bool_exp | null;
  _or?: (question_freeforms_bool_exp | null)[] | null;
  created_at?: timestamptz_comparison_exp | null;
  default_value?: String_comparison_exp | null;
  formatter?: String_comparison_exp | null;
  id?: Int_comparison_exp | null;
  is_deleted?: Boolean_comparison_exp | null;
  order?: Int_comparison_exp | null;
  question_freeform_answers?: question_freeform_answers_bool_exp | null;
  question_group?: question_groups_bool_exp | null;
  question_group_id?: Int_comparison_exp | null;
  text?: String_comparison_exp | null;
  updated_at?: timestamptz_comparison_exp | null;
  valueTypeByValueType?: value_types_bool_exp | null;
  value_type?: value_types_enum_comparison_exp | null;
}

/**
 * input type for inserting data into table "question_freeforms"
 */
export interface question_freeforms_insert_input {
  created_at?: any | null;
  default_value?: string | null;
  formatter?: string | null;
  id?: number | null;
  is_deleted?: boolean | null;
  order?: number | null;
  question_freeform_answers?: question_freeform_answers_arr_rel_insert_input | null;
  question_group?: question_groups_obj_rel_insert_input | null;
  question_group_id?: number | null;
  text?: string | null;
  updated_at?: any | null;
  valueTypeByValueType?: value_types_obj_rel_insert_input | null;
  value_type?: value_types_enum | null;
}

/**
 * input type for inserting object relation for remote table "question_freeforms"
 */
export interface question_freeforms_obj_rel_insert_input {
  data: question_freeforms_insert_input;
  on_conflict?: question_freeforms_on_conflict | null;
}

/**
 * on conflict condition type for table "question_freeforms"
 */
export interface question_freeforms_on_conflict {
  constraint: question_freeforms_constraint;
  update_columns: question_freeforms_update_column[];
  where?: question_freeforms_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "question_groups"
 */
export interface question_groups_arr_rel_insert_input {
  data: question_groups_insert_input[];
  on_conflict?: question_groups_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "question_groups". All fields are combined with a logical 'AND'.
 */
export interface question_groups_bool_exp {
  _and?: (question_groups_bool_exp | null)[] | null;
  _not?: question_groups_bool_exp | null;
  _or?: (question_groups_bool_exp | null)[] | null;
  created_at?: timestamptz_comparison_exp | null;
  group_type?: group_types_bool_exp | null;
  group_type_id?: group_types_enum_comparison_exp | null;
  header?: String_comparison_exp | null;
  id?: Int_comparison_exp | null;
  is_deleted?: Boolean_comparison_exp | null;
  order?: Int_comparison_exp | null;
  question_freeforms?: question_freeforms_bool_exp | null;
  question_multiselects?: question_multiselects_bool_exp | null;
  question_page?: question_pages_bool_exp | null;
  question_page_id?: Int_comparison_exp | null;
  updated_at?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "question_groups"
 */
export interface question_groups_insert_input {
  created_at?: any | null;
  group_type?: group_types_obj_rel_insert_input | null;
  group_type_id?: group_types_enum | null;
  header?: string | null;
  id?: number | null;
  is_deleted?: boolean | null;
  order?: number | null;
  question_freeforms?: question_freeforms_arr_rel_insert_input | null;
  question_multiselects?: question_multiselects_arr_rel_insert_input | null;
  question_page?: question_pages_obj_rel_insert_input | null;
  question_page_id?: number | null;
  updated_at?: any | null;
}

/**
 * input type for inserting object relation for remote table "question_groups"
 */
export interface question_groups_obj_rel_insert_input {
  data: question_groups_insert_input;
  on_conflict?: question_groups_on_conflict | null;
}

/**
 * on conflict condition type for table "question_groups"
 */
export interface question_groups_on_conflict {
  constraint: question_groups_constraint;
  update_columns: question_groups_update_column[];
  where?: question_groups_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "question_multiselect_answers"
 */
export interface question_multiselect_answers_arr_rel_insert_input {
  data: question_multiselect_answers_insert_input[];
  on_conflict?: question_multiselect_answers_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "question_multiselect_answers". All fields are combined with a logical 'AND'.
 */
export interface question_multiselect_answers_bool_exp {
  _and?: (question_multiselect_answers_bool_exp | null)[] | null;
  _not?: question_multiselect_answers_bool_exp | null;
  _or?: (question_multiselect_answers_bool_exp | null)[] | null;
  created_at?: timestamptz_comparison_exp | null;
  id?: Int_comparison_exp | null;
  question_answer_option_id?: Int_comparison_exp | null;
  question_answer_option_value?: String_comparison_exp | null;
  question_multiselect?: question_multiselects_bool_exp | null;
  question_multiselect_id?: Int_comparison_exp | null;
  question_set_submission?: question_set_submission_bool_exp | null;
  question_set_submission_id?: Int_comparison_exp | null;
  updated_at?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "question_multiselect_answers"
 */
export interface question_multiselect_answers_insert_input {
  created_at?: any | null;
  id?: number | null;
  question_answer_option_id?: number | null;
  question_answer_option_value?: string | null;
  question_multiselect?: question_multiselects_obj_rel_insert_input | null;
  question_multiselect_id?: number | null;
  question_set_submission?: question_set_submission_obj_rel_insert_input | null;
  question_set_submission_id?: number | null;
  updated_at?: any | null;
}

/**
 * on conflict condition type for table "question_multiselect_answers"
 */
export interface question_multiselect_answers_on_conflict {
  constraint: question_multiselect_answers_constraint;
  update_columns: question_multiselect_answers_update_column[];
  where?: question_multiselect_answers_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "question_multiselects"
 */
export interface question_multiselects_arr_rel_insert_input {
  data: question_multiselects_insert_input[];
  on_conflict?: question_multiselects_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "question_multiselects". All fields are combined with a logical 'AND'.
 */
export interface question_multiselects_bool_exp {
  _and?: (question_multiselects_bool_exp | null)[] | null;
  _not?: question_multiselects_bool_exp | null;
  _or?: (question_multiselects_bool_exp | null)[] | null;
  created_at?: timestamptz_comparison_exp | null;
  default_value?: String_comparison_exp | null;
  id?: Int_comparison_exp | null;
  is_deleted?: Boolean_comparison_exp | null;
  order?: Int_comparison_exp | null;
  question_answer_set?: question_answer_set_bool_exp | null;
  question_answer_set_id?: Int_comparison_exp | null;
  question_group?: question_groups_bool_exp | null;
  question_group_id?: Int_comparison_exp | null;
  question_multiselect_answers?: question_multiselect_answers_bool_exp | null;
  text?: String_comparison_exp | null;
  updated_at?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "question_multiselects"
 */
export interface question_multiselects_insert_input {
  created_at?: any | null;
  default_value?: string | null;
  id?: number | null;
  is_deleted?: boolean | null;
  order?: number | null;
  question_answer_set?: question_answer_set_obj_rel_insert_input | null;
  question_answer_set_id?: number | null;
  question_group?: question_groups_obj_rel_insert_input | null;
  question_group_id?: number | null;
  question_multiselect_answers?: question_multiselect_answers_arr_rel_insert_input | null;
  text?: string | null;
  updated_at?: any | null;
}

/**
 * input type for inserting object relation for remote table "question_multiselects"
 */
export interface question_multiselects_obj_rel_insert_input {
  data: question_multiselects_insert_input;
  on_conflict?: question_multiselects_on_conflict | null;
}

/**
 * on conflict condition type for table "question_multiselects"
 */
export interface question_multiselects_on_conflict {
  constraint: question_multiselects_constraint;
  update_columns: question_multiselects_update_column[];
  where?: question_multiselects_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "question_pages"
 */
export interface question_pages_arr_rel_insert_input {
  data: question_pages_insert_input[];
  on_conflict?: question_pages_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "question_pages". All fields are combined with a logical 'AND'.
 */
export interface question_pages_bool_exp {
  _and?: (question_pages_bool_exp | null)[] | null;
  _not?: question_pages_bool_exp | null;
  _or?: (question_pages_bool_exp | null)[] | null;
  created_at?: timestamptz_comparison_exp | null;
  header?: String_comparison_exp | null;
  id?: Int_comparison_exp | null;
  is_deleted?: Boolean_comparison_exp | null;
  page_number?: Int_comparison_exp | null;
  question_groups?: question_groups_bool_exp | null;
  question_set?: question_sets_bool_exp | null;
  question_set_id?: Int_comparison_exp | null;
  updated_at?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "question_pages"
 */
export interface question_pages_insert_input {
  created_at?: any | null;
  header?: string | null;
  id?: number | null;
  is_deleted?: boolean | null;
  page_number?: number | null;
  question_groups?: question_groups_arr_rel_insert_input | null;
  question_set?: question_sets_obj_rel_insert_input | null;
  question_set_id?: number | null;
  updated_at?: any | null;
}

/**
 * input type for inserting object relation for remote table "question_pages"
 */
export interface question_pages_obj_rel_insert_input {
  data: question_pages_insert_input;
  on_conflict?: question_pages_on_conflict | null;
}

/**
 * on conflict condition type for table "question_pages"
 */
export interface question_pages_on_conflict {
  constraint: question_pages_constraint;
  update_columns: question_pages_update_column[];
  where?: question_pages_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "question_set_notification_subscriptions"
 */
export interface question_set_notification_subscriptions_arr_rel_insert_input {
  data: question_set_notification_subscriptions_insert_input[];
  on_conflict?: question_set_notification_subscriptions_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "question_set_notification_subscriptions". All fields are combined with a logical 'AND'.
 */
export interface question_set_notification_subscriptions_bool_exp {
  _and?: (question_set_notification_subscriptions_bool_exp | null)[] | null;
  _not?: question_set_notification_subscriptions_bool_exp | null;
  _or?: (question_set_notification_subscriptions_bool_exp | null)[] | null;
  created_at?: timestamptz_comparison_exp | null;
  email_address?: String_comparison_exp | null;
  id?: Int_comparison_exp | null;
  question_set?: question_sets_bool_exp | null;
  question_set_id?: Int_comparison_exp | null;
  updated_at?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "question_set_notification_subscriptions"
 */
export interface question_set_notification_subscriptions_insert_input {
  created_at?: any | null;
  email_address?: string | null;
  id?: number | null;
  question_set?: question_sets_obj_rel_insert_input | null;
  question_set_id?: number | null;
  updated_at?: any | null;
}

/**
 * on conflict condition type for table "question_set_notification_subscriptions"
 */
export interface question_set_notification_subscriptions_on_conflict {
  constraint: question_set_notification_subscriptions_constraint;
  update_columns: question_set_notification_subscriptions_update_column[];
  where?: question_set_notification_subscriptions_bool_exp | null;
}

/**
 * Boolean expression to filter rows from the table "question_set_status". All fields are combined with a logical 'AND'.
 */
export interface question_set_status_bool_exp {
  _and?: (question_set_status_bool_exp | null)[] | null;
  _not?: question_set_status_bool_exp | null;
  _or?: (question_set_status_bool_exp | null)[] | null;
  status?: String_comparison_exp | null;
}

/**
 * expression to compare columns of type question_set_status_enum. All fields are combined with logical 'AND'.
 */
export interface question_set_status_enum_comparison_exp {
  _eq?: question_set_status_enum | null;
  _in?: question_set_status_enum[] | null;
  _is_null?: boolean | null;
  _neq?: question_set_status_enum | null;
  _nin?: question_set_status_enum[] | null;
}

/**
 * input type for inserting data into table "question_set_status"
 */
export interface question_set_status_insert_input {
  status?: string | null;
}

/**
 * input type for inserting object relation for remote table "question_set_status"
 */
export interface question_set_status_obj_rel_insert_input {
  data: question_set_status_insert_input;
  on_conflict?: question_set_status_on_conflict | null;
}

/**
 * on conflict condition type for table "question_set_status"
 */
export interface question_set_status_on_conflict {
  constraint: question_set_status_constraint;
  update_columns: question_set_status_update_column[];
  where?: question_set_status_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "question_set_submission"
 */
export interface question_set_submission_arr_rel_insert_input {
  data: question_set_submission_insert_input[];
  on_conflict?: question_set_submission_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "question_set_submission". All fields are combined with a logical 'AND'.
 */
export interface question_set_submission_bool_exp {
  _and?: (question_set_submission_bool_exp | null)[] | null;
  _not?: question_set_submission_bool_exp | null;
  _or?: (question_set_submission_bool_exp | null)[] | null;
  created_at?: timestamptz_comparison_exp | null;
  id?: Int_comparison_exp | null;
  include_notification?: Boolean_comparison_exp | null;
  is_deleted?: Boolean_comparison_exp | null;
  note?: String_comparison_exp | null;
  question_freeform_answers?: question_freeform_answers_bool_exp | null;
  question_multiselect_answers?: question_multiselect_answers_bool_exp | null;
  question_set?: question_sets_bool_exp | null;
  question_set_id?: Int_comparison_exp | null;
  synced_to_airtable?: Boolean_comparison_exp | null;
  updated_at?: timestamptz_comparison_exp | null;
  user?: users_bool_exp | null;
  user_cognito_id?: uuid_comparison_exp | null;
}

/**
 * input type for inserting data into table "question_set_submission"
 */
export interface question_set_submission_insert_input {
  created_at?: any | null;
  id?: number | null;
  include_notification?: boolean | null;
  is_deleted?: boolean | null;
  note?: string | null;
  question_freeform_answers?: question_freeform_answers_arr_rel_insert_input | null;
  question_multiselect_answers?: question_multiselect_answers_arr_rel_insert_input | null;
  question_set?: question_sets_obj_rel_insert_input | null;
  question_set_id?: number | null;
  synced_to_airtable?: boolean | null;
  updated_at?: any | null;
  user?: users_obj_rel_insert_input | null;
  user_cognito_id?: any | null;
}

/**
 * input type for inserting object relation for remote table "question_set_submission"
 */
export interface question_set_submission_obj_rel_insert_input {
  data: question_set_submission_insert_input;
  on_conflict?: question_set_submission_on_conflict | null;
}

/**
 * on conflict condition type for table "question_set_submission"
 */
export interface question_set_submission_on_conflict {
  constraint: question_set_submission_constraint;
  update_columns: question_set_submission_update_column[];
  where?: question_set_submission_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "question_sets"
 */
export interface question_sets_arr_rel_insert_input {
  data: question_sets_insert_input[];
  on_conflict?: question_sets_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "question_sets". All fields are combined with a logical 'AND'.
 */
export interface question_sets_bool_exp {
  _and?: (question_sets_bool_exp | null)[] | null;
  _not?: question_sets_bool_exp | null;
  _or?: (question_sets_bool_exp | null)[] | null;
  assigned_question_sets?: assigned_question_sets_bool_exp | null;
  category?: String_comparison_exp | null;
  created_at?: timestamptz_comparison_exp | null;
  frequency?: types_frequency_enum_comparison_exp | null;
  id?: Int_comparison_exp | null;
  is_deleted?: Boolean_comparison_exp | null;
  name?: String_comparison_exp | null;
  organization?: organizations_bool_exp | null;
  organization_id?: Int_comparison_exp | null;
  questionSetStatusByQuestionSetStatus?: question_set_status_bool_exp | null;
  question_pages?: question_pages_bool_exp | null;
  question_set_notification_subscriptions?: question_set_notification_subscriptions_bool_exp | null;
  question_set_status?: question_set_status_enum_comparison_exp | null;
  question_set_submissions?: question_set_submission_bool_exp | null;
  question_submits?: question_submits_bool_exp | null;
  set_id?: uuid_comparison_exp | null;
  types_frequency?: types_frequency_bool_exp | null;
  updated_at?: timestamptz_comparison_exp | null;
  version?: String_comparison_exp | null;
}

/**
 * input type for inserting data into table "question_sets"
 */
export interface question_sets_insert_input {
  assigned_question_sets?: assigned_question_sets_arr_rel_insert_input | null;
  category?: string | null;
  created_at?: any | null;
  frequency?: types_frequency_enum | null;
  id?: number | null;
  is_deleted?: boolean | null;
  name?: string | null;
  organization?: organizations_obj_rel_insert_input | null;
  organization_id?: number | null;
  questionSetStatusByQuestionSetStatus?: question_set_status_obj_rel_insert_input | null;
  question_pages?: question_pages_arr_rel_insert_input | null;
  question_set_notification_subscriptions?: question_set_notification_subscriptions_arr_rel_insert_input | null;
  question_set_status?: question_set_status_enum | null;
  question_set_submissions?: question_set_submission_arr_rel_insert_input | null;
  question_submits?: question_submits_arr_rel_insert_input | null;
  set_id?: any | null;
  types_frequency?: types_frequency_obj_rel_insert_input | null;
  updated_at?: any | null;
  version?: string | null;
}

/**
 * input type for inserting object relation for remote table "question_sets"
 */
export interface question_sets_obj_rel_insert_input {
  data: question_sets_insert_input;
  on_conflict?: question_sets_on_conflict | null;
}

/**
 * on conflict condition type for table "question_sets"
 */
export interface question_sets_on_conflict {
  constraint: question_sets_constraint;
  update_columns: question_sets_update_column[];
  where?: question_sets_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "question_submits"
 */
export interface question_submits_arr_rel_insert_input {
  data: question_submits_insert_input[];
  on_conflict?: question_submits_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "question_submits". All fields are combined with a logical 'AND'.
 */
export interface question_submits_bool_exp {
  _and?: (question_submits_bool_exp | null)[] | null;
  _not?: question_submits_bool_exp | null;
  _or?: (question_submits_bool_exp | null)[] | null;
  created_at?: timestamptz_comparison_exp | null;
  header?: String_comparison_exp | null;
  id?: Int_comparison_exp | null;
  is_deleted?: Boolean_comparison_exp | null;
  question_set?: question_sets_bool_exp | null;
  question_set_id?: Int_comparison_exp | null;
  text?: String_comparison_exp | null;
  updated_at?: timestamptz_comparison_exp | null;
  validator?: String_comparison_exp | null;
}

/**
 * input type for inserting data into table "question_submits"
 */
export interface question_submits_insert_input {
  created_at?: any | null;
  header?: string | null;
  id?: number | null;
  is_deleted?: boolean | null;
  question_set?: question_sets_obj_rel_insert_input | null;
  question_set_id?: number | null;
  text?: string | null;
  updated_at?: any | null;
  validator?: string | null;
}

/**
 * on conflict condition type for table "question_submits"
 */
export interface question_submits_on_conflict {
  constraint: question_submits_constraint;
  update_columns: question_submits_update_column[];
  where?: question_submits_bool_exp | null;
}

/**
 * Boolean expression to filter rows from the table "theme_types". All fields are combined with a logical 'AND'.
 */
export interface theme_types_bool_exp {
  _and?: (theme_types_bool_exp | null)[] | null;
  _not?: theme_types_bool_exp | null;
  _or?: (theme_types_bool_exp | null)[] | null;
  id?: Int_comparison_exp | null;
  name?: String_comparison_exp | null;
  users?: users_bool_exp | null;
}

/**
 * input type for inserting data into table "theme_types"
 */
export interface theme_types_insert_input {
  id?: number | null;
  name?: string | null;
  users?: users_arr_rel_insert_input | null;
}

/**
 * input type for inserting object relation for remote table "theme_types"
 */
export interface theme_types_obj_rel_insert_input {
  data: theme_types_insert_input;
  on_conflict?: theme_types_on_conflict | null;
}

/**
 * on conflict condition type for table "theme_types"
 */
export interface theme_types_on_conflict {
  constraint: theme_types_constraint;
  update_columns: theme_types_update_column[];
  where?: theme_types_bool_exp | null;
}

/**
 * expression to compare columns of type timestamp. All fields are combined with logical 'AND'.
 */
export interface timestamp_comparison_exp {
  _eq?: any | null;
  _gt?: any | null;
  _gte?: any | null;
  _in?: any[] | null;
  _is_null?: boolean | null;
  _lt?: any | null;
  _lte?: any | null;
  _neq?: any | null;
  _nin?: any[] | null;
}

/**
 * expression to compare columns of type timestamptz. All fields are combined with logical 'AND'.
 */
export interface timestamptz_comparison_exp {
  _eq?: any | null;
  _gt?: any | null;
  _gte?: any | null;
  _in?: any[] | null;
  _is_null?: boolean | null;
  _lt?: any | null;
  _lte?: any | null;
  _neq?: any | null;
  _nin?: any[] | null;
}

/**
 * Boolean expression to filter rows from the table "types_frequency". All fields are combined with a logical 'AND'.
 */
export interface types_frequency_bool_exp {
  _and?: (types_frequency_bool_exp | null)[] | null;
  _not?: types_frequency_bool_exp | null;
  _or?: (types_frequency_bool_exp | null)[] | null;
  id?: String_comparison_exp | null;
  question_sets?: question_sets_bool_exp | null;
}

/**
 * expression to compare columns of type types_frequency_enum. All fields are combined with logical 'AND'.
 */
export interface types_frequency_enum_comparison_exp {
  _eq?: types_frequency_enum | null;
  _in?: types_frequency_enum[] | null;
  _is_null?: boolean | null;
  _neq?: types_frequency_enum | null;
  _nin?: types_frequency_enum[] | null;
}

/**
 * input type for inserting data into table "types_frequency"
 */
export interface types_frequency_insert_input {
  id?: string | null;
  question_sets?: question_sets_arr_rel_insert_input | null;
}

/**
 * input type for inserting object relation for remote table "types_frequency"
 */
export interface types_frequency_obj_rel_insert_input {
  data: types_frequency_insert_input;
  on_conflict?: types_frequency_on_conflict | null;
}

/**
 * on conflict condition type for table "types_frequency"
 */
export interface types_frequency_on_conflict {
  constraint: types_frequency_constraint;
  update_columns: types_frequency_update_column[];
  where?: types_frequency_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "user_category"
 */
export interface user_category_arr_rel_insert_input {
  data: user_category_insert_input[];
  on_conflict?: user_category_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "user_category". All fields are combined with a logical 'AND'.
 */
export interface user_category_bool_exp {
  _and?: (user_category_bool_exp | null)[] | null;
  _not?: user_category_bool_exp | null;
  _or?: (user_category_bool_exp | null)[] | null;
  category?: String_comparison_exp | null;
  created_at?: timestamptz_comparison_exp | null;
  id?: Int_comparison_exp | null;
  user?: users_bool_exp | null;
  user_id?: Int_comparison_exp | null;
}

/**
 * input type for inserting data into table "user_category"
 */
export interface user_category_insert_input {
  category?: string | null;
  created_at?: any | null;
  id?: number | null;
  user?: users_obj_rel_insert_input | null;
  user_id?: number | null;
}

/**
 * on conflict condition type for table "user_category"
 */
export interface user_category_on_conflict {
  constraint: user_category_constraint;
  update_columns: user_category_update_column[];
  where?: user_category_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "user_invites"
 */
export interface user_invites_arr_rel_insert_input {
  data: user_invites_insert_input[];
  on_conflict?: user_invites_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "user_invites". All fields are combined with a logical 'AND'.
 */
export interface user_invites_bool_exp {
  _and?: (user_invites_bool_exp | null)[] | null;
  _not?: user_invites_bool_exp | null;
  _or?: (user_invites_bool_exp | null)[] | null;
  email?: String_comparison_exp | null;
  fulfilled?: Boolean_comparison_exp | null;
  fulfilled_at?: timestamptz_comparison_exp | null;
  id?: Int_comparison_exp | null;
  invited_at?: timestamptz_comparison_exp | null;
  invited_by_user_id?: Int_comparison_exp | null;
  phone?: String_comparison_exp | null;
  user?: users_bool_exp | null;
}

/**
 * input type for inserting data into table "user_invites"
 */
export interface user_invites_insert_input {
  email?: string | null;
  fulfilled?: boolean | null;
  fulfilled_at?: any | null;
  id?: number | null;
  invited_at?: any | null;
  invited_by_user_id?: number | null;
  phone?: string | null;
  user?: users_obj_rel_insert_input | null;
}

/**
 * on conflict condition type for table "user_invites"
 */
export interface user_invites_on_conflict {
  constraint: user_invites_constraint;
  update_columns: user_invites_update_column[];
  where?: user_invites_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "users"
 */
export interface users_arr_rel_insert_input {
  data: users_insert_input[];
  on_conflict?: users_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "users". All fields are combined with a logical 'AND'.
 */
export interface users_bool_exp {
  _and?: (users_bool_exp | null)[] | null;
  _not?: users_bool_exp | null;
  _or?: (users_bool_exp | null)[] | null;
  app_errors?: app_errors_bool_exp | null;
  assignedQuestionSetsByAssignedByUserId?: assigned_question_sets_bool_exp | null;
  assigned_question_sets?: assigned_question_sets_bool_exp | null;
  cognito_id?: uuid_comparison_exp | null;
  created_at?: timestamp_comparison_exp | null;
  dark_mode?: Boolean_comparison_exp | null;
  email?: String_comparison_exp | null;
  id?: Int_comparison_exp | null;
  is_disabled?: Boolean_comparison_exp | null;
  is_org_admin?: Boolean_comparison_exp | null;
  last_seen?: timestamp_comparison_exp | null;
  menu_type?: menu_types_bool_exp | null;
  menu_type_id?: Int_comparison_exp | null;
  organization?: organizations_bool_exp | null;
  organization_id?: Int_comparison_exp | null;
  phone?: String_comparison_exp | null;
  pushMessagesByCreatedByUserId?: push_messages_bool_exp | null;
  push_messages?: push_messages_bool_exp | null;
  push_token?: String_comparison_exp | null;
  question_set_submissions?: question_set_submission_bool_exp | null;
  theme_type?: theme_types_bool_exp | null;
  theme_type_id?: Int_comparison_exp | null;
  user_categories?: user_category_bool_exp | null;
  user_invites?: user_invites_bool_exp | null;
}

/**
 * input type for inserting data into table "users"
 */
export interface users_insert_input {
  app_errors?: app_errors_arr_rel_insert_input | null;
  assignedQuestionSetsByAssignedByUserId?: assigned_question_sets_arr_rel_insert_input | null;
  assigned_question_sets?: assigned_question_sets_arr_rel_insert_input | null;
  cognito_id?: any | null;
  created_at?: any | null;
  dark_mode?: boolean | null;
  email?: string | null;
  id?: number | null;
  is_disabled?: boolean | null;
  is_org_admin?: boolean | null;
  last_seen?: any | null;
  menu_type?: menu_types_obj_rel_insert_input | null;
  menu_type_id?: number | null;
  organization?: organizations_obj_rel_insert_input | null;
  organization_id?: number | null;
  phone?: string | null;
  pushMessagesByCreatedByUserId?: push_messages_arr_rel_insert_input | null;
  push_messages?: push_messages_arr_rel_insert_input | null;
  push_token?: string | null;
  question_set_submissions?: question_set_submission_arr_rel_insert_input | null;
  theme_type?: theme_types_obj_rel_insert_input | null;
  theme_type_id?: number | null;
  user_categories?: user_category_arr_rel_insert_input | null;
  user_invites?: user_invites_arr_rel_insert_input | null;
}

/**
 * input type for inserting object relation for remote table "users"
 */
export interface users_obj_rel_insert_input {
  data: users_insert_input;
  on_conflict?: users_on_conflict | null;
}

/**
 * on conflict condition type for table "users"
 */
export interface users_on_conflict {
  constraint: users_constraint;
  update_columns: users_update_column[];
  where?: users_bool_exp | null;
}

/**
 * expression to compare columns of type uuid. All fields are combined with logical 'AND'.
 */
export interface uuid_comparison_exp {
  _eq?: any | null;
  _gt?: any | null;
  _gte?: any | null;
  _in?: any[] | null;
  _is_null?: boolean | null;
  _lt?: any | null;
  _lte?: any | null;
  _neq?: any | null;
  _nin?: any[] | null;
}

/**
 * Boolean expression to filter rows from the table "value_types". All fields are combined with a logical 'AND'.
 */
export interface value_types_bool_exp {
  _and?: (value_types_bool_exp | null)[] | null;
  _not?: value_types_bool_exp | null;
  _or?: (value_types_bool_exp | null)[] | null;
  id?: String_comparison_exp | null;
}

/**
 * expression to compare columns of type value_types_enum. All fields are combined with logical 'AND'.
 */
export interface value_types_enum_comparison_exp {
  _eq?: value_types_enum | null;
  _in?: value_types_enum[] | null;
  _is_null?: boolean | null;
  _neq?: value_types_enum | null;
  _nin?: value_types_enum[] | null;
}

/**
 * input type for inserting data into table "value_types"
 */
export interface value_types_insert_input {
  id?: string | null;
}

/**
 * input type for inserting object relation for remote table "value_types"
 */
export interface value_types_obj_rel_insert_input {
  data: value_types_insert_input;
  on_conflict?: value_types_on_conflict | null;
}

/**
 * on conflict condition type for table "value_types"
 */
export interface value_types_on_conflict {
  constraint: value_types_constraint;
  update_columns: value_types_update_column[];
  where?: value_types_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "webhook_headers"
 */
export interface webhook_headers_arr_rel_insert_input {
  data: webhook_headers_insert_input[];
  on_conflict?: webhook_headers_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "webhook_headers". All fields are combined with a logical 'AND'.
 */
export interface webhook_headers_bool_exp {
  _and?: (webhook_headers_bool_exp | null)[] | null;
  _not?: webhook_headers_bool_exp | null;
  _or?: (webhook_headers_bool_exp | null)[] | null;
  created_at?: timestamptz_comparison_exp | null;
  id?: Int_comparison_exp | null;
  name?: String_comparison_exp | null;
  updated_at?: timestamptz_comparison_exp | null;
  value?: String_comparison_exp | null;
  webhook?: webhooks_bool_exp | null;
  webhook_id?: Int_comparison_exp | null;
}

/**
 * input type for inserting data into table "webhook_headers"
 */
export interface webhook_headers_insert_input {
  created_at?: any | null;
  id?: number | null;
  name?: string | null;
  updated_at?: any | null;
  value?: string | null;
  webhook?: webhooks_obj_rel_insert_input | null;
  webhook_id?: number | null;
}

/**
 * on conflict condition type for table "webhook_headers"
 */
export interface webhook_headers_on_conflict {
  constraint: webhook_headers_constraint;
  update_columns: webhook_headers_update_column[];
  where?: webhook_headers_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "webhook_source"
 */
export interface webhook_source_arr_rel_insert_input {
  data: webhook_source_insert_input[];
  on_conflict?: webhook_source_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "webhook_source". All fields are combined with a logical 'AND'.
 */
export interface webhook_source_bool_exp {
  _and?: (webhook_source_bool_exp | null)[] | null;
  _not?: webhook_source_bool_exp | null;
  _or?: (webhook_source_bool_exp | null)[] | null;
  created_at?: timestamptz_comparison_exp | null;
  id?: Int_comparison_exp | null;
  source?: String_comparison_exp | null;
  updated_at?: timestamptz_comparison_exp | null;
  webhook?: webhooks_bool_exp | null;
  webhook_id?: Int_comparison_exp | null;
}

/**
 * input type for inserting data into table "webhook_source"
 */
export interface webhook_source_insert_input {
  created_at?: any | null;
  id?: number | null;
  source?: string | null;
  updated_at?: any | null;
  webhook?: webhooks_obj_rel_insert_input | null;
  webhook_id?: number | null;
}

/**
 * on conflict condition type for table "webhook_source"
 */
export interface webhook_source_on_conflict {
  constraint: webhook_source_constraint;
  update_columns: webhook_source_update_column[];
  where?: webhook_source_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "webhooks"
 */
export interface webhooks_arr_rel_insert_input {
  data: webhooks_insert_input[];
  on_conflict?: webhooks_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "webhooks". All fields are combined with a logical 'AND'.
 */
export interface webhooks_bool_exp {
  _and?: (webhooks_bool_exp | null)[] | null;
  _not?: webhooks_bool_exp | null;
  _or?: (webhooks_bool_exp | null)[] | null;
  created_at?: timestamptz_comparison_exp | null;
  id?: Int_comparison_exp | null;
  is_enabled?: Boolean_comparison_exp | null;
  method?: String_comparison_exp | null;
  name?: String_comparison_exp | null;
  organization?: organizations_bool_exp | null;
  organization_id?: Int_comparison_exp | null;
  updated_at?: timestamptz_comparison_exp | null;
  url?: String_comparison_exp | null;
  webhook_headers?: webhook_headers_bool_exp | null;
  webhook_sources?: webhook_source_bool_exp | null;
}

/**
 * input type for inserting data into table "webhooks"
 */
export interface webhooks_insert_input {
  created_at?: any | null;
  id?: number | null;
  is_enabled?: boolean | null;
  method?: string | null;
  name?: string | null;
  organization?: organizations_obj_rel_insert_input | null;
  organization_id?: number | null;
  updated_at?: any | null;
  url?: string | null;
  webhook_headers?: webhook_headers_arr_rel_insert_input | null;
  webhook_sources?: webhook_source_arr_rel_insert_input | null;
}

/**
 * input type for inserting object relation for remote table "webhooks"
 */
export interface webhooks_obj_rel_insert_input {
  data: webhooks_insert_input;
  on_conflict?: webhooks_on_conflict | null;
}

/**
 * on conflict condition type for table "webhooks"
 */
export interface webhooks_on_conflict {
  constraint: webhooks_constraint;
  update_columns: webhooks_update_column[];
  where?: webhooks_bool_exp | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
