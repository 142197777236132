import gql from 'graphql-tag'

export const getUsers = gql`
    query Users {
        users(order_by: {email: asc}, where: {is_disabled: {_eq: false}}) {
            email
            phone
            created_at
        }
    }
`;

export const getUserSetting = gql`
    query UserSetting($id:uuid!) {
        users(where: {cognito_id: {_eq: $id}}, limit: 1) {
            theme_type_id
            theme_type {
                name
            }
            menu_type_id
            menu_type {
                name
            }
        }
    }
`;

export const getUserProfileInfoQuery = gql`
    query UserProfileInfo {
        users{
            email
            created_at
            phone
            organization{
            name
            }
        }
    }
`;

export const getCurrentUserId = gql`
    query GetUserId($cognitoId:uuid!){
        users(where:{cognito_id: {_eq:$cognitoId}}){
            id
        }
    }
`;

export const insertUserInviteQuery = gql`
    mutation InsertUserInvite($user:user_invites_insert_input!) {
        insert_user_invites(objects:[$user]){
            returning {
                email
            }
        }
    }
`; 

export const getUserById = gql`
    query GetUserById($email: String!) {
        users(where: {email: {_eq: $email}}) {
            id,
            email,
            phone,
            created_at,
            is_disabled,
            organization {
                name
            },
            user_categories{
                id,
                category
            }
        }
    }
`;

export const setUserIsEnabled = gql`
    mutation SetUserIsEnabled($email: String!, $isDisabled: Boolean!) {
        update_users(where: {email: {_eq: $email}}, _set: {is_disabled: $isDisabled}) {
            affected_rows
        }
    }
`;

export const updateUser = gql`
    mutation UpdateUserCategories($insert: [user_category_insert_input!]!, $delete: [Int!]!) {
        insert_user_category(objects: $insert) {
            affected_rows
        }
        delete_user_category(where: {id: {_in: $delete}}) {
            affected_rows
        }
    }
`;

export const getOrganization = gql`
    query Organization {
        organizations {
            id
            display_name
            logo_url
        }
    }
`;

export const updateOrganization = gql`
    mutation UpdateOrganization($id: Int, $display_name: String!, $logo_url: String!) {
        update_organizations(where: {id: {_eq: $id}}, _set: {display_name: $display_name, logo_url: $logo_url}) {
            affected_rows
        }
    }
`;

