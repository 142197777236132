import { value_types_enum } from "../types/graphql-global-types";

export const DefaultValueLabelsEnum = {
    CurrentUser: "Current User",
    FormVersion: "Form Version",
    CurrentDate: "Current Date",
    CurrentTime: "Current Time",
    CurrentDateTime: "Current Date/Time",
    None: "",
};

export const DefaultValueOptionsEnum = {
    CurrentUser: "=CURRENT_USER",
    FormVersion: "=FORM_VERSION",
    CurrentDate: "=CURRENT_DATE",
    CurrentTime: "=CURRENT_TIME",
    CurrentDateTime: "=CURRENT_DATETIME",
    None: "",
};

type DefaultValue = { label: string, value: string };

const DefaultValueOptions: DefaultValue[] = [
    { label: DefaultValueLabelsEnum.CurrentUser, value: DefaultValueOptionsEnum.CurrentUser }
    , { label: DefaultValueLabelsEnum.FormVersion, value: DefaultValueOptionsEnum.FormVersion }
    , { label: DefaultValueLabelsEnum.CurrentDate, value: DefaultValueOptionsEnum.CurrentDate }
    , { label: DefaultValueLabelsEnum.CurrentTime, value: DefaultValueOptionsEnum.CurrentTime }
    , { label: DefaultValueLabelsEnum.CurrentDateTime, value: DefaultValueOptionsEnum.CurrentDateTime }
];

/**
 * Given the type of a free form question (e.g. date, numeric, text), return the valid dropdown
 * options for the default value for that type
 * @param type Free form question type to get the valid dropdown options for
 */
export const getFilteredDefaultValueOptions = (type: value_types_enum): DefaultValue[] => {
    if (type === value_types_enum.Date) {
        // if date, only CurrentDate or CurrentTime or CurrentDateTime or static date
        return DefaultValueOptions.filter(r =>
            r.label === DefaultValueLabelsEnum.CurrentDate ||
            r.label === DefaultValueLabelsEnum.CurrentTime ||
            r.label === DefaultValueLabelsEnum.CurrentDateTime
        );
    } else if (type === value_types_enum.Number) {
        // if number, only form version or static number
        return DefaultValueOptions.filter(r => r.label === DefaultValueLabelsEnum.FormVersion);
    } else if (type === value_types_enum.Text) {
        // if text, only current user or static text
        return DefaultValueOptions.filter(r => r.label === DefaultValueLabelsEnum.CurrentUser);
    } else {
        return DefaultValueOptions;
    }
}

/**
 * Given the type of a free form question (e.g. date, numeric, text) and the current default value
 * for that question, return whether or not the default value is a mismatch for that type and should be 
 * reset. E.g. if the type is date but the default value is "abc 123" (not able to be parsed to a date), 
 * return true
 * @param type Free form question type to get the valid dropdown options for
 * @param value The current default value
 */
export const shouldResetDefaultValueBasedOnQuestionType = (type: value_types_enum, value: string | null): boolean => {
    // if no value set, don't have to reset
    if (!value) return false;

    // if no type set, don't have to reset
    if (!type) return false;

    if (type === value_types_enum.Date) {
        // if date, only CurrentDate or CurrentTime or CurrentDateTime or static date are valid
        if (value === DefaultValueOptionsEnum.CurrentDate ||
            value === DefaultValueOptionsEnum.CurrentTime ||
            value === DefaultValueOptionsEnum.CurrentDateTime) return false;

        // if static value, if can cast to date don't need to reset
        if (new Date(value).toString() !== "Invalid Date") return false;
    } else if (type === value_types_enum.Number) {
        // if number, only form version or static number
        if (value === DefaultValueOptionsEnum.FormVersion) return false;

        // if static value, if can cast to number don't need to reset
        if (!isNaN(value as any)) return false;
    } else if (type === value_types_enum.Text) {
        // if text, only current user or static text
        if (value === DefaultValueOptionsEnum.CurrentUser) return false;

        // any value that isn't one of the other default values is good here
        if (!value.startsWith("=")) return false;
    }

    // if here there are no valid options, reset
    return true;
}

export default DefaultValueOptions;
