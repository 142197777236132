import * as React from 'react';
import { ApolloConsumer } from '@apollo/react-hooks';

type Comp = (args: any) => JSX.Element;
const withApolloClient = (Component: Comp) => (props: any) => (
    <ApolloConsumer>
        {client => <Component {...props} client={client} />}
    </ApolloConsumer>
);

export default withApolloClient;
